import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";
import SplitPane, { Pane } from "react-split-pane";

import TargetPanel from "../components/repo/Target";
import RepoTree from "../components/tree";
import { RepoInfo, RepoRevision } from "../components/repo";

import useStores from "~/core/utils/useStores";
import RepoStore from "../stores/RepoStore";
import CommitsView from "../components/commits";
import SourceView from "../components/code/SourceView";
import { InfoToolWindow, infoToolContent } from "~/core/components/InfoToolWindow";
import { SIDEPANEL_RELATIONS } from "~/core/constants/SidePanels";
import { CLS_REPO_FILE } from "~/core/constants/Classes";
import { MODE_VIEW_COMMITS, MODE_VIEW_REPO, MODE_VIEW_SOURCE } from "../constants/modes";
import { changeContext } from "../utils";

import "./css/index.scss";

/**
 * Инструмент для работы с репозиторием
 *
 * @param {Object} props набор параметров
 * @param {String} props.tabId id таба в Layout
 * @param {String} props.editable id репозитория
 * @param {Object} props.trackedItem информация об отселживаемом элементе
 * @param {Array} props.isSubVisible  массив с состоянием SidePanels
 *
 * @type {RepoTool}
 */
const RepoTool = observer((props) => {
  const { tabId, editable: repositoryId, trackedItem, isSubVisible, layoutItem } = props;
  const { rootStore, objectStore, layoutStore } = useStores();

  // const repoTreeEl = useRef(null);

  // Здесь храним ширину панели с деревом репозитория.
  // Для каждого режима - своя ширина
  const [splitPos, setSplitPos] = useState({
    repo:    "100%",
    source:  250,
    commits: 250
  });

  // Хранилище для работы с репозиторием
  const store = useMemo(() => {
    const store = new RepoStore(rootStore, objectStore);
    return store;
  }, []);

  const repoName = useMemo(() => {
    return layoutItem && layoutItem.itemName || "Репозиторий";
  }, [layoutItem]);

  // номер линии функции в коде должна быть Integer,
  // а при переходе по url она приходит в формате String
  const focusCodeLine = useMemo(() => {
    if (!props.focusCodeLine) {
      return null;
    }

    return parseInt(props.focusCodeLine);
  }, [props.focusCodeLine]);

  const [modeView, setModeView] = useState(MODE_VIEW_REPO);

  useEffect(() => {
    // инициализция репозитория
    store.init(repositoryId);
  }, [repositoryId]);

  // переключение SidePanel со связью
  const toggleSubPanel = useCallback(() => {
    layoutStore.toggleSubPanel(tabId, SIDEPANEL_RELATIONS);
  }, [tabId]);
  
  const onRepoMode = useCallback(() => {
    setModeView(MODE_VIEW_REPO);
  }, [tabId]);

  const onSourceMode = useCallback(() => {
    setModeView(MODE_VIEW_SOURCE);
  }, [tabId]);

  const onCommitsMode = useCallback(() => {
    setModeView(MODE_VIEW_COMMITS);
  }, [tabId]);

  // Кликнули на иконку в ноде дерева
  const onNodeClick = useCallback(async(repoNode, focusCodeLine) => {
    if (repoNode.class === CLS_REPO_FILE && 
        (modeView === MODE_VIEW_SOURCE || modeView === MODE_VIEW_REPO || focusCodeLine)) {
      if (focusCodeLine || modeView === MODE_VIEW_REPO) {
        setModeView(MODE_VIEW_SOURCE);
      }
      await store.openFile(repoNode.uid, repoNode.commitId, repoNode.name, focusCodeLine);
    }
    if (modeView ===  MODE_VIEW_COMMITS) {
      store.clearCommits();
      store.loadCommits(repoNode.path);
    }

    changeContext(layoutItem, repoNode.repositoryId, repoNode);
  }, [modeView]);

  // Кликнули по target
  const onTargetClick = useCallback(() => {
    if (modeView ===  MODE_VIEW_COMMITS) {
      // repoTreeEl.current.resetCursor();

      // загружаем список коммитов для рута
      store.clearCommits();
      store.loadCommits();
    } 
  }, [modeView]);

  // Изменили ширину у дерева репозитория
  const onSplitPosChange = useCallback((size) => {
    setSplitPos((state) => {
      return {
        ...state,
        [modeView]: size
      };
    });
  }, [modeView]);

  const [infoIsVisible, setInfoIsVisible] = useState(false);

  const toggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);

  // набор кнопок в toolbar инструмента
  const toolbarRightButtons = useMemo(() => {
    return [
      <Components.Button
        key="button-relations"
        icon="app-relations-M"
        tooltip="Связи"
        isSelected={isSubVisible[SIDEPANEL_RELATIONS]}
        onPress={toggleSubPanel}
      />,
      <Components.Spacer key="right-spacer" />,
      <Components.Button
        key="button-info"
        icon="info-M"
        tooltip="info"
        isSelected={infoIsVisible}
        onPress={toggleInfoModal}
      />
    ];
  }, [isSubVisible[SIDEPANEL_RELATIONS], infoIsVisible]);

  return (
    <div className="repo-tool">
      <Components.ToolBar right={toolbarRightButtons} >
        <Components.Button
          icon="repository-M"
          tooltip="Репозиторий"
          isSelected={modeView === MODE_VIEW_REPO}
          onPress={onRepoMode}
        />
        <Components.Button
          icon="app-redactionviewer-M"
          tooltip="Проводник"
          isSelected={modeView === MODE_VIEW_SOURCE}
          onPress={onSourceMode}
        />
        <Components.Button
          icon="log-M"
          tooltip="История изменений"
          isSelected={modeView ===  MODE_VIEW_COMMITS}
          onPress={onCommitsMode}
        />
      </Components.ToolBar>
      <div className="repo-tool-tracker">
        <TargetPanel
          trackedItem={trackedItem}
          repositoryId={repositoryId}
          store={store}
          onTargetClick={onTargetClick}
        />
        <RepoInfo store={store} />
      </div>
  
      <div className="repo-tool-content">
        {store.pending && (
          <div className="preloader-wrapper">
            <Components.Preloader size={3} />
          </div>
        )}
        <SplitPane
          split="vertical"
          minSize={modeView === MODE_VIEW_REPO ? "100%" : 200}
          style={{ flex: 1 }}
          allowResize={modeView !== MODE_VIEW_REPO}
          onChange={onSplitPosChange}
          size={splitPos[modeView]}
        >
          <RepoTree
            {...props}

            repoName={repoName}
            focusCodeLine={focusCodeLine}
            store={store}
            modeView={modeView}
            onNodeClick={onNodeClick}
          />
          <Pane className="repo-tool-panel">
            <SourceView
              {...props}
              repoName={repoName}
              focusCodeLine={focusCodeLine}
              store={store} 
              disabled={modeView !== MODE_VIEW_SOURCE}
              onCloseAllTabs={store.onCloseAllSourceTabs}
            />
            <CommitsView 
              repoName={repoName}
              store={store} 
              disabled={modeView !==  MODE_VIEW_COMMITS} 
            />
          </Pane>
        </SplitPane>
        <RepoRevision commitItem={store.lastCommit} />
      </div>
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.repo}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={toggleInfoModal}
        />
      )}
    </div>
  );
});

RepoTool.propTypes = {
  tabId:        PropTypes.string, 
  editable:     PropTypes.string,
  trackedItem:  PropTypes.object, 
  isSubVisible: PropTypes.bool
};
export default RepoTool;
