/**
 * Скопировать переданный текст в буфер обмена
 * 
 * @param {String} text текст, который необходимо скопировать в буфер обмена
 * @param {Function} onSuccess callback ф-я, если текст был скопирован в буфер обмена без проблем
 * @param {Function} onFail callback ф-я, если текст был скопирован в буфер обмена с проблемой.
 * Текст ошибки передается в параметре вызываемой ф-ии
 */
const copyTextToClipboard = (text, onSuccess, onFail) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(text);
    onSuccess && onSuccess();
  } else {
    const res = unsecuredCopyToClipboard(text);
    if (res === true) {
      onSuccess && onSuccess();
    } else {
      onFail && onFail(res); 
    }
  }
};


const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
    return err;
  }
  document.body.removeChild(textArea);
  return true;
};

export default copyTextToClipboard;
