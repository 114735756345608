import { observable, action, computed } from "mobx";
import moment from "moment";
import Colors from "~/core/constants/Colors";

/**
 * Хранилище для работы с UI
 */
class UiStore {
  /**
   * Список запросов, которые еще находятся в обработке на сервере.
   * Пока список не будет пуст, пользователь не сможет сделать выход из приложения
   * @type {Map}
   */
  @observable
  busyRequestsMap = new Map();

  /**
   * Флаг, указывающий, что в приложении нужно отобразить сообщение - `Alert` об ошибке
   * @type {Boolean}
   */
  @observable
  showError = false;

  /**
   * Флаг, указывающий, что ошибка критическая
   * @type {Boolean}
   */
  @observable
  errorIsCritical = false;

  /**
   * Текст последнего сообщения об ошибке
   * @type {String}
   */
  @observable
  errorText;

  /**
   * Статус-код последнего сообщения об ошибке
   * @type {String}
   */
  @observable
  errorStatus;

  /**
   * Текст последнего сообщения об успехе
   * @type {String}
   */
  @observable
  successText;
  /**
   * Детали последнего сообщения об ошибке
   * @type {String}
   */
  @observable
  errorDetail;

  /**
   * Флаг, указывающий, что идет обработка подтвержденного действия
   * @type {Boolean}
   */
  @observable
  isConfirmPending;
  
  /**
   * Здесь храним конфигурацию модального окна с подтверждением: icon, title, content, buttons
   * Если параметр не задан, значит окно не нужно отображать
   *@type {Object}  
   */ 
  @observable
  confirmCfg = null;

  /**
   * Набор цветовой палитры, которую использует приложение
   * @type {Colors}
   */
  @observable
  colors;

  /**
   * Конфигурация пользовательских настроек инструментов приложения
   * @type {Object}
   */
  @observable
  modulesConfig;

  /**
   * Набор аватарок пользователя
   */
  @observable
  userAvatarsMap = new Map();
 
   /**
    * Конфигурация Wizard - инструмент в модальном окне для 
    * создания объектов.
    * @type {Boolean}
    */
   @observable
   wizardConfig = null;

   constructor(root) {
     this.rootStore = root;
     this.colors = new Colors();
   }

  /**
   * Очистить очередь запросов, которые находятся еще в обработе на сервер
   */
  @action
   clearBusyRequests() {
     this.busyRequestsMap.clear();
   }

  /**
   * Добавить запрос в очередь запросов
   * @param {String} id запроса
   * @param {String} url запроса
   * @param {Object} options набор параметров запроса
   */
  @action
  addBusyRequest(id, url, options) {
    this.busyRequestsMap.set(id, { dt: new Date(), url, options });
  }

  /**
   * Удалить запрос из очереди запросов
   * @param {String} id запроса
   */
  @action
  removeBusyRequest(id) {
    this.busyRequestsMap.delete(id);
  }

  /**
   * Флаг, указывающий на возможность пользователю сделать выход из системы - logout
   * @return {Boolean}
   */
  @computed
  get canLogout() {
    return this.busyRequestsMap.size === 0;
  }

  /**
   * Флаг, указывающий, что еще не все запросы обработаны на сервере
   * @return {Boolean}
   */
  @computed
  get isPendingTasks() {
    return this.busyRequestsMap.size !== 0;
  }

  @computed
  get pendingTasksList() {
    if (this.busyRequestsMap.size === 0) {
      return null;
    }

    return Array.from(this.busyRequestsMap.values()).map((req) => {
      const { dt, url, options } = req;
      return `${moment(dt).format("DD.MM.YYYY HH:mm:ss")} [${options.method}] ${url}`;
    })
      .join("\n");
  }

  /**
   * Отобразить сообщение об ошибке
   * @param {String} text сообщение об ошибке
   * @param {Boolean} critical критичная ли ошибка
   * @param {number} stat статус-код ошибки
   */
  @action
  setErrorText(text, criticalOrStatus = false, stat = null) {
    let critical = false;
    let status = stat;
    
    // проверка второго параметра
    if (typeof criticalOrStatus === "number") {
      status = criticalOrStatus;
    } else {
      critical = criticalOrStatus;
    }
    this.showError = true;
    this.showConfirm = false;
    this.errorIsCritical = critical;
    this.errorStatus = status;
    if (typeof text === "string") {
      this.errorText = text;
    } else {
      this.errorText = JSON.stringify(text);
    }
  }

  /**
   * Установить детали ошибки
   * @param {Object || String} detail детали ошибки
   */
  @action
  setErrorDetail(detail) {
    if (typeof detail === "string") {
      this.errorDetail = detail;
    } else {
      this.errorDetail = JSON.stringify(detail);
    }
  }

  /**
   * Отобразить сообщение об успехе
   * @param {String} text сообщение об успехе
   */
  @action
  setSuccessText(text) {
    this.successText = text;
  }

  /**
   * Скрыть окно `Alert`  c успехом
   */
  @action
  dismissSuccess() {
    if (this) {
      this.successText = "";
    }
  }
  /**
   * Задать/снять статус обработки процесса, подтвержденный пользователем
   * @param {Boolean} pending обрабатывается ли процесс, подтвержденный пользователем
   */
  @action
  setConfirmPending(pending = false) {
    this.isConfirmPending = pending;
  }

  /**
   * Отобразить окно `Confirmation` для подтверждения действия пользователем
   * @param {Object} params набор параметров
   * @param {String} params.name название модального окна подтверждения
   * @param {String} params.icon название иконки, которая должна быть отображена в заголовке окна 
   * @param {String} params.title заголовок окна сообщения для пользователя
   * @param {String|Component} params.content текст собщения
   * @param {Array<Сomponents.Button>} buttons набор кнопок, доступных действий
   * @param {Func} params.onKeyPressEsc callback ф-я на нажатие кнопки `Esc`
   * @param {Func} params.onKeyPressEnter callback ф-я на нажатие кнопки `Enter`
   */
  @action
  setConfirm({
    name = "confirm",
    icon = "plane-M",
    title,
    content,
    buttons = [],
    onKeyPressEsc,
    onKeyPressEnter
  }) {
    this.confirmCfg = {
      name,
      icon,
      title,
      content,
      buttons,
      onKeyPressEsc,
      onKeyPressEnter
    };
  } 

  /**
   * Скрыть окно `Confirmation` для подтверждения действия пользователем
   */
  @action
  hideConfirm() {
    this.confirmCfg = null;
    this.setConfirmPending();
  }

  /**
   * Скрыть окно `Alert`  c ошибкой
   */
  @action
  dismissError() {
    if (this) {
      this.showError = false;
      this.errorStatus = null;
      this.errorText = "";
      this.errorDetail = "";
    }
  }

  /**
   * Загрузить пользовательскую конфигурацию инструментов
   */
  loadModulesConfig() {
    this.modulesConfig = {};
    const userUid = this.rootStore.accountStore.uid;
    const cfg = localStorage.getItem("ais3pModules");
    try {
      const allCfg = cfg ? JSON.parse(cfg) : {};
      this.modulesConfig = allCfg[userUid] || {};
    } catch (e) {
      console.error("Во время загрузки конфигурации инстументов произошла ошибка", e);
    }

    return this.modulesConfig;
  }

  /**
   * Очистить пользовательскую конфигурацию инструментов
   *
   */
  @action
  clearModulesConfig() {
    this.modulesConfig = {};
  }

  /**
   * Задать конфигурацию модуля
   *
   * @param {String} moduleName название инструмента
   * @param {Object} config кофигурация инструмента
   */
  @action
  setModuleConfig(moduleName, config) {
    this.modulesConfig[moduleName] = config;
    const cfg = localStorage.getItem("ais3pModules");
    try {
      const userUid = this.rootStore.accountStore.uid;
      let allCfg = cfg ? JSON.parse(cfg) : {};
      allCfg = {
        ...allCfg,
        [userUid]: this.modulesConfig
      };
      localStorage.setItem("ais3pModules", JSON.stringify(allCfg));
    } catch (e) {
      console.error("Во время сохранения конфигурации инстументов произошла ошибка", e);
    }
  }

  /**
   * Получить конфигурацию модуля
   *
   * @param {String} moduleName название инструмента
   * @return {Object}
   */
  getModuleConfig(moduleName) {
    return this.modulesConfig[moduleName] || {};
  }

  /**
   * Добавить путь до аватарки к локальному файлу в кэше бразуера, который был скачан с файлового хранилища.
   * Делаем для того, чтобы каждый раз не скачавать одну и ту же аватарку и использовать
   * кэш браузера
   * 
   * @param {String} fileId  id файла в файловом хранилище
   * @param {String} src путь до локального файла в кэше браузера
   */
  @action 
  addUserAvatar(fileId, src) {
    if (!src) return;
    this.userAvatarsMap.set(fileId, src);
  }

  /**
   * Получить путь до аватарки к локальному файлу в кэше бразуера, который был скачан с файлового хранилища.
   * Если возрващается `true`, значит запрос на скачивание файла уже начался и другим компонентам нужно 
   * немного подождать.
   * 
   * @param {String} fileId  id файла в файловом хранилище
   * 
   * @return {String|Boolean}
   */
  getUserAvatar(fileId) {
    return this.userAvatarsMap.get(fileId);
  }

  /**
   * Отобразить окно инструмента `Wizard`
   * @param {Object} wizardConfig  конфигурация для окна `Wizard` 
   */
  @action
  setWizard({
    icon,
    id,
    label,
    parent,
    onSuccess,
    onCancel,
    config:    {
      instantConfirm,
      initialName,
      nameTitle,
      className,
      withName,
      views:          [
        {
          type,
          kindId
        }
      ]
    }
  }) {
    this.wizardConfig = {
      icon,
      id,
      label,
      parent,
      onSuccess,
      onCancel,
      config: {
        instantConfirm,
        initialName,
        nameTitle,
        className,
        withName,
        views: [
          {
            type,
            kindId
          }
        ]
      }
    };
  }
  
  /**
   * Скрыть окно инструмента `Wizard`
   */
  @action
  hideWizard() {
    this.wizardConfig = null;
  }
  

  /**
   * Очистить хранилище
   */
  clear() {
    this.userAvatarsMap.clear();
    this.clearBusyRequests();
  }

  /**
   * Деструктор хранилища
   */
  destroy() {
    this.clear();
  }
}

export default UiStore;
