import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import Indicator from "../../../core/components/Indicator";

import TextItemIcon from "./TextItemIcon";

const Code = observer(({ data, renderItem, setContextMenu }) => {
  const { 
    uid, 
    idsArray, 
    itemsForRender, 
    caption,
    scrollItemId, 
    isFocusUid, 
    diffClass, 
    additionalClasses 
  } = data;

  const captionRender = useMemo(() => {
    return caption && renderItem(caption);
  }, [caption]);

  const onCreateCaption = useCallback(() => {
    data.createCaption();
  }, [uid]);
  
  const itemsForRenderRender = useMemo(() => {
    return itemsForRender.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, itemsForRender]);

  const onClickIcon = useCallback(() => {
    data.setEditing();
    data.setFocusUid();
  }, [data]);

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div className={`code element  ${additionalClasses} ${diffClass}`} ref={element} id={uid}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <TextItemIcon onClick={onClickIcon} setContextMenu={setContextMenu} data={data} />
        <div className="code-holder">
          <div className="lang-select">
            <div className="caption">
              {captionRender || (
                <div onClick={onCreateCaption} className="placeholder">
                  Нет заголовка
                </div>
              )}
            </div>
          </div>
          <div className={"code-viewer"}>{itemsForRenderRender}</div>
        </div>
      </div>
    </div>
  );
});

export default Code;
