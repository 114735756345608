import React, { useMemo } from "react";
import { observer } from "mobx-react";

import ChildButton from "./ChildButton";
import { Components } from "@ais3p/ui-framework";

const DropButton = observer((props) => {
  const { icon, children, getPresset } = props;
  
  const childArray = useMemo(() => {
    return children.map((child) => {
      return (
        <ChildButton 
          key={child.id}
          getPresset={getPresset}  
          {...{ ...props, ...child }} 
        />
      );
    });
  }, [children, getPresset, props]);

  return (
    <div className={"button-wrapper"} >
      <Components.Button
        size={3}
        icon={icon}
      />
      <div className={"children"}>{childArray}</div>
    </div>
  );
});

export default DropButton;
