import { action, computed } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class ListItem extends BaseBoxClass {
  @action
  init(data, parent) {
    super.init(data, parent);
    if (data.isNew) {
      this.creationPromise = this.persistCreate(data.forceEmpty);
    }
  }

  @action
  async persistCreate(noIndent = false) {
    await super.persistCreate();
    if (!noIndent) {
      await this.createOnlyIndent();
    }
  }

  @action
  async createOnlyIndent() {
    if (!this.store) {
      return null;
    }
    if (this.idsArray.length < 1) {
      const item = this.store.createItem(
        {
          class: "text.element.Indent",
          uid:   this.store.getUid(),
          isNew: true
        },
        this
      );
      this.addItemById(item.uid);
    }
  }

  @action
  async persistDelete() {
    await super.persistDelete();
    (await this.parent) && this.parent.checkForEmptinessAndRemove();
  }

  @computed
  get isSplitable() {
    return true;
  }

  @computed
  get title() {
    let title = " ";
    this.items.forEach((item) => {
      if (item && item.title) {
        title += item.title;
      }
    });
    return title;
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [
      {
        class:               "text.container.EnumerationItem",
        icon:                "data-enum-single-M",
        level:               this.parent.childListLevel,
        isSibling:           true,
        ancorId:             this.uid,
        availableKindsArray: [{ kind: "type-only", title: "", icon: null }]
      }
    ];
    return array;
  }

  @computed
  get firstItem() {
    if (!this.idsArray.length) {
      return null;
    }
    const id = this.idsArray[0];
    const item = this.getItemById(id);
    return item || null;
  }


  @computed
  get flatItemsArray() {
    const data = [this];
    const childrenArray = [];
    if (this.removedFirstChildItemsArray && this.removedFirstChildItemsArray.length) {
      this.removedFirstChildItemsArray.forEach((item) => {
        if (item) {
          childrenArray.push(item);
        }
      });
    }
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      item.flatItemsArray && item.flatItemsArray.forEach((item) => {
        if (item) {
          childrenArray.push(item);
        }
      });
    });
    if (!this.store.isPending && !this.store.isDiffPending && this.removedDescendantItemsArray.length > 0) {
      this.removedDescendantItemsArray.forEach((item) => {
        if (item) {
          childrenArray.push(item);
        }
      });
    }
    childrenArray.shift();
    return data.concat(childrenArray);
  }
  
  @computed
  get childListLevel() {
    return 1 + (this.parent.childListLevel || 0);
  }

  @computed
  get slug() {
    return "enumeration-items";
  }

  @computed
  get category() {
    return "enumeration-items";
  }

  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      elements:    [],
      "@position": this.position
    };
  }

  @computed
  get hierarchyItemsArray() {
    const array = [];
    if (!this.isLast) {
      return array;
    }
    return [].concat(this.parent.hierarchyItemsArray, array);
  }

  @computed
  get removedDescendantListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (this.nextSibling && this.nextSibling.diffClass === "removed") {
      return [this.nextSibling, ...this.nextSibling.removedDescendantListItemsArray];
    }
    if (this.diffCompatitor.nextSibling && this.diffCompatitor.nextSibling.diffClass === "removed") {
      return [this.diffCompatitor.nextSibling, ...this.diffCompatitor.nextSibling.removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get availableItemsArray() {
    if (!this.parent) {
      return [];
    }
    if (this.isLocked && !this.isLockedByMe) {
      return [].concat(
        this.parent.hierarchyItemsArray,
        this.innerHierarchyItemsArray
      );
    }
    if (this.isParentLocked && !this.isParentLockedByMe) {
      return [];
    }
    if (this.isLast) {
      return this.parent.hierarchyItemsArray.concat(
        this.innerHierarchyItemsArray,
        this.defaultInnerItemsArray
      );
    }
    return this.innerHierarchyItemsArray.concat(this.defaultInnerItemsArray);
  }
}

export default ListItem;
