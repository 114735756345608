import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { layout, Components } from "@ais3p/ui-framework";
import { servicesVersions } from "../../../core/constants/ServiceVersions";
import semver from "semver";
import "./css/index.scss";
import useStores from "../../../core/utils/useStores";
import { copyTextToClipboard } from "~core/utils";

const { modal, notification } = layout;

const About = observer(({ isVisible: visible, onShow }) => {
  const { aboutStore } = useStores();
  const [isVisible, setIsVisible] = useState(visible);

  const toggleModal = useCallback(() => {
    onShow(false);
  }, [onShow]);

  useEffect(() => {
    setIsVisible(visible);
    visible && aboutStore.validateVersions();
  }, [visible]);

  const { isPending, data } = aboutStore;
  const items = [
    <div key={"header"} className="service">
      <div className="name">
        Сервис
      </div>
      <div className="version">
        Версия
      </div>
      <div className="version compatible">
        Версия (совместимая)
      </div>
      <div className="build">
        Сборка
      </div>
      <div className="commit">
        Коммит
      </div>
      <div className="status header">
      </div>
    </div>
  ];
  let content = (
    <div className="preloader-holder">
      <Components.Preloader size={3} />
    </div>
  );
  if (!isPending) {
    data.forEach((status, i) => {
      const service = servicesVersions.find((service) => {
        return service.name === status.name;
      });
      let compatible;
      if (service) {
        if (status.version === "staging") {
          compatible = true;
        } else {
          const isValid = semver.valid(status.version) && semver.valid(service.version);
          compatible = isValid && semver.minor(status.version) === semver.minor(service.version);
        }
      } else {
        compatible = false;
      }
      items.push(
        <div key={i} className="service">
          <div className="name">
            {status.name}
          </div>
          <div className={`version ${compatible ? "" : "noCompatible"}`}>
            {status.version}
          </div>
          <div className="version compatible">
            {service.version}
          </div>
          <div className="build">
            {status.build}
          </div>
          <div className="commit">
            {status.commit ? status.commit : ""}
          </div>
          <div
            className={`status ${status.alive ? "alive" : "dead"}`}
          />
        </div>
      );
    });
    content = <div className="services-content">{items}</div>;
  }

  const handleCopy = useCallback(() => {
    const tableData = aboutStore.data.map((status) => {
      const service = servicesVersions.find((service) => {
        return service.name === status.name;
      });
      // eslint-disable-next-line max-len
      return `${status.name}, ${status.version}, ${service.version}, ${status.build ? status.build : ""}, ${status.commit ? status.commit : ""}, ${status.alive ? "alive" : "dead"}`;
    });
    const tableString = ["Service, Version, Compatible Version, Build, Commit, Status"].concat(tableData).join("\n");
    copyTextToClipboard(tableString,
      () => {
        notification.success("Информация \"О системе\" скопирована в буфер обмена", { autoClose: 1000 });
        toggleModal();
      },
      (err) => {
        notification.warning(`Информация "О системе" не скопирована в буфер обмена: ${err}`, { autoClose: 2000 });
      }
    );
  }, []);
  const buttons = ([
    <Components.Button 
      key="copy"
      text="Копировать"
      icon="copy-M"
      onPress={handleCopy}
      color="action"
    />,
    <Components.Button 
      key="close"
      text="Закрыть"
      icon="close-M"
      onPress={toggleModal}
      color="negative"
    />
  ]
  );
  return (
    <modal.Window
      name="about"
      icon="plane-M"
      show={isVisible}
      title="О системе"
      buttons={buttons}
      onKeyPressEsc={toggleModal}
      onBackdropClick={toggleModal}
    >
      {content}
    </modal.Window>
  );
});

export default About;
