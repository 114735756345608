import { observable, computed } from "mobx";

/**
 * Модель вида связи
 *
 * @class KindModel
 */
class KindModel {
  @observable
  uid = undefined;
  @observable
  name = undefined;
  @observable
  class = undefined;
  @observable
  destCaption = undefined;
  @observable
  sourceCaption = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid
   * @param {String} params.name
   * @param {String} params.class
   * @param {String} params.destCaption
   * @param {String} params.sourceCaption
   *
   * @retrun {KindModel}
   */
  static create({
    uid,
    class:klass,
    name,
    destCaption,
    sourceCaption
  }) {
    return new KindModel({
      uid,
      class: klass,
      name,
      destCaption,
      sourceCaption
    });
  }

  constructor(params) {
    this.uid = params.uid;
    this.name = params.name;
    this.class = params.class;
    this.destCaption = params.destCaption;
    this.sourceCaption = params.sourceCaption;
  }

  
  /**
   * ID объекта
   *
   * @return {String}
   */
  @computed
  get id() {
    return this.uid;
  }

  /**
   * Строковое название объекта
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }
}

export default KindModel;
