import Server from "~/core/api/server";

/**
 * Api для трассировки и анализа связей
 * 
 * @class TraceApi
 */
class TraceApi extends Server {
  methods = {
    getBaseTraceSchemas: {
      url:         this.getServiceUrl("tracer", "api/v2/schemes"),
      method:      "GET",
      textProcess: "Загрузка списка схем отчетов о трассировке ...",
      textSuccess: null,
      // eslint-disable-next-line max-len
      textError:   this.template `Во время загрузки списка схем отчетов о трассировке произошла ошибка: ${0}`
    },
    requestComposeData: {
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Загрузка данных трассировки ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных трассировки произошла ошибка: ${0}`,
      timeout:     600000
    },
    printReportToPdf: {
      url:            this.getServiceUrl("tables", "api/v2/traceprint"),
      method:         "POST",
      accept:         "application/pdf",
      textProcess:    "Печать отчета о трассировке в pdf...",
      responseIsBlob: true,
      textSuccess:    null,
      textError:      this.template`Во время печати отчета о трассировке в pdf произошла ошибка: ${0}`,
      timeout:        600000
    },
    printGapReportToPdf: {
      url:            this.getServiceUrl("tables", "api/v2/analyzeprint"),
      method:         "POST",
      accept:         "application/pdf",
      textProcess:    "Печать отчета GAP анализа в pdf...",
      responseIsBlob: true,
      textSuccess:    null,
      textError:      this.template`Во время печати отчета GAP анализа в pdf произошла ошибка: ${0}`,
      timeout:        600000
    },
    printReportToOdt: {
      url:            this.getServiceUrl("tables", "api/v2/traceprint"),
      method:         "POST",
      accept:         "application/vnd.oasis.opendocument.text",
      textProcess:    "Печать отчета о трассировке в odt...",
      responseIsBlob: true,
      textSuccess:    null,
      textError:      this.template`Во время печати отчета о трассировке в odt произошла ошибка: ${0}`,
      timeout:        600000
    },
    printGapReportToOdt: {
      url:            this.getServiceUrl("tables", "api/v2/analyzeprint"),
      method:         "POST",
      accept:         "application/vnd.oasis.opendocument.text",
      textProcess:    "Печать отчета GAP анализа в odt...",
      responseIsBlob: true,
      textSuccess:    null,
      textError:      this.template`Во время печати отчета GAP анализа в odt произошла ошибка: ${0}`,
      timeout:        600000
    },
    printReportToDocx: {
      url:            this.getServiceUrl("tables", "api/v2/traceprint"),
      method:         "POST",
      accept:         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      textProcess:    "Печать отчета о трассировке в docx..",
      responseIsBlob: true,
      textSuccess:    null,
      textError:      this.template`Во время печати отчета о трассировке в docx произошла ошибка: ${0}`,
      timeout:        600000
    },
    printGapReportToDocx: {
      url:            this.getServiceUrl("tables", "api/v2/analyzeprint"),
      method:         "POST",
      accept:         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      textProcess:    "Печать отчета GAP анализа в docx...",
      responseIsBlob: true,
      textSuccess:    null,
      textError:      this.template`Во время печати отчета GAP анализа в docx произошла ошибка: ${0}`,
      timeout:        600000
    }
  };


  /**
   * Загрузить список схем таблиц отчетов о трассировке
   *
   * @return {Promise}
   */
  async getBaseTraceSchemas() {
    return await this.request("getBaseTraceSchemas");
  }

  /**
   * Подготовить данные для запроса трассировки связей.
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при трассировке
   * 
   * @return {Object}
   */
  getTraceAnalyizeReqData(schema, values) {
    return {
      operationName: "TraceQuery",
      variables:     {
        scheme: { ...schema,
          parameterValues: values
        }
      },
      query: `query TraceQuery($scheme: JSON!) {
  Trace(scheme: $scheme) {
    __typename
    ... on AisObject {
      uid
      class
      version
      representation
      fullObjectPath {
        uid
        class
        version
        title
        members {
          kindUid
        }
      }
      members {
        uid
        objectId
        kindUid
        kind {
          uid
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    __typename
    ... on Object {
      id
      class
      version
      members {
        uid
        kind {
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    __typename
    ... on RelationsObject {
      uid
      class
      representation
    }
  }
}` };
  }

  /**
   * Произвести трассировку связей. Запрос просисходит напряму в Composer 
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при трассировке
   * 
   * @return {Promise}
   */
  async traceAnalyize(schema, values) {
    const reqData = this.getTraceAnalyizeReqData(schema, values); 

    const data = await this.request("requestComposeData", reqData);
    return data.Trace;
  }

  /**
   * Произвести печать трассировки связей в pdf формате.
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при трассировке
   * @param {String} format тип экспорта печати - pdf, odt, docx
   * 
   * @return {Promise}
   */
  async printTraceAnalyize(schema, values, format = "pdf") {
    const reqData = this.getTraceAnalyizeReqData(schema, values); 

    let url = "printReportToPdf";

    switch (format) {
      case "pdf":
        url = "printReportToPdf";
        break;
      case "odt":
        url = "printReportToOdt";
        break;
      case "docx":
        url = "printReportToDocx";
        break;
    }

    const data = await this.request(url, reqData);
    return data;
  }

  /**
   * Подготовить данные для запроса GAP анализа связей.
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при трассировке
   * 
   * @return {Object}
   */
  getAnalyzeQueryReqData(schema, values) {
    return {
      operationName: "AnalyzeQuery",
      variables:     {
        scheme: { ...schema,
          parameterValues: values
        }
      },
      query: `query AnalyzeQuery($scheme: JSON!) {
  Analyze(scheme: $scheme) {
    __typename
    ... on AisObject {
    	uid
      class
      version
      representation
      fullObjectPath {
        uid
        class
        version
        title
        members {
          kindUid
        }
      }
      members {
        uid
        objectId
        kindUid
        kind {
          uid
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    __typename
    ... on Object {
      id
      class
      version
      members {
        uid
        kind {
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    __typename
    ... on RelationsObject {
      uid
      class
      representation
    }
    __typename
    ... on GAPs {
      items 
    }
    __typename
    ... on ExpectedItem {
      class
      expected
    }
  }
}` };
  }

  /**
   * Произвести GAP анализ связей. Запрос просисходит напряму в Composer 
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при анализе
   * 
   * @return {Promise}
   */
  async gapAnalyize(schema, values) {
    const reqData = this.getAnalyzeQueryReqData(schema, values);

    const data = await this.request("requestComposeData", reqData);
    return data.Analyze;
  }

  /**
   * Произвести печать GAP анализ связей в pdf формате.
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при анализе
   * @param {String} format тип экспорта печати - pdf, odt, docx
   * 
   * @return {Promise}
   */
  async printGapAnalyize(schema, values, format = "pdf") {
    const reqData = this.getAnalyzeQueryReqData(schema, values); 

    let url = "printGapReportToPdf";

    switch (format) {
      case "pdf":
        url = "printGapReportToPdf";
        break;
      case "odt":
        url = "printGapReportToOdt";
        break;
      case "docx":
        url = "printGapReportToDocx";
        break;
    }

    const data = await this.request(url, reqData);
    return data;
  }
}

export default TraceApi;
