import React, { useCallback } from "react";
import AisIcon from "~/core/components/AisIcon";
import { Components } from "@ais3p/ui-framework";
import "./css/plus-menu-btn.scss";

const PlusMenuButton = ({ tooltip, icon, onPress }) => {
  const onClickBtn = useCallback((e) => {
    e.stopPropagation();
    onPress(e);
  }, [onPress]);
  return (
    <Components.Tooltip content={tooltip}>
      <div onClick={onClickBtn} className={"plus-menu-button"}>
        <AisIcon icon={icon} />
      </div>
    </Components.Tooltip>
  );
};

export default PlusMenuButton;
