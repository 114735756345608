import { observer } from "mobx-react";
import React from "react";

const PlusPreview = observer(({ style }) => {
  return (
    <div style={style} className="plus-preview">
      <div className={"menu-items-preview"}>
      </div>
    </div>
  );
});

export default PlusPreview;
