import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import useStores from "~/core/utils/useStores";
import AisIcon from "./AisIcon";
import { getPropsToOpenLayoutTool } from "~/core/utils";
import { CLS_LIBRARY_FILE, CLS_KIND } from "../constants/Classes";
import { Components, ContextMenu  } from "@ais3p/ui-framework";
import AisObjectTooltip from "./AisObjectTooltip";
import { CTX_MENU_AIS_OBJECT_LINK } from "../constants/ContextMenu";

/**
 * Прямоугольник а-ля кнопка отображающий АИС объект (нечто с uid, name и значком)
 */
const AisObject = observer(({ object, icon, isPending, className, withVersion, noToolTip }) => {
  const [processing, setProcessing] = useState(false);
  const { layoutStore, rootStore, objectStore } = useStores();
  
  /**
   * Откроет объект в подходящем инструменте.
   *
   * TODO: (@ai): это надо в отдельную функцию или хук? Часто повторяется.
   */
  const openItem = useCallback(
    async() => {
      if (!object) return;
      let toolToOpen = {
        component: object.tool || object.domain
      };
      if (object.class === CLS_LIBRARY_FILE) {
        toolToOpen = {
          component: "viewer"
        };
      }

      let itemToOpen;

      setProcessing(true);
      try {
        itemToOpen = await getPropsToOpenLayoutTool(object, objectStore);
      } catch (exc) {
        rootStore.onError(exc);
      } finally {
        setProcessing(false);
      }

      if (!itemToOpen) return;
      layoutStore.open({ ...itemToOpen, ...toolToOpen });
    },
    [object]
  );

  if (processing || isPending) {
    return (
      <div 
        className={classNames("ais-object", className)} 
        data-tooltip="Пожалуйста подождите"
      >
        <div className="object">
          <Components.Preloader size={1} className="icon" />
          <div className="label">Идет загрузка объекта…</div>
        </div>
      </div>
    );
  }

  if (!object) {
    return (
      <div 
        className={classNames("ais-object", className)} 
      >
        <div className="object">
          <AisIcon className="icon" icon={"cancel-M"} />
          <div className="label">Нет объекта</div>
        </div>
      </div>
    );  
  }
  const canGoToObject = object.class !== CLS_KIND;

  const content = (
    <div 
      className={classNames("ais-object", className)} 
      onClick={canGoToObject ? openItem : null} 
      // data-tooltip={(canGoToObject && !noToolTip) ? "Перейти к объекту" : null}
    >
      <ContextMenu.Trigger
        menuId={CTX_MENU_AIS_OBJECT_LINK}
        context={{ object }}
        className={"object"}
      >
        <AisIcon className="icon" item={object} icon={icon} />
        <div className="label">{object?.title || object?.name}</div>
        {!!(object && object.version) && withVersion && 
          <div className="version">
            <AisIcon className="icon" icon={"version-M"} />
            {object.version}
          </div>
        }
      </ContextMenu.Trigger>
    </div>
  );
  
  if (noToolTip || (!object.fullObjectPath || (object.fullObjectPath && object.fullObjectPath.length === 0))) {
    return content;
  }

  return (
    <Components.Tooltip 
      content={<AisObjectTooltip object={object} canGoToObject={canGoToObject} />}
    >
      {content} 
    </Components.Tooltip>
  );
});

export default AisObject;
