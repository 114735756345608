import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Components, ContextMenu } from "@ais3p/ui-framework";
import { useStores } from "../utils";

const ChildButton = observer(({
  item, 
  onClick, 
  getPresset, 
  menuCollect,
  ws,
  id, 
  icon, 
  loading,
  labelPosition
}) => {
  const { accountStore } = useStores();
  
  const onClickHandler = useCallback(() => {
    onClick && onClick(item);
  }, [onClick, item]);

  const onMenuClick = useCallback((e, contextMenuData) => {
    const { action, item } = contextMenuData;
    const presset = getPresset();
    switch (action) {
      case "save":
        accountStore.saveWorkspaceToItem(item.id, presset);
        break;
      case "edit":
        accountStore.showForm(item.id, presset);
        break;
      case "delete":
        accountStore.deleteWorkspace(item.id);
        break;
    }
  }, [getPresset]);

  const contextMenuCollect = useCallback((props) => {
    menuCollect && menuCollect(props);
    return props;
  }, [menuCollect]);

  const attributes = {
    "data-tooltip":    item && item.name,
    "data-tooltip-at": labelPosition
  };
  return (
    <div key={id} className={"button-wrapper"} {...((ws && ws.isPending) || loading ? {} : attributes)}>
      <ContextMenu.Trigger
        key={id}
        id={(ws && ws.isPending) || loading || !item.hasMenu ? "" : "treeId"}
        item={item}
        menuType={"HEADER"}
        holdToDisplay={-1}
        onItemClick={onMenuClick}
        collect={contextMenuCollect}
      >
        <Components.Button
          size={3}
          onPress={onClickHandler}
          icon={icon}
          isLoading={(ws && ws.isPending) || loading}
        />
      </ContextMenu.Trigger>
    </div>
  );
});

export default ChildButton;
