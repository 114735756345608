/**
 * Функция для усечения строки до определенной длины. Если переданная строка окажется длинее,
 * чем `maxlength`, то строка обрежется и добавится `...`
 * 
 * @param {String} str строка для усечения
 * @param {Number} maxLength максимальная длина строки
 * @returns {String} усеченная строка
 */
const truncateString = (str, maxLength = 32) => {
  if (!str) {
    return "";
  }

  const text = str.trim();

  return text.length > maxLength ? `${text.slice(0, maxLength - 1)}…` : text;
};

// eslint-disable-next-line import/prefer-default-export
export { truncateString };
