import { action, observable } from "mobx";
import Chunk from "./Chunk";

class Varchar extends Chunk {
  @observable
  caretPosition = 0;
  @action
  getValueFromPosition(pos) {
    return this.value.substring(pos, this.value.length);
  }

  @action
  clearValueAfterPosition(pos) {
    this.setValue(this.value.substring(0, pos));
  }

  @action
  setCaretPosition(pos) {
    this.caretPosition = pos;
  }

  @action
  splitAtPosition(pos, preventBubble = false) {
    this.parent.splitAtItem(this.uid, pos, preventBubble);
    this.caretPosition = null;
  }

  @action
  insertPicture(pos, src) {
    this.parent.splitAtItemWithInlinePicture(this.uid, pos, src);
  }

  @action
  insertFormula(pos) {
    this.parent.splitAtItemWithInlineFormula(this.uid, pos);
  }

  @action
  mergeByCondition(delta) {
    if ((delta === -1 && this.isFirst) || (delta === 1 && this.isLast)) {
      this.parent.mergeByCondition(delta);
    }
  }
}

export default Varchar;
