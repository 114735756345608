import React, { useCallback, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import {
  ISSUES_VIEW_KANBAN,
  ISSUES_VIEW_TABLE,
  ISSUES_VIEW_ISSUE
} from "../../constants/issuesViews";
// import { FILTERS_CFG } from "../../constants/config";
import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS
} from "~/core/constants/SidePanels";
import IssueStore from "../../stores/IssueStore";
import InfoToolWindow from "~/core/components/InfoToolWindow/InfoToolWindow.jsx";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";

/**
 * Toolbar для задач
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Boolean} params.canBeEditable признак, можно ли редактирвоать Задачу
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {String} params.tabId id вкладки в Layout
 * @param {Object} params.isSubVisible флаг отображения боковой доп панели
 * @param {String} params.issueUid uid Задачи. Если это параметр передан, значит инструмент отрыт для 
 * @param {String} params.projectUid uid Проекта. Если это параметр передан, значит инструмент отрыт для 
 * работы с  Задачами
 */
const IssuesToolbar = observer((props) => {
  const {
    store,
    canBeEditable,
    layoutStore,
    tabId,
    isSubVisible,
    issueUid,
    projectUid
  } = props;

  const { kindsStore, accountStore } = useStores();
  const [infoIsVisible, setInfoIsVisible] = useState(false);

  const readOnly = useMemo(() => {
    if (store.activeIssue) {
      return !store.activeIssue.isEditingMode;
    }
    return store.readOnly;
  }, [store.readOnly, store.activeIssue, store.activeIssue && store.activeIssue.isEditingMode]);

  const onToggleSubPanelKinds = useCallback(
    () => {
      layoutStore.toggleSubPanel(tabId, SIDEPANEL_KINDS_ATTRS);
    },
    [tabId]
  );

  const onToggleSubPanelRelations = useCallback(
    () => {
      layoutStore.toggleSubPanel(tabId, SIDEPANEL_RELATIONS);
    }, 
    [tabId]
  );


  const setReadOnly = useCallback(() => {
    if (store.activeIssue) {
      store.activeIssue.setIsEditingMode(false);
    } else {
      store.updateReadOnly(true);
    }
  }, [store, store.activeIssue]);

  const setEditable = useCallback(() => {
    if (store.activeIssue) {
      store.activeIssue.setIsEditingMode(true);
    } else {
      store.updateReadOnly(false);
    }
  }, [store, store.activeIssue]);

  const onSaveIssueChanges = useCallback(async() => {
    const issue = store.activeIssue;
    if (!issue) {
      return;
    }

    issue && issue.setIsPending(true);
    try {
      const b = await store.saveIssue(issue, issue.jsonTmpValues);
      if (b) {
        issue.setIsEditingMode(false);
      }
    } catch (e) {
      store.onError(e);
    } finally {
      issue && issue.setIsPending(false);
    }
  }, [store.activeIssue]);

  const onCancelIssueChanges = useCallback(() => {
    const issue = store.activeIssue;
    if (!issue) {
      return;
    }
    issue.setIsEditingMode(false);
  }, [store.activeIssue]);


   
  const toggleShowIssueJournal = useCallback(() => {
    const issue = store.activeIssue;
    if (!issue) {
      return;
    }
    issue.toggleShowJournal();
  }, [store.activeIssue]);

  const createNewIssue = useCallback(
    (trackerId) => {
      const tracker = store.getTracker(trackerId);
      if (!canBeEditable) {
        return;
      }

      if (!tracker) {
        store.onError(`Трекер с id=${trackerId} не найден!`);
        return;
      }

      const newIssue = store.createNewIssue(tracker.config);
      store.setActiveIssue(newIssue);
    },
    [canBeEditable]
  );

  useEffect(() => {
    if (!!issueUid) {
      store.setIssuesViewMode(ISSUES_VIEW_ISSUE);
    }
  }, [issueUid]);

  const toggleShowIssuesModeTable = useCallback(
    () => {
      store.setIssuesViewMode(ISSUES_VIEW_TABLE);
    },
    []
  );

  const toggleShowIssuesModeKanban = useCallback(
    () => {
      store.setIssuesViewMode(ISSUES_VIEW_KANBAN);
    },
    []
  );
  
  const onToggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);


  const rightButtons = useMemo(() => {
    let modeButtons = [
      (
        <Components.Button
          key="view"
          icon="review-M"
          tooltip="Просмотр"
          isSelected={readOnly || store.issuesViewMode === ISSUES_VIEW_TABLE}
          onPress={setReadOnly}
        />
      )
      // (<Components.Spacer key="right-spacer-2" />),
      // (
      //   <Components.Button
      //     key={"info-M"} icon="info-M" tooltip={"Справка"}
      //     onPress={onToggleInfoModal}
      //     isSelected={infoIsVisible}
      //   />
      // )
    ];

    if (
      canBeEditable &&
      (store.issuesViewMode === ISSUES_VIEW_KANBAN || store.activeIssue)
    ) {
      modeButtons.unshift(
        <Components.Button
          key="edit"
          icon="editing-M"
          tooltip="Редактирование"
          isSelected={!readOnly}
          onPress={setEditable}
        />
      );
    }
    
    const sidePanelsButtons = [
      <Components.Spacer key="right-spacer-2" />,
      <Components.Button
        key={SIDEPANEL_RELATIONS}
        icon="app-relations-M"
        tooltip="Связи"
        isSelected={isSubVisible[SIDEPANEL_RELATIONS]}
        onPress={onToggleSubPanelRelations}
      />,
      <Components.Button
        key={SIDEPANEL_KINDS_ATTRS}
        icon="app-attributes-M"
        tooltip="Виды и атрибуты"
        isSelected={isSubVisible[SIDEPANEL_KINDS_ATTRS]}
        onPress={onToggleSubPanelKinds}
      />
    ];

    modeButtons = modeButtons.concat(sidePanelsButtons);

    if (store.activeIssue) {
    // Активна вкладка с задачей
      // modeButtons.push(<Components.Spacer key="spacer-edit" />);
      const issue = store.activeIssue;
      // return modeButtons.concat(sidePanelsButtons, [
      modeButtons.push(
        <Components.Button
          key="journal"
          icon="log-M"
          tooltip="Журнал изменений"
          isSelected={issue && issue.isShownJournal}
          onPress={toggleShowIssueJournal}
        />
      );
    }

    modeButtons.push(
      <Components.Button
        key={"info-M"} icon="info-M" tooltip={"Справка"}
        onPress={onToggleInfoModal}
        isSelected={infoIsVisible}
      />
    );

    return modeButtons;
  }, [
    readOnly,
    canBeEditable,
    store.issuesViewMode,
    isSubVisible,
    store.activeIssue,
    store.activeIssue && store.activeIssue.isShownJournal    
  ]);

  const leftButtons = useMemo(() => {
    if (store.activeIssue) {
      // Активна вкладка с задачей
      const issue = store.activeIssue;
      if (issue.isEditingMode) {
        return  [
          <Components.Button
            key="saveChanges"
            icon="save-M"
            tooltip="Сохранить изменения"
            color={issue.hasChanges ? "positive" : ""}
            isDisabled={!canBeEditable || !issue.isTmpValuesValid || !issue.hasChanges}
            isLoading={issue.isPending}
            onPress={onSaveIssueChanges}
          />,
          <Components.Button
            key="cancelChanges"
            icon="cancel-M"
            tooltip="Отменить изменения"
            onPress={onCancelIssueChanges}
          />
        ];
      }
      return null;
    }

    if (!projectUid) {
      return [];
    }

    // Режим просмотра списка задач
    const kindMember = kindsStore.getKindMemberSync(projectUid);
    // Формируем список кнопок для создания задач
    const createIssueButtons = store.trackerList
      .filter((tracker) => {
        const kind = kindsStore.getKind(tracker.uid);
        return kindMember && kindMember.allowedTasks.has(kind.uid);
      })
      .map((tracker) => {
        const kind = kindsStore.getKind(tracker.uid);
        return (
          <Components.Button
            key={tracker.id}
            icon={kind && accountStore.getIcon(kind.uid)}
            tooltip={`Создать "${tracker.name}"`}
            isDisabled={!canBeEditable}
            // eslint-disable-next-line react/jsx-no-bind
            onPress={() => {
              return createNewIssue(tracker.id);
            }}
          />
        );
      });

    return [].concat(
      [
        <Components.Button
          key={ISSUES_VIEW_TABLE}
          icon="table-M"
          tooltip="Отобразить список задач в Табличном представлении"
          isSelected={store.issuesViewMode === ISSUES_VIEW_TABLE}
          onPress={toggleShowIssuesModeTable}
        />,
        <Components.Button
          key={ISSUES_VIEW_KANBAN}
          icon="module-M"
          tooltip="Отобразить список задач в Канбан представлении"
          isSelected={store.issuesViewMode === ISSUES_VIEW_KANBAN}
          onPress={toggleShowIssuesModeKanban}
        />,
        <Components.Spacer key={"spacer-modes"} />
      ],
      createIssueButtons
    );
  }, [
    // additionalButtons,
    store.trackerList,
    store.activeIssue,
    store.activeIssue && store.activeIssue.isPending,
    canBeEditable,
    isSubVisible,
    store.issuesViewMode,
    projectUid,
  
    store.activeIssue && store.activeIssue.hasChanges,
    store.activeIssue && store.activeIssue.isTmpValuesValid
  ]);

  return (
    <>
      <Components.ToolBar right={rightButtons} >{leftButtons}</Components.ToolBar>
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.issues}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={onToggleInfoModal}
        />
      )}
    </>
  );
});

IssuesToolbar.propTypes = {
  store:         PropTypes.instanceOf(IssueStore),
  canBeEditable: PropTypes.bool,
  layoutStore:   PropTypes.object,
  tabId:         PropTypes.string,
  isSubVisible:  PropTypes.object,
  projectUid:    PropTypes.string,
  issueUid:      PropTypes.string

};

export default IssuesToolbar;
