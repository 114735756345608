import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import File from "../../viewer/components/File";
import { Components, Modal } from "@ais3p/ui-framework";
import ViewerTool from "~/modules/viewer/tools/ViewerTool";

const InlinePicture = observer(({ data, measure, title }) => {
  const { uid, value, isEditingPicture, diffClass } = data;
  const [loaded, setLoaded] = useState(null);
  const [isShowPictureInModal, setIsShowPictureInModal] = useState(false);

  const onLoad = useCallback((data) => {
    setLoaded(data);
    measure && measure();
  });

  useEffect(() => {
    onLoad(null);
  }, [value]);

  const onClickPicture = useCallback(() => {
    setIsShowPictureInModal(!!value && !isEditingPicture);
  }, [value, isEditingPicture]);

  const onCancelShowImage = useCallback(() => {
    setIsShowPictureInModal(false);
  }, []);

  // const contextMenuCollect = useCallback((props) => {
  //   const menuItems = [
  //     {
  //       icon:  "editing-M",
  //       title: "Изменить рисунок",
  //       data:  { action: "editPicture" }
  //     },
  //     {
  //       icon:  "delete-M",
  //       title: "Удалить",
  //       data:  { action: "delete" }
  //     }
  //   ];
  //   layoutStore.menuCollect({
  //     menuItems,
  //     onItemClick: props.onItemClick
  //   });
  //   return props;
  // }, []);

  // const onMenuClick = useCallback((e, contextMenuData) => {
  //   const { action } = contextMenuData;
  //   switch (action) {
  //     case "delete":
  //       data.selfDestruct();
  //       break;
  //     case "editPicture":
  //       data.setEditingPicture(true);
  //       break;
  //     default:
  //       break;
  //   }
  // }, []);

  const [src, setSrc] = useState(null);
  const refInput = useRef();

  const unsetIsEditingPicture = useCallback(() => {
    data.setEditingPicture();
    setSrc(null);
  }, []);

  const onSelectImage = useCallback(async(e) => {
    const file = e.target.files[0];
    try {
      const src = await data.uploadFile(file);
      setSrc(src);
    } catch (ex) {
      console.warn(ex.message);
    }
  }, []);

  const onOk = useCallback(async() => {
    await data.setValue(src);
    unsetIsEditingPicture();
  }, [src]);

  const onBrowse = useCallback(() => {
    if (refInput && refInput.current) {
      refInput.current.value = null;
      refInput.current.click();
    }
  }, [refInput]);

  const modalButtons = useMemo(() => {
    return [
      ( 
        <Components.Button
          key="add"
          text="Добавить"
          icon="plus-M"
          onPress={onOk}
          isDisabled={!src}
          color="action"
        />
      ), (
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={unsetIsEditingPicture}
          color="negative"
        />
      )
    ];
  }, [onOk, src, unsetIsEditingPicture]);
  
  return (
    <React.Fragment>
      <span
        id={`${uid}`}
        contentEditable={false}
        className={`inline-picture ${diffClass}`}
        onClick={onClickPicture}
      >

        {value && (
          <File
            file={value}
            type={"image"}
            className={"inline-picture"}
            onLoad={onLoad}
            loaded={loaded}
          />
        )}
        {!value && (
          <Components.Icon
            name={"image-M"}
            data-tooltip="Нет изображения"
            data-tooltip-at="bottom"
            className="picture-image-empty-icon"
          />
        )}
        <Modal.Window
          name="image"
          icon="image-M"
          show={isEditingPicture}
          title={"Рисунок"}
          buttons={modalButtons}
          onKeyPressEnter={onOk}
          onKeyPressEsc={unsetIsEditingPicture}
        >
          <div className={"picture-editor"}>
            <div className={"picture-editor-image"}>
              <a
                className={"picture-editor-image-browse"}
                href="#"
                onClick={onBrowse}
              >
                Обзор
              </a>
              <input
                type="file"
                accept="image/*"
                ref={refInput}
                onChange={onSelectImage}
                style={{ display: "none" }}
              />
              {!src && (
                <Components.Icon
                  className="picture-editor-image-empty-icon"
                  name={"image-M"}
                />
              )}

              {src && <InlinePicture data={{ uid: "temp", value: src }} />}
            </div>
          </div>
        </Modal.Window>
      </span>
      <Modal.Window
        name="show-picture-in-modal"
        icon="image-M"
        show={isShowPictureInModal}
        title={title}
        buttons={[
          <Components.Button
            key="cancel"
            text="Отмена"
            icon="cancel-M"
            onPress={onCancelShowImage}
            color="negative"
          />
        ]}
        onKeyPressEsc={onCancelShowImage}
      >
        <ViewerTool
          type="image"
          className="viewer-in-modal"
          file={value}
        />
      </Modal.Window>
    </React.Fragment>
  );
});
export default InlinePicture;
