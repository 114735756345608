import React from "react";

import { Components } from "@ais3p/ui-framework";
import {
  CLS_ISSUE,
  CLS_REPO_DIR,
  CLS_REPO_FILE,
  CLS_REPO_FILE_FUNCTION,
  CLS_REPO_BRANCHES,
  CLS_REPO_TAGS,
  CLS_REPO_GIT_REPOSITORY,
  CLS_REPO_GIT_TAG,
  CLS_REPO_GIT_BRANCH,
  CLS_LIBRARY_TEXT_MATERIAL,
  CLS_LIBRARY_TEXT_VERSION,
  CLS_LIBRARY_COLLECTION,
  CLS_LIBRARY_REPOSITORY,
  CLS_TEXT_RUBRIC,
  CLS_TEXT_ENUMERATION_ITEM,
  CLS_TEXT_TABLE_ROW,
  CLS_TEXT_TABLE_CELL,
  CLS_TEXT_INDENT,
  CLS_TEXT_PICTURE,
  CLS_TEXT_FORMULA,
  CLS_TEXT_ENUMERATION,
  CLS_TEXT_TABLE,
  CLS_TEXT_CODE,
  CLS_TEXT_CHUNK_VARCHAR
} from "../constants/Classes";

const iconRender = (item, returnIconString, makeFromString) => {
  if (item && item.isLoading) {
    return <Components.Preloader size={1} style={{ margin: "0.25rem" }} />;
  }
  if (makeFromString) {
    if (returnIconString) {
      return item;
    } else {
      return <Components.Icon name={item} className="expander big" />;
    }
  }

  let type = item && item.type;
  if (item && item.objectClass) {
    type = item.objectClass;
  }
  if (item && item.class) {
    type = item.class;
  }
  if (item && item.payload && item.payload.class) {
    type = item.payload.class;
  }
  if (item && item.className) {
    type = item.className;
  }
  if (item && item.etype) {
    type = item.etype;
  }
  if (item && item.libraryRepresentation) {
    type = item.libraryRepresentation.class;
  }
  if (item && item.textRepresentation) {
    type = item.textRepresentation.className;
  }
  if (item && item.issueRepresentation) {
    type = item.issueRepresentation.className;
  }
  let icon = "aggr-M";

  switch (type) {
    // real OBJ types
    case "library.material.Alien":
      icon = "app-pof-M";
      break;
    case CLS_REPO_GIT_REPOSITORY:
    case "vcs.git.repository":
      icon = "fork-M";
      break;
    case CLS_REPO_BRANCHES:
    case "vcs.branches":
      icon = "repository-M";
      break;
    case CLS_REPO_TAGS:
    case "vcs.tags":
      icon = "token-M";
      break;
    case CLS_REPO_GIT_TAG:
    case "vcs.git.tag":
      icon = "token-M";
      break;
    case CLS_REPO_GIT_BRANCH:
    case "vcs.git.branch":
      icon = "repository-M";
      break;
    case CLS_REPO_DIR:
    case "sourcecode.directory":
    case "vcs.directory":
    case "tree":
      icon = "collection-closed-M";
      break;
    case CLS_REPO_FILE:
    case "sourcecode.file":
    case "vcs.file":
      icon = "wmaterial-M";
      break;
    case CLS_REPO_FILE_FUNCTION:
      icon = "function-M";
      break;
    case "blob":
      icon = "log-M";
      break;
    case "aliens.pof.Object":
    case "library.material.Report":
      icon = "waiting-M";
      break;
    case "aggr.pof.object":
      icon = "proxy-obj-M";
      break;
    case "aggr.pof.sql":
      icon = "po-sql-M";
      break;
    case "aggr.pof.requality":
      icon = "po-requality-M";
      break;
    case "aggr.pof.redmine":
      icon = "po-redmine-M";
      break;
    case "aggr.pof.mantis":
      icon = "po-mantis-M";
      break;
    case "aggr.pof.icd":
      icon = "po-icd-M";
      break;
    case "kind":
      icon = "app-attributes-M";
      break;
    case "kind-member":
      icon = "token-M";
      break;
    case CLS_TEXT_CHUNK_VARCHAR:
      icon = "string-M";
      break;
    case "aggr.text.header.number":
    case "aggr.text.header.label":
    case "aggr.text.rubric":
    case CLS_TEXT_RUBRIC:
    case "Rubric":
      icon = "paragraph-M";
      break;
    case "aggr.text.indent":
    case CLS_TEXT_INDENT:
    case "Indent":
      icon = "aggr-M";
      break;
    case "aggr.list":
    case "aggr.list.numbers":
    case CLS_TEXT_ENUMERATION:
    case "List":
      icon = "list-mark-M";
      break;
    case "aggr.text.list.item":
    case "aggr.list.item":
    case CLS_TEXT_ENUMERATION_ITEM:
    case "ListItem":
      icon = "data-enum-single-M";
      break;
    case "aggr.text.quote":
    case "Quote":
      icon = "quote-M";
      break;
    case "aggr.text.code":
    case "text.element.code":
    case CLS_TEXT_CODE:
    case "Code":
      icon = "code-M";
      break;
    case "aggr.text.picture":
    case "aggr.text.image":
    case "Picture":
    case CLS_TEXT_PICTURE:
      icon = "image-M";
      break;
    case "Attachment":
    case "aggr.attachment.file":
      icon = "download-M";
      break;
    case CLS_TEXT_TABLE:
    case "aggr.text.table":
    case "Table":
      icon = "table-M";
      break;
    case CLS_TEXT_TABLE_ROW:
    case "TableRow":
      icon = "table-row-M";
      break;
    case CLS_TEXT_TABLE_CELL:
    case "TableCell":
      icon = "table-col-M";
      break;
    case "aggr.text.math":
    case CLS_TEXT_FORMULA:
    case "Formula":
      icon = "formula-M";
      break;
    case CLS_LIBRARY_COLLECTION:
    case "aggr.library.collection":
      icon = "collection-closed-M";
      break;
    case "aggr.build.collection":
      icon = "build-M";
      break;
    case "aggr.wm.text":
    case "text.form.Text":
      icon = "file-ais-M";
      break;
    case "3d.Model":
      icon = "domain-M";
      break;
    case CLS_LIBRARY_REPOSITORY:
    case "library.material.Repository":
      icon = "app-tree-M";
      break;
    case "library.material.3dModel":
      icon = "wireframe-M";
      break;
    case "text.Text":
    case "Material":
    case "library.material.Text":
    case "library.MaterialText":
    case "library.TextMaterial":
    case CLS_LIBRARY_TEXT_MATERIAL:
    case "aggr.wm":
      icon = "wmaterial-M";
      break;
    case CLS_LIBRARY_TEXT_VERSION:
    case "versioning.Version":
      icon = "version-M";
      break;
    case CLS_ISSUE:
    case "aggr.issues.issue":
      icon = "reglament-M";
      break;
    case "aggr.common.file":
    case "files.File":
    case "files.File":
    case "library.FileMaterial":
      switch (item.contentType) {
        case "image/gif":
        case "image/jpg":
        case "image/jpeg":
        case "image/pjpeg":
        case "image/png":
        case "image/svg+xml":
        case "image/tiff":
        case "image/vnd.microsoft.icon":
        case "image/vnd.wap.wbmp":
        case "image/webp":
          icon = "file-picture-M";
          break;
        case "text/cmd":
        case "text/css":
        case "text/csv":
        case "text/html":
        case "text/javascript":
        case "text/plain":
        case "text/php":
        case "text/xml":
        case "text/markdown":
          icon = "file-text-M";
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          icon = "file-docx-M";
          break;
        case "application/vnd.oasis.opendocument.text":
          icon = "file-odt-M";
          break;
        case "application/x-c3d":
          icon = "object-M";
          break;
        case "application/msword":
          icon = "file-doc-M";
          break;
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          icon = "file-xls-M";
          break;
        case "application/pdf":
          icon = "file-pdf-M";
          break;
        case "application/zip":
          icon = "archive-zip-M";
          break;
        case "application/rar":
          icon = "archive-rar-M";
          break;
        case "application/tar":
          icon = "archive-tar-M";
          break;
        default:
          icon = "file-unknown-M";
          break;
      }
      break;
    default:
      icon = "aggr-M";
      break;
  }
  if (returnIconString) {
    return icon;
  }
  return <Components.Icon name={icon} className="expander big" />;
};

export default iconRender;
