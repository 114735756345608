import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState, useRef, useLayoutEffect } from "react";
import { Components, Dnd } from "@ais3p/ui-framework";
import classNames from "classnames";

import AisObject from "~/core/components/AisObject";

import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import { 
  DND_NODE_TYPE,
  DND_ISSUE_TYPE,
  DND_EDITORITEM_TYPE,
  DND_SOURCECODE_TYPE,
  DND_CONNECTION_TYPE
} from "~/core/constants/DnD";
import iconRender from "../../../core/utils/IconRender";

/**
 * Компонент для добавления новой связи
 * 
 * @param {KindItem} kind Вид связи
 * @param {Object} targetInfo активный элемент в АИС
 * @param {Function} onAdd callback функция на добавление новой связи
 */
const AddRelationItem = observer(
  ({ kind = {}, targetInfo, onAdd }) => {
    const { sourceCaption, destCaption, name, uid: relationKindUid  } = kind;
    const [direction, setDirection] = useState("to");
    const [droppedItem, setDroppedItem] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [errorDrop, setErrorDrop] = useState();
    const [errorDropStyle, setErrorDropStyle] = useState({ top: 0 });
    const [isHover, setIsHover] = useState(false);

    const { kindsStore, relationStore, configStore, accountStore } = useStores();

    const errorDropEl = useRef(null);
    const config =  configStore.getToolConfig();

    // dsсчитываем позицию отображения сообщения о невозможности кинуть в drop объект
    useLayoutEffect(() => {
      if (!errorDropEl.current || !isHover) {
        return;
      }
      const errorDropRec = errorDropEl.current.getBoundingClientRect();
      setErrorDropStyle({
        top: (errorDropRec.height + 10) * -1
      });
    }, [errorDropEl && errorDropEl.current, isHover]);

    
    /**
     * Обработчик на Drop объекта АИС
     */
    const drop = useCallback((props, monitor) => {
      const item = monitor.getItem();
      if (item) {
        setDroppedItem((item.value && item.value.item) || item, direction);
      }
      hideErrorDrop();
    }, [direction]);

    /**
     * Список ограничений, согласно направлению связи (direction)
     * 
     * @returns {Array<Object>} массив ограничений в формате {as: String, name:String | kind:String}
     */
    const linkRestrictions = useMemo(() => {
      const cfg = config.linkConfig[relationKindUid];
      return cfg && cfg[direction] || [];
    }, [direction, config.linkConfig, relationKindUid]);

    /**
     * Определяем текст с сообщением о возмодных вариантах drop объектах
     */
    const dropPlaceholderText = useMemo(() => {
      if (linkRestrictions.length === 0) {
        return (
          <div className="placeholder-text">
            <div className="placeholder-label">Перетащите объект</div>
          </div>
        );
      }

      const names = [];
      linkRestrictions.forEach((r) => {
        // проверка по Виду объекта
        if (r.kind) {
          const kind = kindsStore.getKind(r.kind);
          if (kind) {
            names.push({ name: kind.name, icon: accountStore.getIcon(kind.uid) });
          }
        }

        // проверка по классу объекта
        if (r.name && r.as) {
          names.push({ name: r.name, icon: iconRender({ class: r.as }, true) });
        }
      });

      // return `Перетащите:  ${names.join(", ")}`;
      return (
        <div className="placeholder-text">
          <div className="placeholder-label">Перетащите:</div>
          {names.map((item, i) => {
            return (
              <div key={i} className="placeholder-item">
                <AisIcon icon={item.icon} />
                {item.name}
                {i < names.length - 1 ? ", " : ""}
              </div>
            );
          })}
        </div>
      );
    }, [direction, linkRestrictions, relationKindUid]);

    /**
     * Событие на покидание курсором  drop области 
     */
    const onMouseLeave = useCallback(() => {
      hideErrorDrop();
    }, []);

    let timerId;

    /**
     * Событие на заход курсором drop области 
     */
    const onHover = useCallback(() => {
      setIsHover(true);
      if (timerId) {
        // если таймер уже был выставлен, сто сбрасываем его
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        // если время вышло, то убираем сообщение о причине невозможности drop объекта
        // так мы решаем проблему с невсегда срабатыванием onMouseOut
        hideErrorDrop();
      }, 500);
    }, []);

    /**
     * Показать сообщение о причине невозможности drop объекта
     * 
     * @param {String} msg текст сообщения о причине невозможности drop объекта
     */
    const showErrorDrop = (msg) => {
      setTimeout(() => {
        setErrorDrop(msg);
      }, 0);
    };

    /**
     * Скрываем сообщение о причине невозможности drop объекта
     */
    const hideErrorDrop = () => {
      clearTimeout(timerId);
      timerId = null;
      setIsHover(false);
      setErrorDrop(null);
    };

    /**
     * Проверка на возмодность Drop объекта
     */
    const canDrop = useCallback(
      (props, monitor) => {
        const item = monitor && (monitor.getItem().value || monitor.getItem());

        if (!item) {
          return false;
        }

        const restrictions = linkRestrictions;
        let canDrop = restrictions.length === 0; // если ограничения не заданы, то значит можно накидывать все

        if (restrictions.length > 0) {
          // если огранчения заданы, то проверяем по ним
          const uid = item.uid || item.id;
          const itemClass = item.class || item.data && item.data.class;
          const kindUids = kindsStore.getItemKindUids(uid);

          restrictions.forEach((r) => {
            // проверка по Виду объекта
            if (r.kind && kindUids.includes(r.kind)) {
              canDrop = true;
            }

            // проверка по классу объекта
            if (r.name && r.as === itemClass) {
              canDrop = true;
            }
          });

          if (!canDrop) {
            showErrorDrop("Переносимый объект не отвечает критериям ограничений!");
          }
        }

        // Проверям, что такая связь уже существует
        if (canDrop) {
          const version = item.version || 0;
          const targetItemUid = `${item.uid}-${version}`;
          let half;
          let relation;
          // получаем информацию о части связи по ее направлению
          if (direction === "to") {
            half = relationStore.ends.get(targetItemUid);
          }

          if (direction === "from") {
            half = relationStore.starts.get(targetItemUid);
          }

          // если нашли часть связи
          if (half) {
            // то ищем, существует ли уже сама связь для текущего Вида
            half.rels.forEach((val, key) => {
              const rel = relationStore.relations.get(key);
              if (rel && rel.kind && rel.kind.uid === relationKindUid) {
                relation = rel;
              }
            });

            // если связь уже существует, то не даем создать дублируемую связь
            if (relation) {
              canDrop = false;
              showErrorDrop("Данная связь уже существует!");
            }
            // canDrop = !relation;
          }
        }
        
        if (canDrop && !isHover) {
          // очищаем от старого сообщения
          showErrorDrop(null);
        }
        return canDrop;
      },
      [name, direction, linkRestrictions, isHover]
    );
    
    /**
     * Callback на переключение направления связи
     */
    const onToggleDirection = useCallback(() => {
      setDirection(direction === "from" ? "to" : "from");
      const fits = canDrop();
      if (!fits) {
        setDroppedItem(null);
      }
    }, [direction, canDrop]);

    /**
     * Удаление брошенного объекта
     */
    const deleteItem = useCallback(() => {
      setDroppedItem(null);
    }, [direction]);

    /**
     * Признак возможности создать новую свзяь
     * 
     * @return {Boolean}
     */
    const canConnect = useMemo(() => {
      return !!droppedItem && !isConnecting && !!kind && !!targetInfo;
    }, [droppedItem, isConnecting, kind]);

    /**
     * Создать новую связь
     */
    const onConnect = useCallback(async() => {
      if (canConnect) {
        setIsConnecting(true);
        try {
          let res = false;

          if (direction === "from") {          
            res = await relationStore.connect(droppedItem, targetInfo, relationKindUid);
          } 
          if (direction === "to") {
            res = await relationStore.connect(targetInfo, droppedItem, relationKindUid);
          }
          if (res) {
            setDroppedItem(null);
            onAdd && onAdd();
          }
        } finally {
          setIsConnecting(false);
        }
      }
    }, [targetInfo, droppedItem, relationKindUid, canConnect, direction, onAdd]);

    // Если тип связи не выбран, то необходимо об этом сообщить пользователю
    if (!relationKindUid) {
      return (
        <div className="add-item-line no-restriction" >
          Необходимо выбрать тип связи
        </div>
      );
    }

    return (
      <div 
        className="add-item-line"
      >
        <div className="add-item-line-wrapper">
          <Components.Button
            color="action"
            // tooltip="Изменить направление"
            tooltip={direction === "from" ? sourceCaption : destCaption}
            icon={direction === "from" ? "arrow-left-M" : "arrow-right-M"}
            onPress={onToggleDirection}
          />
          {/*
            linkRestrictions.length === 0 && relationKindUid  &&
            <div className="empty-rules">
              Не заданы ограничения для выбранной связи
            </div>
          */}
          <div 
            className={classNames("item-drop-target", {
              "no-object": !droppedItem
            })}
            onMouseLeave={onMouseLeave}
            // onMouseOver={onMouseLeave}
          > 
            {!!errorDrop && isHover &&
              <div 
                className="item-drop-target-error"
                ref={errorDropEl}
                style={errorDropStyle}
              >
                {errorDrop}
              </div>}
            <Dnd.Target
              drop={drop}
              canDrop={canDrop}
              hover={onHover}
              accept={[
                DND_NODE_TYPE,
                DND_ISSUE_TYPE,
                DND_EDITORITEM_TYPE,
                DND_SOURCECODE_TYPE,
                DND_CONNECTION_TYPE
              ]}
            >
              {!droppedItem && (
                <div className="placeholder">{dropPlaceholderText}</div>
              )}
              {!!droppedItem && (
                <div
                  key={droppedItem.id}
                  className="object"
                  onClick={deleteItem}
                  data-tooltip={direction === "from" ? sourceCaption : destCaption}
                >
                  <AisObject
                    object={droppedItem} 
                    withVersion={true} 
                    noToolTip={true}
                    store={relationStore}
                  />
      
                  <AisIcon className="delete" icon={"delete-M"} />
                </div>
              )}
            </Dnd.Target>
          </div>
        </div>
        <div className="add-item-line-controls">
          <Components.Button
            icon="ok-M"
            text={"Создать"}
            color="positive"
            isDisabled={!canConnect}
            isLoading={isConnecting}
            onPress={onConnect}
          />
        </div>
      </div>
    );
  }
);

export default AddRelationItem;
