import { observable, computed, action } from "mobx";

import  MessageModel from "../models/messageModel";
import  Api from "../api/wsApi";
import { wsStateTitle } from "../constants/wsApiStates";
import TrackingItemModel from "../models/trackingItemModel";


/** 
 * Хранилище для работы с пакетами, полученными по web socket
 *
 * @class WSStore
 */
class WSStore {
  /**
   * Максимальное кол-во полученных пакетов, которое будет храниться в памяти
   *
   * @type {Number}
   */
  maxMessages = 150;

  /**
   * Массив полученных пакетов
   *
   * @type {Array<MessageModel>}
   */
  @observable
  messages = [];

  /**
   * Uid соединения по websocket
   *
   * @type {String}
   */
  @observable
  connId = undefined;

  @observable trackingItemsMap = new Map();

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.api = new Api({
      onOpen:    this.onOpen.bind(this),
      onMessage: this.onMessage.bind(this),
      onError:   this.onError.bind(this),
      onClose:   this.onClose.bind(this)
    });
  }

  /**
   * Инициализация хранилища
   *
   */
  init(token) {
    this.api.connect(token);
  }

  /**
   * Разъединить соединение
   *
   */
  disconnect() {
    this.api.disconnect();
  }

  @action
  addTrackingItem(data) {
    let item = this.trackingItemsMap.get(data.itemId);
    if (!item) {
      item = new TrackingItemModel(data, this);
      this.trackingItemsMap.set(item.id, item);
    } else {
      item.add(data);
    }
    this.sendData(this.trackingObjects);
  }

  @action
  removeTrackingItem(data) {
    const item = this.trackingItemsMap.get(data.itemId);
    if (item && data.uid) {
      item.remove(data);
    }
    if (item && !data.uid) {
      this.trackingItemsMap.delete(data.itemId);
    }
    this.sendData(this.trackingObjects);
  }

  @computed
  get trackingObjects() {
    const object = {
      connId:   this.connId,
      operator: this.rootStore.accountStore.uid
    };
    const trackObject = {};
    this.trackingItemsMap.forEach((item) => {
      item.output.forEach((track) => {
        trackObject[`${track.domain}-${track.uid}-${track.version}`] = track;
      });
    });
    
    const objects = [];
    Object.keys(trackObject).forEach((key) => {
      if (trackObject[key]) {
        objects.push([trackObject[key].domain, trackObject[key].uid, trackObject[key].version]);
      }
    });

    if (objects.length) {
      object.objects = objects;
    }
    return JSON.stringify(object);
  }

  /**
   * Статус соединиения
   *@return {Boolean}
   */
  @computed
  get isConnected() {
    return this.api.isConnected;
  }

  /**
   * Callback функция на открытие web socket
   *
   * @param {Boolean} value
   */
  onOpen() {
  }

  /**
   * Callback функция на получения сообщения по web socket
   *
   * @param {String} data
   */
  onMessage(data) {
    try {
      if (data) {
        const json = JSON.parse(data);
        if (json.connID) {
          this.setConnId(json.connID);
        } else {
          this.rootStore.objectStore.processMessage(json);
          this.addMessage(MessageModel.create(json, this));
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * Обработка ошибки
   *
   * @param {String} message текст ошибки
   */
  onError(message) {
    this.rootStore.onError(message);
  }

  /**
   * Callback функция на закрытие web socket
   *
   * @param {Number} code код закрытия зарытия сокета
   * @param {String} reason текст причины закрытия сокета
   */
  onClose() {
    // console.log("onClose", code, reason);
  }

  /**
   * Добавить сообщение в список полученных сообщений
   *
   * @param {MessageModel} message
   */
  @action
  addMessage(message) {
    const lastMsg = this.messages.length > 0 ? this.messages[0] : undefined;
    const lastNum = (lastMsg ? lastMsg.num : 0) || 0;
    message.setNum(lastNum + 1);
    this.messages.unshift(message);
    this.messages.splice(this.maxMessages);
  }

  /**
   * Состояние соединения
   * @return {String}
   */
  @computed
  get readyState() {
    return wsStateTitle(this.api.readyState);
  }

  /**
   * Отправить данные по web socket
   * @param {String} data
   */
  sendData(data) {
    if (!this.isConnected) {
      // this.onError("Нет соединения с сервером"); // временное решение  T5680
      console.error("Нет соединения с сервером по websocket");
      return;
    }
    this.api.send(data);
  }

  /**
   * Очистить очередь сообщений
   */
  @action
  clearMessages() {
    this.messages = [];
  }

  /**
   * Получить объект пользователя по его uid
   *
   * @param {String} uid пользователя
   * @return {User}
   */
  getUser(uid) {
    return this.rootStore.userStore.get(uid);
  }

  /**
   * Здасть uid соединения
   * 
   * @param {String} uid uid соединения
   */
  @action
  setConnId(uid) {
    this.connId = uid;
  }

  /**
   * Деструктор хранилища
   */
  destroy() {
    if (this.api) {
      this.api.destroy();
    }
    this.setConnId(undefined);
    this.clearMessages();
  }
}

export default WSStore;
