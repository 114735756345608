import { observable, action, computed, runInAction } from "mobx";
import ConfigApi from "../../api/configApi";
import RestrictionsForTool from "../models/RestrictionsForTool";

class ConfigStore {
  @observable pending;
  @observable restrictionsArray = [];
  @observable restrictionsByToolMap = new Map();
  
  constructor(root) {
    this.rootStore = root;
    this.api = new ConfigApi(root);
  }

  @action
  async init() {
    if (!this.pending) {
      this.pending = true;
      await this.getRestrictions();
    }
  }

  @action
  async getRestrictions() {
    this.restrictionsByToolMap.clear();
    try {
      const restrictions = await this.api.getRestrictions();
      runInAction(() => {
        this.restrictionsArray.replace(restrictions);
      });
      restrictions.forEach((line) => {
        this.setRestriction(line);
      });
    } catch (error) {
      console.warn(error);
    }
  }

  @action
  setRestriction(data) {
    const [
      tool,
      objectCriterion,
      objectCriterionValue,
      role,
      subjectCriterion,
      subjectCriterionValue
    ] = data;
    let availableRestrictions = this.restrictionsByToolMap.get(`${tool}`);
    if (!availableRestrictions) {
      availableRestrictions = new RestrictionsForTool(tool, this);
      this.restrictionsByToolMap.set(
        availableRestrictions.tool,
        availableRestrictions
      );
    }
    availableRestrictions.addItem({
      tool,
      objectCriterion,
      objectCriterionValue,
      role,
      subjectCriterion,
      subjectCriterionValue
    });
  }

  getToolConfig(id) {
    const commonRestrictions = this.restrictionsByToolMap.get("*");
    const restrictions = this.restrictionsByToolMap.get(id);
    const typeCreate = {};
    let kindCreate = [];
    const linkConfig = {};
    if (commonRestrictions) {
      Object.entries(commonRestrictions.config.typeCreate).forEach(
        ([key, item]) => {
          typeCreate[key] = item;
        }
      );
      kindCreate = commonRestrictions.config.kindCreate;
      Object.entries(commonRestrictions.config.linkConfig).forEach(
        ([key, item]) => {
          linkConfig[key] = item;
        }
      );
    }
    if (restrictions) {
      Object.entries(restrictions.config.typeCreate).forEach(([key, item]) => {
        typeCreate[key] = item;
      });
      kindCreate = restrictions.config.kindCreate;
      Object.entries(restrictions.config.linkConfig).forEach(([key, item]) => {
        linkConfig[key] = item;
      });
    }
    return {
      typeCreate,
      kindCreate,
      linkConfig
    };
  }

  @action
  kindsAllows(kindUid) {
    const allows = {
      allowedKinds: [],
      allowedTypes: [],
      allowedTasks: []
    };
    this.restrictionsArray.forEach(
      ([tool, criterion, kind, role, subjectCriterion, value]) => {
        if (
          tool &&
          criterion === "kind" &&
          role === "contains" &&
          kind === kindUid
        ) {
          if (subjectCriterion === "kind") {
            allows.allowedKinds.push(value);
          } else if (subjectCriterion === "class") {
            allows.allowedTypes.push(value);
          } 
        }

        if (
          tool &&
          criterion === "kind" &&
          role === "tasks" &&
          kind === kindUid &&
          subjectCriterion === "kind"
        ) {
          allows.allowedTasks.push(value);
        }
      }
    );
    return allows;
  }

  @action
  classAllows(className) {
    const allows = {
      allowedKinds: [],
      allowedTypes: [],
      allowedTasks: []
    };
    this.restrictionsArray.forEach(
      ([tool, criterion, kind, role, subjectCriterion, value]) => {
        if (
          tool &&
          criterion === "class" &&
          role === "contains" &&
          kind === className
        ) {
          if (subjectCriterion === "kind") {
            allows.allowedKinds.push(value);
          } else if (subjectCriterion === "class") {
            allows.allowedTypes.push(value);
          } 
        }
      }
    );
    return allows;
  }
  
  @computed
  get schemaArray() {
    const availableSchemas = [];
    this.restrictionsByToolMap.forEach(({ name, uid, icon }) => {
      availableSchemas.push({
        name,
        icon,
        uid
      });
    });
    return availableSchemas;
  }

  @computed
  get kindByClassCommon() {
    const common =
      (this.restrictionsByToolMap.get("*") &&
        this.restrictionsByToolMap.get("*").kindByClass) ||
      {};
    return common;
  }
}

export default ConfigStore;
