import { action, computed, observable } from "mobx";

import LibraryApi from "../api/LibraryApi";
import { DOMAIN_LIBRARY } from "~/core/constants/Domains";
import TreeNode from "../models/TreeNode";

const TOOL_ID = "library.Library";

class LibraryStore {
  @observable
  rootStore = null;
  @observable
  pending = false;
  @observable
  pendingRepos = false;
  @observable
  gotError = false;
  @observable
  noRepresentation = true;
  @observable
  nodes = new Map();
  @observable
  repos = new Map();
  @observable
  root = null;
  @observable
  pendingDndMove = null;
  @observable 
  importSchemes = [];
  @observable
  importFileIds = new Map();


  constructor(root) {
    this.rootStore = root;
    this.api = new LibraryApi(this.rootStore);
    this.getRepos();
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPendingRepos(pending = false) {
    this.pendingRepos = pending;
  }

  @action
  setRoot(root = null) {
    this.root = root;
  }

  @action
  setGotError(gotError = false) {
    this.gotError = gotError;
  }

  @action
  setNoRepresentation(noRep = false) {
    this.noRepresentation = noRep;
  }

  @action
  async getRepos() {
    this.setPendingRepos(true);
    const repoData = await this.api.getRepos();
    this.processRepos(repoData);
    this.setPendingRepos(false);
  }

  @action
  processRepos(repoData) {
    this.repos.clear();
    repoData.forEach((repo) => {
      this.repos.set(`${repo.id}`, repo);
    });
  }

  @action
  getVersion(uid) {
    return this.rootStore.objectStore.getVersion(uid, DOMAIN_LIBRARY);
  }

  /**
   * Добавить новый репозитрий
   * 
   * @param {NodeItem} target нода в Библиотеке
   * @param {Object} params набор параметров для добавления репозитория 
   * @returns {Object}
   */
  @action
  async addRepo(target, { name, url, type, repo, username = "", password = "" }) {
    let result = null;
    if (!target) {
      return result;
    }
    target.setPending(true);
    try {
      let repoId = repo;
      if (!repoId) {
        const repoData = await this.api.addRepoToList({
          name,
          url,
          username,
          password,
          type
        });
        if (repoData) {
          repoId = `${repoData.id}`;
        }
      }
    
      if (target && name && repoId) {
        result = await this.api.addRepoMaterial({
          targetUid:  target.uid,
          repository: `${repoId}`,
          name
        });

        if (result) {
          const node = await this.rootStore.objectStore.processLibraryItem(result, DOMAIN_LIBRARY, {});
          if (node) {
            target.addChild(node.uid, -1);
            node.setParentUid(target.uid);
          }
        }
      }
    } catch (ex) {
      result = null;
      this.rootStore.uiStore.setErrorText(ex.message);
    } finally {
      target.setPending(false);
    }
    return result;
  }

  @action
  getNode(uid) {
    let nodeItem = this.nodes.get(uid);
    if (!nodeItem) {
      nodeItem = this.addNode(uid);
    }
    return nodeItem;
  }

  @action
  addNode(uid) {
    const nodeItem = new TreeNode(uid, this);
    this.nodes.set(nodeItem.uid, nodeItem);
    return nodeItem;
  }

  /**
   * Загрузить список доступных схем для импорта файлов
   */
  @action
  async loadImportSchemes() {
    try {
      this.importSchemes = [];
      const data = await this.api.loadImportSchemes();
      this.importSchemes = this.importSchemes.concat(data);
    } catch (ex) {
      this.rootStore.uiStore.setErrorText(ex.message);
    }
  }

  @action
  async uploadFile(uid, position, files) {
    const result = await this.api.loadFile(
      uid,
      position,
      files
    );
    if (result && result.length) {
      const items = [];
      result.forEach((nodeData) => {
        const item = this.rootStore.objectStore.processLibraryItem(nodeData, DOMAIN_LIBRARY, {});
        this.getNode(item.uid);
        items.push(item);
      });
      return await Promise.all(items);
    }
  }

  @action
  async downloadFile(id) {
    return await this.api.getFile(id);
  }

  /**
   * Импорт файла
   * 
   * @param {String} uid uid коллекции ноды, в которую добавляется импортируемый файл
   * @param {Number} position номер позиции в коллекции, куда нужно будет потом доабавить ноду с файлом
   * @param {Array<Files>} files набор файлов для импорта
   * @param {Object} importScheme описание схемы импорта
   * @param {String} importScheme.parser_key название-ключ парсера
   * @param {String} importScheme.uid uid схемы для парсера
   * @param {String} importScheme.name название схемы для парсера
   */
  @action
  async importFile(uid, position, files, importScheme) {
    const result = await this.api.import(
      uid,
      files,
      importScheme
    );
    if (result && result.length) {
      this.importFileIds.clear();
      const items = [];
      result.forEach((nodeData) => {
        this.importFileIds.set(nodeData.node.uid, nodeData.source_files); // id исходных файлов из storage.files
        const item = this.rootStore.objectStore.processLibraryItem(nodeData.node, DOMAIN_LIBRARY, {});
        this.getNode(item.uid);
        items.push(item);
      });
      return await Promise.all(items);
    }
  }

  @action
  async getNodeData(uid, force = false) {
    if (!this.root) {
      this.setPending(true);
    }
    this.setGotError(false);
    this.setNoRepresentation(false);
    try {
      const result = await this.rootStore.objectStore.fetchRepresentation(uid, DOMAIN_LIBRARY
        , 0, {
          tool:   TOOL_ID,
          rootID: (this.root && this.root.uid) || uid
        }, { force: force || !this.root });
      if (!this.root || result.uid === (this.root && this.root.uid)) {
        this.setRoot(this.getNode(result.uid));
      }
      return result;
    } catch (error) {
      if (error && error.code === 404) {
        this.setNoRepresentation(true);
      } else {
        this.setGotError(true);
        this.rootStore.uiStore.setErrorText(error.message);
      }
    } finally {
      this.setPending(false);
    }

    return null;
  }

  @action
  export(uid, data) {
    const treeNode = this.getNode(uid);
    treeNode && treeNode.export(data);
  }

  /** 
   * Поиск записей Библиотеки по набраному тексту
   * 
   * @param {String} text набранный текст
   */
  async searchNodes(text) {
    this.setPending(true);
    try {
      const data = await this.api.search({ name: text });
      const res = [];
      if (Array.isArray(data)) {
        await Promise.all(data.map(async(itemData) => {
          const node = await this.rootStore.objectStore.processLibraryItem(itemData, DOMAIN_LIBRARY, {
            tool:   TOOL_ID,
            rootID: (this.root && this.root.uid)
          }, { force: false });
          res.push(node);
        }));
      }

      return res;
    } catch (ex) {
      this.rootStore.uiStore.setErrorText(ex.message);
    } finally {
      this.setPending(false);
    }
  }

  @action
  setPendingMoveData(data) {
    this.pendingDndMove = data;
  }

  @action
  addFileToVersion(uid, fileId, versionNumber = 1) {
    this.api.importForm(uid, versionNumber, { class: "files.File", id: fileId });
  }

  @computed
  get pendingNode() {
    return this.pendingDndMove ? this.pendingDndMove.node : null;
  }

  @computed
  get pendingTarget() {
    return this.pendingDndMove ? this.pendingDndMove.target : null;
  }


  @computed
  get isPending() {
    return this.pending;
  }
  @computed
  get repoArray() {
    const repos = [];
    this.repos.forEach((repo) => {
      repos.push({
        label: repo.name,
        value: `${repo.id}`
      });
    });
    return repos;
  }

  @computed
  get isPendingRepos() {
    return this.pendingRepos;
  }

  @computed
  get toolId() {
    return TOOL_ID;
  }
}

export default LibraryStore;
