import React, { useCallback, useMemo } from "react";
// import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Dnd } from "@ais3p/ui-framework";
import AisObject from "~/core/components/AisObject";

import {
  ENTITY_VALUE_NUM,
  ENTITY_VALUE_TITLE,
  ENTITY_VALUE_VERSION
} from "../../constants/values";
import SearchStore from "../../stores/searchStore";
import EntityModel from "../../models/EntityModel";
import { 
  DND_EDITORITEM_TYPE, 
  DND_ISSUE_TYPE, 
  DND_NODE_TYPE, 
  DND_SOURCECODE_TYPE 
} from "../../../../core/constants/DnD";
import { DOMAIN_ISSUE, DOMAIN_LIBRARY, DOMAIN_REPO, DOMAIN_TEXT } from "../../../../core/constants/Domains";


/**
 * @component
 * 
 * Компонент для отображения значения в ячейке таблицы
 * 
 * @param {SearchItemModel} entity запись найденнего артефакта
 * @param {String} columnId id колонки - ключ для отображения значения
 * @param {SearchStore} store хранилище для поиска артефатов 
 * @param {Number} index номер записи в табличичном представлении
 * @param {String} keyValues ключ, за которым хранится набор значений
 */
const TableValue = observer(({ entity, columnId, store, index, keyValues, kindUid }) => {
  const dndType = useMemo(() => {
    if (!entity.object) {
      return "none";
    }
    switch (entity.object.domain) {
      case DOMAIN_LIBRARY:
        return DND_NODE_TYPE;
      case DOMAIN_TEXT:
        return DND_EDITORITEM_TYPE;
      case DOMAIN_REPO:
        return DND_SOURCECODE_TYPE;
      case DOMAIN_ISSUE:
        return DND_ISSUE_TYPE;
      default:
        return "none";
    }
  }, [entity.object]);

  const canDrag = useCallback(() => {
    if (!entity.object) {
      return false;
    }
    if (dndType === "none") {
      return false;
    }
    return true;
  }, [entity.object, dndType]);

  if (keyValues) {
    const member = entity.getMember(kindUid);
    if (member) {
      return (
        <span className="entity-table-cell-text">
          {Array.isArray(member[keyValues][columnId]) ? 
            member[keyValues][columnId].join(", ") : 
            member[keyValues][columnId]}
        </span>
      );  
    };
  }

  switch (columnId) {
    case ENTITY_VALUE_NUM:
      return (
        <div className="entity-table-cell-num">
          {`${(store.currentPage - 1) * store.pageSize + index + 1}`}
        </div>
      );
    case ENTITY_VALUE_TITLE:
      return (
        <span className="entity-table-cell-text">
          <Dnd.Source
            canDrag={canDrag}
            type={dndType}
            item={entity.object}
          >
            <AisObject object={entity.object} />
          </Dnd.Source>
        </span>
      );
    case ENTITY_VALUE_VERSION:
      return <span className="entity-table-cell-text">{entity[columnId]}</span>;
    default:
      return <span className="entity-table-cell-text">{entity[columnId]}</span>;
  }
});

TableValue.propTypes = {
  columnId:  PropTypes.string.isRequired, 
  index:     PropTypes.number.isRequired,
  store:     PropTypes.instanceOf(SearchStore),
  entity:    PropTypes.instanceOf(EntityModel),
  keyValues: PropTypes.string
};


export default TableValue;
