import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Components, Field } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import AttrsForm from "./AttrsForm";
import KindItem from "../models/KindItem";


/**
 * @component
 * 
 * Форма привязки вида
 * 
 * @param {KindItem} kindItem активная сущность АИС
 * @param {Function} onChange callback функция на изменения в контролах формы
 */
const AddKind = observer(({ kindItem, onClose, allowedKinds = null }) => {
  const { kindsStore } = useStores();

  const [kindId, setKindId] = useState(null);
  // const [itemUid, setItemUid] = useState(null);

  const canSave = useMemo(() => {
    if (!kindId) {
      return false;
    }
    return kindItem && kindItem.canSave;
  }, [kindId, kindItem && kindItem.canSave]);

  useEffect(() => {
    // setItemUid(null);
    setKindId(null);

    return () => {
      kindItem && kindItem.revert();
    };
  }, [kindItem]);

  const onCloseForm = useCallback(() => {
    kindItem.revert();
    onClose && onClose();
  }, [kindItem, onClose]);

  const onSave = useCallback(() => {
    if (kindItem) {
      kindItem.save();

      onClose && onClose();
    }
    // setItemUid(null);
    setKindId(null);
  }, [kindItem, onClose]);

  const onSelectKindChange = useCallback(
    (value) => {
      setKindId(null);
      if (kindItem) {
        kindItem.revert();
      }
      if (value) {
        kindItem.addKind(value);
        setKindId(value);
      }
    },
    [kindItem]
  );

  const kindsForSelect = useMemo(() => {
    if (kindItem) {
      if (!allowedKinds) {
        return kindItem.kindsToAddForSelect;
      } else {
        const array = [];
        const allowedKindsSet = new Set(allowedKinds);
        kindItem.kindsToAddForSelect.forEach((kind) => {
          if (allowedKindsSet.has(kind.value)) {
            array.push(kind);
          }
        });
        return array;
      }
    }
    return [];
  }, [kindsStore, kindItem, allowedKinds]);

  return (
    <div 
      className="new-item form-expanded"
    >
      <div className="k-and-a-controls top">
        <Components.Button
          icon={"close-M"}
          tooltip={"Закрыть"}
          color="negative"
          onPress={onCloseForm}
        />
      </div>
      <div className="add-kind-form">
        <Field.SingleSelect
          label="Вид"
          icon="data-enum-single-M"
          name="itemKinds"
          theme="kindsAndAttrs"
          placeholder="Выберите вид"
          value={kindId}
          options={kindsForSelect}
          onChange={onSelectKindChange}
        />
        {/* kindId && <div className="divider"></div>*/}
        {kindId && (
          <AttrsForm
            kindItem={kindItem}
            // objectUid={item && item.objectId}
            // version={item && item.version}
            kindUid={kindId}
          />
        )}
      </div>
      <div className="k-and-a-controls bottom">
        <Components.Button
          icon="save-M"
          text={"Сохранить"}
          color="positive"
          isDisabled={!canSave}
          onPress={onSave}
        />
      </div>
    </div>
  );
});

AddKind.propTypes = {
  kindItem: PropTypes.instanceOf(KindItem).isRequired,
  onClose:  PropTypes.func
};

export default AddKind;
