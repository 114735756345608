import { observable, computed, action } from "mobx";


class TrackingItemModel {
  @observable
  wsStore = null;
  @observable
  id = null;
  @observable
  objectMap = new Map();

  constructor(data, wsStore) {
    this.wsStore = wsStore;
    this.init(data);
  }

  @action
  init(data = {}) {
    if (data.itemId) {
      this.id = data.itemId;
    }
    this.add(data);
  }

  @action
  add(data = {}) {
    this.objectMap.set(`${data.domain}-${data.uid}-${data.version}`, data);
  }

  @action
  remove(data = {}) {
    if (!data.domain || !data.uid) {
      this.objectMap.clear();
    } else {
      this.objectMap.delete(`${data.domain}-${data.uid}-${data.version}`);
    }
  }

  @computed
  get output() {
    const array = [];
    this.objectMap.forEach((data) => {
      array.push(data);
    });
    return array;
  }
}

export default TrackingItemModel;
