import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";

import AddRelationItem from "./AddRelationItem";
import { Components, Field } from "@ais3p/ui-framework";

/**
 * Форма для создания связи
 * 
 * @param {Object} targetInfo активный элемент в АИС
 * @param {RelationUIStore} relationUIStore хранилище для работы 
 */
const AddRelation = observer(({ targetInfo, relationUIStore }) => {
  const [kind, setKind] = useState();
  const [isFormVisible, setIsFormVisible] = useState(false);
    
  const onToggleForm = useCallback(() => {
    setIsFormVisible((state) => {
      return !state;
    });
  }, [isFormVisible]);

  const onKindChange = useCallback((kind) => {
    setKind(kind);
  }, []);

  const kindOptions = useMemo(() => {
    return relationUIStore.relationKinds.map((kind) => {
      return {
        label: kind.name,
        value: kind
      };
    });
  }, [relationUIStore.relationKinds]);

  const onAddRelation = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  if (relationUIStore.relationKinds.length === 0) {
    return null;
  }

  return (
    <div className={`new-item ${isFormVisible ? "form-expanded" : ""}`}>
      <div className="button-holder">
        <Components.Button
          text={isFormVisible ? "" : "Добавить связь"}
          icon={isFormVisible ? "close-M" : "plus-M"}
          onPress={onToggleForm}
        />
      </div>
      {isFormVisible && (
        <div className="form-holder">
          <div className="relation-type-holder">
            <Field.SingleSelect
              label="Тип связи"
              icon="link-M"
              name="linkKind"
              theme="relation"
              placeholder="Выберите тип связи"
              value={kind}
              options={kindOptions}
              onChange={onKindChange}
            />
          </div>
          <AddRelationItem
            kind={kind}
            targetInfo={targetInfo}
            onAdd={onAddRelation}
          />
        </div>
      )}
    </div>
  );
}
);

export default AddRelation;
