import React, { useCallback, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import File from "../components/File";

import "./css/index.scss";

const SCALE_STEP = 0.25;

/**
 * Инструмент для просмотра файлов: изображение, pdf
 *
 * @param {String} type тип файла
 * @param {String} name название файла
 * @param {String} icon название файла
 * @param {String} file id файла в файловом хранилище
 * @param {String} fileBlob url локального Blob файла, который уже был загружен.
 * Те это файл, который уже был загружен клиентом и его не нужно брать из файлового хранилища АИС
 */
const ViewerTool = observer((props) => {
  const { type, name, icon } = props;
  const [file, setFile] = useState(props.file);
  const [imgScale, setImgScale] = useState(1);
  const [loaded, setLoaded] = useState(props.fileBlob);
  const fileRef = useRef();

  const onRefresh = useCallback(() => {
    setFile(undefined);
    setLoaded(undefined);
    setFile(props.file);
    setLoaded(props.fileBlob);
  }, []);

  const onLoad = useCallback((file) => {
    setLoaded(file);
  }, []);

  const onPlusImageSize = useCallback(() => {
    const img =  fileRef && fileRef.current; 
    
    if (img) {
      setImgScale((state) => {
        const scale = state + SCALE_STEP;
        img.style.transform = `scale(${scale})`;
        return scale;
      });
    }
  }, [fileRef && fileRef.current]);

  const onMinusImageSize = useCallback(() => {
    const img =  fileRef && fileRef.current;
    if (img) {
      setImgScale((state) => {
        if (state <= SCALE_STEP) {
          return;
        }
        const scale = state - SCALE_STEP;
        img.style.transform = `scale(${scale})`;
        return scale;
      });
    }
  }, [fileRef && fileRef.current]);

  const onResetImageSize = useCallback(() => {
    const img =  fileRef && fileRef.current;
    if (img) {
      img.style.transform = "scale(1)";
      setImgScale(1);
    }
  }, [fileRef && fileRef.current]);

  const toolBar = useMemo(() => {
    let rightButtons = [];
    if (type === "image") {
      rightButtons = [
        <Components.Button
          key="plus"
          icon="zoom-in-M"
          tooltip="Увеличить размер изображения"
          isDisabled={imgScale >= SCALE_STEP * 12}
          onPress={onPlusImageSize}
        />,
        <Components.Button
          key="minus"
          icon="zoom-out-M"
          tooltip="Уменьшить размер изображения"
          isDisabled={imgScale <= SCALE_STEP}
          onPress={onMinusImageSize}
        />,
        <Components.Button
          key="reset"
          icon="zoom-reset-M"
          tooltip="Сбросить размер изображения"
          onPress={onResetImageSize}
        />
      ];
    } 

    return (
      <Components.ToolBar right={rightButtons}>
        <Components.Button
          icon="refresh-M"
          tooltip="Обновить"
          onPress={onRefresh}
        />
      </Components.ToolBar >
    ); 
  }, [type, imgScale]);

  return (
    <div className="viewer">
      {toolBar}
      <div className={`viewer-content ${type}`}>
        <File
          ref={fileRef}
          file={file}
          type={type}
          name={name}
          icon={icon}
          onLoad={onLoad}
          loaded={loaded}
        />
      </div>
    </div>
  );
});

ViewerTool.propTypes = {
  type:     PropTypes.string,
  name:     PropTypes.string,
  icon:     PropTypes.string,
  file:     PropTypes.string,
  fileBlob: PropTypes.string
};

export default ViewerTool;
