const isValidDate = function(date) {
  return date instanceof Date && !isNaN(date);
};
const createDateAsUTC = function(date) {
  if (!date) {
    return;
  }
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};

const getTimePeriod = function(startDate, endDate) {
  if (!isValidDate(startDate)) {
    return "";
  }
  const s = 1000;
  const m = 60 * s;
  const h = 60 * m;
  const d = 24 * h;
  const now = createDateAsUTC(endDate || new Date()).getTime();
  const mSec = now - createDateAsUTC(startDate).getTime();
  if (mSec <= 5 * m) return "только что";
  if (mSec <= 15 * m) return "недавно";
  if (mSec <= h) return "менее часа назад";
  if (mSec <= d) {
    const fullHours = ~~(mSec / h);
    if ([1, 21].includes(fullHours)) {
      return `${fullHours} час назад`;
    } else if ([2, 3, 4, 22, 23].includes(fullHours)) {
      return `${fullHours} часа назад`;
    } else {
      return `${fullHours} часов назад`;
    }
  }
  const fullDays = ~~(mSec / d);
  if (fullDays <= 2) return "вчера";
  if (fullDays <= 4) return `${fullDays} дня назад`;
  if (fullDays < 7) return `${fullDays} дней назад`;
  if (fullDays === 7) return "неделю назад";
  return startDate.toISOString().slice(0, 10)
    .split("-")
    .reverse()
    .join(".");
};

export default getTimePeriod;
