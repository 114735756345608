import { computed, action, observable } from "mobx";
import SearchApi from "../api/searchApi";
import { MODULE_NAME_CFG, FILTERS_CFG } from "../constants/config";
import EntityModel from "../models/EntityModel";

import { CONDITION_IN, CONDITION_NOT_IN } from "../constants/condtions";
// import processComposerAisObject from "~/core/utils/aisHelpers";

/**
 * Хранилище для поиска сущностей АИС 
 */
export default class SearchStore {
  /**
   * @type {Map<SearchItemModel>}
   * Набор найденных АИС объектов
   * 
   */
  @observable
  entitiesMap = new Map();

  /**
   * @type {Map<Object>}
   * 
   * Набор фильтров
   * 
   */
  @observable
  filters = [];

  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @type {Boolean}
   */
  @observable
  processing = false;

  /**
   * Флаг, указывающий, что идет инициалиация хранилища - загруза небходимых данных
   *
   * @type {Boolean}
   */
  @observable
  isInitialization = false;

  /**
   * Отображать ли панель фильтров
   *
   * @type {Boolean}
   */
  @observable
  isShownFilters = true;

  /**
   * Кол-во записей на странице в табличном представлением
   *
   * @type {Number}
   */
  @observable
  pageSize = 20;

  /**
   * Номер текущей страницы в табличном представлением
   *
   * @type {Number}
   */
  @observable
  currentPage = 1;

  /**
   * Общее кол-во записей
   *
   * @type {Number}
   */
  @observable
  totalEntities = 0;

  /**
   * Текущий Вид
   * 
   * @type {KindModel}
   */
  @observable
  currentKind = undefined;

  /**
   * Набор данных для отображения пикера выбора условий
   * 
   * @type {Object}
   */
  @observable
  conditionPickerData = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.entitiesMap = new Map();
    this.filters = new Map();

    this.api = new SearchApi(rootStore);

    // this.isShownFilters = this.getItemConfig(FILTERS_CFG).isShownFilters !== false;
    this.isShownFilters = true;
  }

  /**
   * Список найденных сущностей АИС
   *
   * @return {Array<EntityModel>}
   */
  @computed
  get entityList() {
    return Array.from(this.entitiesMap.values());
  }

  /**
   * Добавить сущность АИС в список под ее uid
   *
   * @param {Array<EntityModel>} entity модель найденной сущности АИС
   */
  @action
  addEntity(entity) {
    this.entitiesMap.set(entity.uid, entity);
    // this.rootStore.objectStore.addVersion(issue);
  }

  /**
   * Изменение значения флага, указывающий, что идет обработка/загрузка данных
   *
   * @param {Boolean} value значение
   */
  @action
  setIsProcessing(value) {
    this.processing = value;
  }

  /**
   * Изменение значения флага, указывающий, что идет инициализация хранилища
   *
   * @param {Boolean} value значение
   */
  @action
  setIsInitialization(value) {
    this.isInitialization = value;
  } 

  @action
  setFilters(filters) {
    this.filters = filters;
  }

  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @return {Boolean} 
   */
  @computed
  get isProcessing() {
    return this.processing  || this.isInitialization;
  }

  /**
   * Переключить отображение панели с фильтрами
   */
  @action
  toggleShowFilters() {
    this.isShownFilters = !this.isShownFilters;
    const cfg = this.getItemConfig(FILTERS_CFG);
    this.setItemConfig(FILTERS_CFG, {
      ...cfg,
      isShownFilters: this.isShownFilters
    });
  }

  /**
   * Скрыть отображение панели с фильтрами
   */
  @action
  hideFilters() {
    this.isShownFilters = false;
    const cfg = this.getItemConfig(FILTERS_CFG);
    this.setItemConfig(FILTERS_CFG, {
      ...cfg,
      isShownFilters: false
    });
  }

  /**
   * Задать кол-во записей на странице в табличном представлении
   *
   * @param {Number} size кол-во записей
   */
  @action
  setPageSize(size) {
    this.pageSize = size || 20;
    this.setCurrentPage(1);
  }

  /**
   * Задать номер текущей страницы в табличном представлении
   *
   * @param {Number} page номер текущей страницы в табличном представлении
   */
  @action
  setCurrentPage(page) {
    this.currentPage = page || 1;
  }

  /**
   * Задать общее кол-во записей
   *
   * @param {Number} value общее кол-во записей
   */
  @action
  setTotalEntities(value = 0) {
    this.totalEntities = value;
  }

  /**
   * Задать текущий Вид
   *
   * @param {KindModel} kind Вид
   */
  @action 
  setCurrentKind(kind) {
    this.currentKind = kind;
  }

  /**
   * Задать набор данных для отображения пикера выбора условий
   *
   * @param {Object} data набор данных для отображения пикера выбора условий. Если null, то пикер скроется
   */
  setConditionPickerData(data) {
    this.conditionPickerData = data;
  }

  /**
   * Кол-во страниц
   *
   * @return {Number}
   */

  @computed
  get pages() {
    if (this.pageSize && this.totalEntities) {
      return Math.ceil(this.totalEntities / this.pageSize);
    }

    return -1;
  }

  /**
   * Можно ли перейти на предыдущую страницу
   *
   * @return {Boolean}
   */
  @computed
  get canPreviousPage() {
    return this.currentPage > 1;
  }

  /**
   * Можно ли перейти на следуюущую страницу
   *
   * @return {Number}
   */
  @computed
  get canNextPage() {
    return this.currentPage < this.pages;
  }

  /**
   * Получить найденную сущность АИС по ее uid
   *
   * @param {String} uid найденной сущности АИС 
   *
   * @return {EntityModel}
   */
  getEntity(uid) {
    return this.entitiesMap.get(uid);
  }

  /**
   * Метод для инициализации хранилища.
   * Необходимо вывать для загузки словарей данных - видов и ех атрибутов
   */
  async init() {
    this.setIsInitialization(true);
    try {
      // console.log("init");
    } finally {
      this.setIsInitialization(false);
    }
  }

  /**
  * Поиск артефактов АИС по заданным фильтрам
  *
  * @params {Object} codes набор кодов - uid:значение
  * @params {Object} attrubutes набор атрибутов - uid:значение
  * @params {Object} version номер версии
  * @params {String} kindUid uid выбранного Вида 
  */
  async searchEntities(codes = {}, attributes = {}, version = {}, kindUid) {
    this.setCurrentKind(this.rootStore.kindsStore.getKind(kindUid));
    this.setIsProcessing(true);
    try {
      this.clearEntities();
      const filters = [];
      const params = {};
      Object.keys(codes).forEach((key) => {
        const { label, condition, value, type } = codes[key];
        if (value || type === "boolean") {
          // if (conditionEnumList.includes(condition)) {
          if ([CONDITION_IN, CONDITION_NOT_IN].includes(condition)) {
            const val = Array.isArray(value) ? value.map((item) => {
              return item.value;
            }) : [value];
            params[`codes.${key}`] = {
              [condition]: val
            };
          } else if (type === "enum") {
            // был выбран из выпадающего списка
            params[`codes.${key}`] = {
              [condition]: Array.isArray(value) ? value : [value]
            };
          } else {
            params[`codes.${key}`] = {
              [condition]: value
            };
          }
          
          // filters.push(codes[key]);
          filters[label] = value;
        }
      });
      Object.keys(attributes).forEach((key) => {
        const { label, condition, value, type } = attributes[key];
        if (value || type === "boolean") {
          // if (conditionEnumList.includes(condition)) {
          if ([CONDITION_IN, CONDITION_NOT_IN].includes(condition)) {
            const val = Array.isArray(value) ? value.map((item) => {
              return item.value;
            }) : [value];

            params[`values.${key}`] = {
              [condition]: val
            };
          } else if (type === "enum") {
            // был выбран из выпадающего списка
            params[`values.${key}`] = {
              [condition]: Array.isArray(value) ? value : [value]
            };
          } else {
            params[`values.${key}`] = {
              [condition]: value
            };
          }
          filters[label] = value;
        }
      });
      if (version && version.value) {
        // params.version = version;
        params.version = {
          [version.condition]: version.value
        };
        filters["Версия"] = version;
      }

      params.kind = kindUid;
      const res = await this.api.searchEntities(params, this.currentPage, this.pageSize);
      let data = res.Search;
      const totalCount = res.headers && res.headers.get("Total-Count");
      if (res.headers && totalCount) {
        data = res.data.Search;
        this.setTotalEntities(totalCount);
      } else {
        this.setTotalEntities(data.length);
      }

      data.forEach((item) => {
        this.addEntity(EntityModel.create(item, this));
      });
    } catch (e) {
      console.error(e);
      this.onError(e);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return true;
  }
  
  async foundObjects(uids) {
    return  await this.api.foundObjects(uids);
  }

  /**
   * Получить конфигурацию инструмента;
   *
   * @return {Object}
   */
  @computed
  get config() {
    return this.rootStore.uiStore.getModuleConfig(MODULE_NAME_CFG);
  }

  /**
   * Получить параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @return {Object}
   */
  getItemConfig(name) {
    return (this.config && this.config[name]) || {};
  }

  /**
   * Задать параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @param {Object} params набор параметров
   */
  setItemConfig(name, params) {
    this.rootStore.uiStore.setModuleConfig(MODULE_NAME_CFG, {
      ...this.config,
      [name]: params
    });
  }

  /**
   * Функция для вызова сообщения обшибке
   *
   * @param {String} msg текст сообщения об ошибке
   */
  onError(msg) {
    this.rootStore.onError(msg);
  }


  @action
  clearEntities() {
    this.entitiesMap.clear();
  }

  /**
   * Очистеть список найденных АИС объектов
   */
  clear() {
    this.entitiesMap.clear();
    this.filters = [];
  }

  /**
   * Разрушить хранилище
   */
  destroy() {
    this.clear();
    this.api.destroy();
  }
}

