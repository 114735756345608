import { action, computed } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class Indent extends BaseBoxClass {
  @action
  async init(data, parent) {
    super.init(data, parent);
    if (data.isNew) {
      this.creationPromice = this.persistCreate();
      await this.creationPromice;
    }
  }

  @action
  async createFirstChunk() {
    if (!((this.isLocked && !this.isLockedByMe) || (this.isParentLocked && !this.isParentLockedByMe))) {
      await this.creationPromice;
      const chunk = this.createChunk();
      if (chunk) {
        this.addChunk(chunk);
        chunk.setEditing();
      }
    }
  }

  @action
  async setEditing(offset) {
    if (!((this.isLocked && !this.isLockedByMe) || (this.isParentLocked && !this.isParentLockedByMe))) {
      if (!this.idsArray.length) {
        await this.createFirstChunk();
        return null;
      }
      let itemId = this.idsArray[0];
      if (this.store.delta < 0) {
        itemId = this.idsArray[this.idsArray.length - 1];
      }
      const item = this.getItemById(itemId);
      await(item && item.setEditing(offset));
    }
  }

  @action
  createChunk() {
    if (!this.store) {
      return null;
    }
    const newId = this.store.getUid();
    const chunk = this.store.createItem(
      { uid: newId, class: "text.chunk.Varchar", value: "", isNew: true },
      this
    );
    return chunk;
  }

  @action
  addChunk(chunk) {
    this.idsArray.push(chunk.uid);
    chunk.setParent(this);
    return chunk;
  }

  @action
  clear() {
    this.idsArray.forEach((id) => {
      this.deleteItemId(id);
      this.store.deleteItemById(id);
    });
  }

  @action
  async delete(id, stopPropagation) {
    if (this.idsArray.length > 0) {
      this.deleteItemId(id);
      this.store.deleteItemById(id);
    }
    if (this.idsArray.length === 0 && !stopPropagation) {
      await this.persistDelete();
      this.parent.delete(this.uid, stopPropagation);
    }
  }

  @action
  mergeByCondition(delta) {
    if (delta < 0 && !this.isFirst) {
      const mergeId = this.parent.getPrevId(this.uid);
      if (mergeId) {
        const mergeItem = this.getItemById(mergeId);
        this.idsArray.forEach((id) => {
          mergeItem.addItemById(id);
        });
        this.parent.delete(this.uid);
      }
    }
    if (delta > 0 && !this.isLast && !this.isCaption) {
      const mergeId = this.parent.getNextId(this.uid);
      if (mergeId) {
        const mergeItem = this.getItemById(mergeId);
        mergeItem.idsArray.forEach((id) => {
          this.addItemById(id);
        });
        this.parent.delete(mergeId);
      }
    }
  }

  @action
  async splitAtItem(id, pos, preventBubble = false) {
    if (!this.store) {
      return null;
    }
    const splitedItem = this.getItemById(id);
    if (splitedItem && !this.isCaption) {
      // create indent first
      let targetParent = this.parent;
      if (this.isLast && this.parent.isSplitable && !preventBubble) {
        targetParent = await this.parent.splitAfter(this.uid);
      }

      const newItem = targetParent.createAfter(this.uid, {
        uid:   this.store.getUid(),
        class: this.className
      });

      // remove created empty Indent
      newItem.clear();
      // create new chunk as new indents child first
      const newChild = this.store.createItem(
        {
          uid:   this.store.getUid(),
          class: splitedItem.className
        },
        newItem
      );
      // add new chunk to new indents child list
      newItem.addItemId(newChild.uid);

      // get value of indent after caret position
      const value = splitedItem.getValueFromPosition(pos);
      // set value to new chunk
      newChild.setValue(value, true);

      // reduce value of old chunk
      splitedItem.clearValueAfterPosition(pos);
      // move all chunks after splitted one to new parent - new indent
      let foundItem = false;
      this.idsArray.forEach((itemId) => {
        if (foundItem) {
          newItem.addItemById(itemId);
          this.deleteItemId(itemId);
        }
        if (id === itemId) {
          foundItem = true;
        }
      });
      await newItem.persistCreate();
      // set cursor to new chunk
      newChild.setEditing();
    }
  }

  @action
  async splitAtItemWithInlinePicture(id, pos, src) {
    if (!this.store) {
      return null;
    }
    const splitedItem = this.getItemById(id);
    if (splitedItem && !this.isCaption) {
      // create new chunk as inlinePicture
      const inlinePictureItem = this.store.createItem(
        {
          uid:   this.store.getUid(),
          class: "text.chunk.InlinePicture",
          value: src
        }, this
      );
      // get value of old chunk after caret position
      const valueAfterCaret = splitedItem.getValueFromPosition(pos);

      // reduce value of old chunk
      splitedItem.clearValueAfterPosition(pos);

      // create new chunk as old chunk after caret position
      const newTextChunk = this.store.createItem(
        {
          uid:   this.store.getUid(),
          class: splitedItem.className,
          value: valueAfterCaret
        }, this
      );
      await inlinePictureItem.persistCreate();
      await newTextChunk.persistCreate();
      this.insertAfter(inlinePictureItem, id);
      this.insertAfter(newTextChunk, inlinePictureItem.uid);
      newTextChunk.setEditing();
    }
  }
  @action
  async splitAtItemWithInlineFormula(id, pos) {
    if (!this.store) {
      return null;
    }
    const splitedItem = this.getItemById(id);
    if (splitedItem && !this.isCaption) {
      // create new chunk as inlineFormula
      const inlineFormulaItem = this.store.createItem(
        {
          uid:   this.store.getUid(),
          class: "text.chunk.InlineFormula"
        }, this
      );
      // get value of old chunk after caret position
      const valueAfterCaret = splitedItem.getValueFromPosition(pos);

      // reduce value of old chunk
      splitedItem.clearValueAfterPosition(pos);

      // create new chunk as old chunk after caret position
      const newTextChunk = this.store.createItem(
        {
          uid:   this.store.getUid(),
          class: splitedItem.className,
          value: valueAfterCaret ? valueAfterCaret : " "
        }, this
      );
      await inlineFormulaItem.persistCreate();
      await newTextChunk.persistCreate();
      this.insertAfter(inlineFormulaItem, id);
      this.insertAfter(newTextChunk, inlineFormulaItem.uid);
      setTimeout(() => { // для фокуса на math-field сразу после создания
        const formulaElement = document.getElementById(inlineFormulaItem.uid);
        if (formulaElement) {
          const mathField = formulaElement.querySelector("math-field");
          if (mathField) {
            mathField.focus();
          }
        }
      }, 0);
    }
  }
  
  @computed
  get isCaption() {
    return this.uid === (this.parent && this.parent.captionId);
  }

  @computed
  get title() {
    let title = " ";
    this.items.forEach((item) => {
      if (item) {
        title += item.title;
      }
    });
    return title;
  }

  @computed
  get editingChunkIndex() {
    if (!this.store.editingChunkId) {
      return null;
    }

    const index = this.idsArray.findIndex((id) => {
      return id === this.store.editingChunkId;
    });

    return index;
  }

  @computed
  get hasChunks() {
    return !!this.idsArray.length;
  }

  @computed
  get slug() {
    return "indents";
  }

  @computed
  get category() {
    return this.isCaption ? "heading" : "elements";
  }

  @computed
  get output() {
    return [{
      class:  this.className,
      uid:    this.uid,
      chunks: this.items.map((item) => {
        return item.uid;
      }),
      "@position": this.position
    }, ...this.items.map((item) => {
      return item.output;
    })];
  }
}

export default Indent;
