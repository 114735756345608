import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import ItemView from "../components/ItemView";
import AddKind from "../components/AddKind";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";

import "./css/index.scss";

/**
 * @component
 * 
 * Боковая панель для просмотра/редактирования видов и аттрибутов у объектов АИС
 * 
 * @param {Object} trackedItem активный элемент в АИС
 * @param {Boolean} hasTarget 
 */
const KindsSidepanel = observer((props) => {
  const { hasTarget, trackedItem } = props;
  const [isShowAddKindForm, setIsShowAddKindForm] = useState(false);

  const { kindsStore, configStore } = useStores();
  const { isPending, isItemsPending, isPendingData } = kindsStore;

  const kindItem = useMemo(() => {
    if (
      !!trackedItem &&
      hasTarget &&
      trackedItem.uid &&
      !isItemsPending &&
      !isPendingData
    ) {
      return kindsStore.getItem(trackedItem.uid, trackedItem.version);
    }
    return null;
  }, [
    trackedItem && trackedItem.uid,
    trackedItem && trackedItem.version,
    isItemsPending,
    isPendingData,
    trackedItem,
    hasTarget
  ]);

  useEffect(() => {
    setIsShowAddKindForm(false);
  }, [trackedItem]);

  const isThisPending = useMemo(() => {
    return (
      isPending ||
      (kindItem && kindItem.isPending) ||
      isItemsPending ||
      isPendingData
    );
  }, [
    isPending,
    kindItem && kindItem.isPending,
    isItemsPending,
    isPendingData
  ]);

  const onAddKind = useCallback(() => {
    setIsShowAddKindForm(true);
  }, [kindItem]);

  const onCloseAddKind = useCallback(() => {
    setIsShowAddKindForm(false);
  }, []);

  const allowedKinds = useMemo(() => {
    const additionalItems = configStore.getToolConfig();
    const kindLimits = additionalItems.kindCreate;
    const kindUids = [];
    if (!trackedItem) {
      return kindUids;
    }
    kindLimits.forEach((item) => {
      if (item.as === trackedItem.class) {
        kindUids.push(item.kind);
      }
    });
    return kindUids;
  }, [configStore, trackedItem, kindsStore]);

  return (
    <div className="k-and-a-sidepanel">
      <Target trackedItem={trackedItem} info={infoToolContent.kinds} />
      <div className="k-and-a-content">
        {!isThisPending && kindItem && !isShowAddKindForm && !!allowedKinds.length && 
          (trackedItem && !trackedItem.version) &&
            <div className="k-and-a-controls">
              <Components.Button
                icon="plus-M"
                color="action"
                text="Добавить вид"
                onPress={onAddKind}
              />
            </div>
        }
        {isShowAddKindForm && kindItem && (trackedItem && !trackedItem.version) && (
          <AddKind kindItem={kindItem} allowedKinds={allowedKinds} onClose={onCloseAddKind} />
        )}
        {isThisPending && (
          <div className="preload-wrapper">
            <Components.Preloader size={3} />
          </div>
        )}
        {!isThisPending && 
          <ItemView  
            kindItem={kindItem} 
            objectUid={trackedItem && trackedItem.uid}
            version={trackedItem && trackedItem.version}
          />
        }
      </div>
    </div>
  );
});

KindsSidepanel.propTypes = {
  trackedItem: PropTypes.object,   
  hasTarget:   PropTypes.bool
};


export default KindsSidepanel;
