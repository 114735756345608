import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { iconRender, getTimePeriod } from "~/core/utils";
import useStores from "../utils/useStores";
import { Components } from "@ais3p/ui-framework";

const AisIcon = observer((props) => {
  const { item, className, icon, hideLock, loading, onClick } = props;

  const { objectStore, userStore } = useStores();

  const aggr = useMemo(() => {
    if (item) {
      let domain = item.domain || item.tool;
      if (!domain && item.class) {
        domain = item.class.split(".")[0];
      }
      return objectStore.getVersion(item.uid, domain, item.version);
    }
    return item;
  }, [item, objectStore]);

  const iconString = useMemo(() => {
    if (icon) {
      return icon;
    }

    if (aggr && aggr.iconString) {
      return aggr.iconString;
    }
    return iconRender(aggr || item, true);
  }, [iconRender, aggr, aggr && aggr.hasKinds, aggr && aggr.iconString, item, item && item.hasKinds, icon]);

  if (item && item.isLoading) {
    return <Components.Preloader size={1} style={{ margin: "0.25rem" }} color="#01abfb" />;
  }

  if (aggr && aggr.isLocked && !hideLock && !!aggr.lockData) {
    const { createdAt:lockedAt, subject:lockedBy } = aggr.lockData;
    const lockTime = getTimePeriod(new Date(lockedAt));
    const user = userStore.get(lockedBy);
    const lockedByUser = (user && user.shortName) || "Нет данных";
    const lockInfoText = `Заблокировал ${lockTime} ${lockedByUser}`;
    return (
      <div
        {...{
          "data-tooltip":    props["data-tooltip"],
          "data-tooltip-at": props["data-tooltip-at"]
        }}
        onClick={onClick}
        className="merge-icons"
      >
        <Components.Icon
          className={className || "expander big"}
          name={iconString}
        />
        <div className="lock-icon" data-tooltip={lockInfoText}>
          <Components.Icon className={`lock ${aggr.isLockedByMe && "my-lock"}`} name="fixed-M" />
        </div>
      </div>
    );
  }

  if (aggr && aggr.isParentLocked && !hideLock) {
    return (
      <div
        {...{
          "data-tooltip":    props["data-tooltip"],
          "data-tooltip-at": props["data-tooltip-at"]
        }}
        onClick={onClick}
        className="merge-icons"
      >
        <Components.Icon
          className={className || "expander big"}
          name={iconString}
        />
        <div className="lock-icon" data-tooltip={aggr.isParentLockedByMe ? "Редактирование невозможно для других пользователей" : "Редактирование невозможно, заблокирован родительский элемент"}>
          <Components.Icon className={`lock ${aggr.isParentLockedByMe && "my-lock"}`} name="warning-M" />
        </div>
      </div>
    );
  }

  if (aggr && aggr.isChildLocked && !hideLock) {
    return (
      <div
        onClick={onClick}
        className="merge-icons"
      >
        <Components.Icon
          className={className || "expander big"}
          name={iconString}
        />
        <div className="lock-icon" data-tooltip={"Перемещение невозможно, заблокирован дочерний элемент"}>
          <Components.Icon className="lock" name="restrictions-M" />
        </div>
      </div>
    );
  }

  if (aggr && aggr.isChildLockedByMe && !hideLock) {
    return (
      <div
        onClick={onClick}
        className="merge-icons"
      >
        <Components.Icon
          className={className || "expander big"}
          name={iconString}
        />
        <div className="lock-icon" data-tooltip={"Перемещение невозможно для других пользователей"}>
          <Components.Icon className="lock my-lock" name="restrictions-M" />
        </div>
      </div>
    );
  }

  if (aggr && aggr.isValidatable) {
    // processing-M
    return (
      <div
        {...{
          "data-tooltip": aggr.isProcessingValidation ? "На согласовании" : (aggr.isValidated === "accepted" ? "Согласовано" : "Отклонено"), // eslint-disable-line
          "data-tooltip-at": props["data-tooltip-at"]
        }}
        onClick={onClick}
        className="merge-icons"
      >
        <Components.Icon
          className={`${className || "expander big"} ${
            aggr.isProcessingValidation ? "in-progress" : aggr.isValidated
          }`}
          name={iconString}
        />
        <div className="validate-icon" data-tooltip={props["data-tooltip"]}>
          <Components.Icon
            {...{
              "data-tooltip": aggr.isProcessingValidation ? "На согласовании" : (aggr.isValidated === "accepted" ? "Согласовано" : "Отклонено"), // eslint-disable-line
              "data-tooltip-at": props["data-tooltip-at"]
            }}
            className={`validate ${
              aggr.isProcessingValidation ? "in-progress" : aggr.isValidated
            }`}
            name={aggr.isProcessingValidation ? "processing-M" : (aggr.isValidated === "accepted" ? "ok-M" : "close-M")} // eslint-disable-line
          />
        </div>
      </div>
    );
  }

  if (iconString === "list-bullet-S") {
    return (
      <svg
        {...{
          "data-tooltip":    props["data-tooltip"],
          "data-tooltip-at": props["data-tooltip-at"]
        }}
        loading={loading}
        onClick={onClick}
        className={`${className || "expander big"} ${iconString}`}
        icon={iconString}
        fill="currentColor"
        preserveAspectRatio="xMidYMid meet"
        height="1em"
        width="1em"
        viewBox="0 0 96 96"
        name="list-bullet-S"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        style={{ verticalAlign: "middle" }}
      >
        <circle
          cx="48" cy="48" r="18"
          strokeWidth="4"
        />
      </svg>
    );
  }

  return (
    <Components.Icon
      {...{
        "data-tooltip":    props["data-tooltip"],
        "data-tooltip-at": props["data-tooltip-at"]
      }}
      loading={loading}
      onClick={onClick}
      className={className || "expander big"}
      name={iconString}
    />
  );
});

export default AisIcon;
