import { observable, action, computed } from "mobx";

/**
 * @class RelationUIStore
 * 
 * Хранилище для отображения списка связей в UI SidePanel
 */
class RelationUIStore {
  @observable
  id = null;
  @observable
  version = null;
  @observable
  class = null;

  @observable
  pending = false;

  constructor(relationStore) {
    this.relationStore = relationStore;
    this.kindsStore = this.relationStore.rootStore.kindsStore;
  }

  @action
  async init(id, version = 0, klass) {
    this.id = id;
    this.version = version;
    this.class = klass;
    this.setPending(true);
    try {
      // await this.relationStore.getRelations(id, version);
      await this.relationStore.getRelationFromComposer(id, version, klass);
    } finally {
      this.setPending(false);
    }
  }

  @action
  setPending(pending = false) {
    this.pending = pending;  
  }

  @computed
  get relationKinds() {
    return this.relationStore.relationKinds;
  }

  @computed
  get kindsForSelect() {
    return this.relationStore.kindsForSelect;
  }

  @computed
  get groupedConnections() {
    const relations = [];
    if (this.isPending || this.relationStore.isPending) {
      return [];
    }
    const tempObj = {};
    const data = this.relationStore.getGroupedConnections(this.id, this.version);
    data.forEach(({ kind, items }) => {
      if (kind) {
        if (tempObj[kind.id]) {
          tempObj[kind.id].items = tempObj[kind.id].items.concat([...items]);
        } else {
          tempObj[kind.id] = { kind, items: [...items] };
        }
      }
    });
    for (const key in tempObj) {
      if (Object.hasOwnProperty.call(tempObj, key)) {
        relations.push(tempObj[key]);
      }
    }
    return relations;
  }

  @computed
  get relationsByKindArray() {
    return this.groupedConnections;
  }

  @computed
  get relationsByKindArrayLength() {
    return this.relationsByKindArray.length;
  }

  @computed
  get isPending() {
    return this.relationStore.pending;
  }

  @computed
  get hasConnections() {
    return this.groupedConnections.length;
  }
}

export default RelationUIStore;
