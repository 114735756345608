import { observable, action, computed, toJS } from "mobx";
import { DOMAIN_LIBRARY } from "../../../core/constants/Domains";
import AisVersion from "../../../core/data/models/AisVersion";

class NodeItem extends AisVersion {
  @observable uid = null;
  @observable domain = "library";
  @observable tool = "library";
  @observable childIds = null;
  @observable etype = null;
  @observable name = null;
  @observable parentUid = null;
  @observable path = [];
  @observable editing = false;
  @observable forms = null;
  @observable number = 0;
  @observable size = 0;
  @observable permissions = new Map();
  @observable expanded = false;
  @observable pending = false;
  @observable deleting = false;

  constructor(props) {
    const etype = props.etype || props.class;
    super({ ...props, etype }, props.objectStore);

    this.etype = etype;
    this.update(props);
  }

  @action
  setExpanded(expanded = false) {
    this.expanded = expanded;
  }

  @action
  setEditing(editing = false) {
    this.editing = editing;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  rename(name) {
    if (name) {
      this.name = name;
    }
    this.setEditing(false);
  }

  @action
  removeFromParent() {
    this.parent && this.parent.removeChildByUid(this.uid);
  }

  @action
  revert() {
    this.setEditing(false);
  }

  @action
  setParentUid(uid = null) {
    if (uid) {
      this.parentUid = uid;
    }
  }

  @action
  setDeleting(deleting = false) {
    this.deleting = deleting;
  }

  @action
  confirmDelete() {
    this.setDeleting(true);
  }

  /**
   * Очистить список вложенных детей
   */
  @action
  clearChilds() {
    this.childIds = null;
  }
  
  @action
  removeChildByIndex(position = null) {
    if (position !== null) {
      const newIds = this.childIds.slice();
      newIds.splice(position, 1);
      this.childIds = newIds;
    }
  }

  @action
  removeChildByUid(uid = null) {
    if (!!uid) {
      this.childIds.remove(uid);
    }
  }

  @action
  insertChildIds(items, position = 0) {
    const ids = items.map((item) => {
      item.setParentUid(this.uid);
      return item.uid;
    });
    const newIds = (this.childIds && this.childIds.slice()) || [];
    newIds.splice(position, 0, ...ids);

    this.childIds = newIds;
  }

  @action
  addChild(childUid = null, position = 0) {
    if (childUid) {
      const newIds = this.childIds ? this.childIds.slice() : [];
      if (position === -1) {
        newIds.push(childUid);
      } else {
        newIds.splice(position, 0, childUid);
      }
      this.childIds = newIds;
    }
  }

  @action
  moveTo(fromPosition, target, position = 0) {
    this.parent.removeChildByIndex(fromPosition);
    this.setParentUid(target.uid);
    target.addChild(this.uid, position);
  }

  @action
  update({ name, editable, parent, children, forms, path, number, meta, size }) {
    if (name !== undefined) {
      this.name = name;
    }
    if (editable !== undefined) {
      this.editable = editable;
    }
    if (parent !== undefined) {
      this.parentUid = parent;
    }
    if (children !== undefined && children !== null && children.length) {
      this.childIds = children.map((child) => {
        return child.uid;
      });
    }
    if (forms !== undefined) {
      this.forms = forms;
    }
    if (path !== undefined) {
      this.path = path;
    }
    if (number !== undefined) {
      this.number = number;
    }
    if (forms !== undefined) {
      this.forms = forms;
    }
    if (size !== undefined) {
      this.size = size;
    }
    if (meta && meta.permissions) {
      this.permissions.replace(meta.permissions);
    }
  }

  @computed
  get class() {
    return this.etype;
  }

  @computed
  get isVersion() {
    return this.number > 0;
  }

  @computed
  get isEditing() {
    return this.editing;
  }

  @computed
  get isDeleting() {
    return this.deleting;
  }

  @computed
  get isExpanded() {
    return this.expanded || this.isRoot;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get contentType() {
    if (!this.forms || !this.forms.length) {
      return null;
    }
    
    let contentType = null;
    this.forms.forEach((form) => {
      if (form.contentType) {
        contentType = form.contentType.split(";")[0];
      }
    });
    return contentType;
  }

  @computed
  get fileType() {
    const fileType = "download";
    const type = this.contentType.split("/")[0];
    if (this.contentType === "application/pdf" || type === "text") {
      return "iframe";
    }
    if (type === "image") {
      return "image";
    }
    return fileType;
  }

  @computed
  get fileId() {
    if (!this.forms || !this.forms.length) {
      return null;
    }
    let id = null;
    this.forms.forEach((form) => {
      if (form.id) {
        id = form.id;
      }
    });
    return id;
  }

  @computed
  get pathUidSet() {
    return new Set(toJS(this.path));
  }

  @computed
  get permissionsObject() {
    return this.permissions.toJSON();
  }

  @computed
  get title() {
    if (!this.number) {
      return this.name || "";
    }
    return `${this.name || ""} - ${this.parent && this.parent.name}`;
  }

  @computed
  get level() {
    if (!this.parent) {
      return 0;
    }
    return this.parent.level + 1;
  }

  @computed
  get isLeaf() {
    return (this.size < 1 && (!this.childIds || this.childIds.length < 1));
  }

  @computed
  get POJO() {
    return {
      uid:  this.uid,
      path: this.path
    };
  }

  @computed
  get childItems() {
    if (!this.childIds) {
      return [];
    }
    return this.childIds.map(((childUid) => {
      return this.objectStore.getVersion(childUid, DOMAIN_LIBRARY);
    }));
  }

  @computed
  get parent() {
    return (
      this.parentUid &&
      this.objectStore.getVersion(this.parentUid, DOMAIN_LIBRARY)
    );
  }
}

export default NodeItem;
