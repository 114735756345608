import React, { useState, useCallback } from "react";
import classNames from "classnames";
import { Library }  from "../components";

import "./css/index.scss";
/**
 * Библиотека -- приложение для навигации между материалами и их коллекциями.
 */
const LibraryTool = (props) => {
  const [mode, setMode] = useState("library");

  const onToggleMode = useCallback(() => {
    setMode((state) => {
      return state === "library" ? "search" : "library";
    });
  }, []);

  return (
    <div className="library-tool">
      <div className={classNames("library-tool-item", {
        disabled: mode === "search"
      })}
      >
        <Library 
          {...props}
          mode={"library"}
          onToggleMode={onToggleMode}
        />
      </div>
      <div className={classNames("library-tool-item", {
        disabled: mode === "library"
      })}
      >
        <Library 
          {...props}
          mode={"search"}
          onToggleMode={onToggleMode}
        />
      </div>
    </div>
  );
};

export default LibraryTool;
