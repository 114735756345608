import React, { useCallback, useRef, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Dnd, Components, ContextMenu } from "@ais3p/ui-framework";
import { colorShade } from "~/core/utils";
import useStores from "~/core/utils/useStores";
import {
  SHADE_BACKGROUND_COLOR,
  SHADE_BORDER_COLOR
} from "../../constants/colorShade";
import IssueIcon from "./IssueIcon";
import UserAvatar from "~/modules/users/components/avatar";
import { DND_ISSUE_TYPE } from "~/core/constants/DnD";
import { IssueModel } from "../../models";
import IssueStore from "../../stores/IssueStore";
import { CTX_MENU_AIS_OBJECT_LINK } from "~/core/constants/ContextMenu";


/**
 * Панель для отображения краткой информации о задаче в колонке статусов задачи
 * 
 * @param {Object} props набор параметров
 * @param {IssueModel} params.issue модель задачи
 * @param {IssueStore} params.store хранилизе для работы с задачами
 * @param {String} params.cstatusId id статуса задачи, в кологке которой находится наша панель задачи
 * @param {Function} params.changeIssueStatus ф-я, которую необходимо вызвать для изменения статуса у задачи
 * @param {Function} params.moveIssue ф-я, которую необходимо вызвать для перемещения порядка следования задачи 
 * @param {Object} params.layoutItem объект layoutItem
 */
const IssueItem = observer((props) => {
  const { issue, store, statusId, moveIssue, changeIssueStatus, layoutItem } = props;

  let timer = 0;
  const delay = 200;
  let prevent = false;

  const { uiStore } = useStores();

  const { priority, assignedTo, tracker } = issue;
  const { readOnly } = store;
  const trackerName = tracker.name || "СП";
  const [isLoading, setIsLoading] = useState(false);
  const userImage = useMemo(() => {
    return assignedTo && assignedTo.aisUser && assignedTo.aisUser.image;
  }, [assignedTo]);

  const refIssue = useRef(null);

  const canDrag = useCallback(() => {
    return !readOnly;
  }, [readOnly]);

  const canDrop = useCallback(() => {
    return !readOnly;
  }, [readOnly]);

  const onHover = useCallback(
    (prps, monitor) => {
      const { uid: draggedUid, isCard } = monitor.getItem();

      // переносить можем только карточки
      if (!isCard) {
        return;
      }

      if (draggedUid && draggedUid !== issue.uid && moveIssue) {
        moveIssue(draggedUid, issue.uid);
      }
    },
    [moveIssue, statusId]
  );

  const onDrop = useCallback(
    (prps, monitor) => {
      const draggedUid = monitor.getItem().uid;

      if (draggedUid && changeIssueStatus) {
        changeIssueStatus(draggedUid, statusId);
      }
    },
    [changeIssueStatus, statusId]
  );

  const opacity = 1;

  const containerStyle = useMemo(() => {
    return { opacity };
  }, [opacity]);

  const doClickAction = () => {};

  const doDoubleClickAction = async() => {
    setIsLoading(true);
    try {
      // проверяем, есть ли уже открытая задача в закладках
      const index = store.openedIssueList.findIndex((i) => {
        return i.uid === issue.uid;
      });
      // если нет, то загружаем задачу и добавляем потом в закладки
      if (index < 0) {
        const i = await store.loadIssue(issue.uid);
        store.setActiveIssue(i);
      } else {
        // если есть, то делаем активной эту закладку
        const i = store.openedIssueList[index];
        store.setActiveIssue(i);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onClick = useCallback(() => {
    timer = setTimeout(() => {
      if (!prevent) {
        doClickAction();
      }
      prevent = false;
    }, delay);
  }, [issue]);

  const onDoubleClick = useCallback(() => {
    clearTimeout(timer);
    prevent = true;
    doDoubleClickAction();
  }, [issue]);

  const priorityStyle = useMemo(() => {
    if (priority && priority.color) {
      const colorName = uiStore.colors.getBaseColorName(
        uiStore.colors.getColorNameByHex(priority.color)
      );
      const bgColor =
        (colorName && uiStore.colors.getColorByName(`${colorName}1`)) ||
        colorShade(priority.color, SHADE_BACKGROUND_COLOR);
      const borderColor =
        (colorName && uiStore.colors.getColorByName(`${colorName}3`)) ||
        colorShade(priority.color, SHADE_BORDER_COLOR);
      const txtColor =
        (colorName && uiStore.colors.getColorByName(`d${colorName}3`)) ||
        priority.color;

      return (
        priority &&
        priority.color && {
          backgroundColor: bgColor,
          borderColor,
          color:           txtColor
        }
      );
    }
    return undefined;
  }, [priority]);

  const content = useMemo(() => {
    return (
      <div
        className={classNames("issue-item-body", {
          loading: isLoading
        })}
        ref={refIssue}
        style={containerStyle}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {isLoading && <Components.Preloader className="issue-item-preloader" size={3} />}
        <div className="issue-item-content">
          <ContextMenu.Trigger
            menuId={CTX_MENU_AIS_OBJECT_LINK}
            context={{ object: issue }}
          >
            <div className="issue-item-title">
              <div className="issue-item-title-num">
                <IssueIcon store={store} issue={issue} layoutItem={layoutItem} />
                <div className="issue-item-title-num-id">
                  {`${trackerName} #${issue.redmineId}`}
                </div>
              </div>
              <div className="issue-item-title-subject">{issue.subject}</div>
            </div>
          </ContextMenu.Trigger>
          <div className="issue-item-info">
            {/* status && <div className="issue-item-info-blocks status">{status.name}</div> */}
            {priority && (
              <div
                className="issue-item-info-blocks tags"
                style={priorityStyle}
              >
                {priority.name}
              </div>
            )}
          </div>
        </div>
        <div className="issue-item-user">
          <UserAvatar className="issue-item-user-photo" fileId={userImage} />
        </div>
      </div>
    );
  }, [
    isLoading,
    issue,
    priority,
    priorityStyle,
    containerStyle,
    onClick,
    onDoubleClick,
    userImage,
    trackerName
  ]);

  if (readOnly) {
    return <div className="issue-item read-only">{content}</div>;
  }

  return (
    <div className="issue-item">
      <Dnd.Target
        accept={[DND_ISSUE_TYPE]}
        drop={onDrop}
        canDrop={canDrop}
        hover={onHover}
      >
        <Dnd.Source          
          type={DND_ISSUE_TYPE}
          canDrag={canDrag}          
          item={{
            uid:     issue.uid,
            isCard:  true,
            noStore: true,
            issue
          }}
        >
          {content}
        </Dnd.Source>
      </Dnd.Target>
    </div>
  );
});

IssueItem.propTypes = {
  issue:             PropTypes.instanceOf(IssueModel).isRequired, 
  store:             PropTypes.instanceOf(IssueStore).isRequired, 
  statusId:          PropTypes.number.isRequired, 
  changeIssueStatus: PropTypes.func.isRequired,
  moveIssue:         PropTypes.func.isRequired,
  layoutItem:        PropTypes.object.isRequired  
};

export default IssueItem;
