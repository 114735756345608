import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Components, Dnd } from "@ais3p/ui-framework";
import AisIcon from "~/core/components/AisIcon";
import { DOMAIN_ISSUE } from "~/core/constants/Domains";
import { DND_ISSUE_TYPE } from "~/core/constants/DnD";

/**
 * Иконка для отображения Задачи согласно ее вида (трекера)
 *
 * @param {Object} props набор параметров
 * @param {IssueModel} params.issue модель задачи
 * @param {String} params.className пользловательский className
 * @param {Object} params.layoutItem объект layoutItem
 * @param {Boolean} params.isLoading флаг наличия обработки/запроса
 */
const IssueIcon = ({ issue, className, layoutItem, isLoading }) => {
  const { tracker = {} } = issue;
  const onClick = useCallback(() => {
    if (!layoutItem) {
      // console.error("IssueIcon: layoutItem is undefined");
      return;
    }
    layoutItem.changeContext(issue.project.uid, issue.uid, {
      uid:         issue.uid,
      trackedItem: {
        uid:   issue.uid,
        name:  issue.title,
        class: issue.className,
        tool:  DOMAIN_ISSUE
      }
    });
  }, [issue]);
  const attributes = {
    "data-tooltip":    tracker.title,
    "data-tooltip-at": "bottom"
  };
  const icon = issue.iconString;
  return (
    <Dnd.Source
      uid={`${issue.uid}`}
      type={DND_ISSUE_TYPE}
      item={issue}
    >
      <div
        className={`issue-icon ${className ? className : ""}`}
        {...attributes}
        onClick={onClick}
      >
        {isLoading && <Components.Preloader size={1.5} />}
        {!isLoading && <AisIcon item={issue} icon={icon} />}
      </div>
    </Dnd.Source>
  );
};

IssueIcon.propTypes = {
  issue:      PropTypes.object.isRequired, 
  className:  PropTypes.string, 
  layoutItem: PropTypes.object,
  isLoading:  PropTypes.bool
};

export default IssueIcon;
