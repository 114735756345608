import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import AisIcon from "~/core/components/AisIcon";
import { ContextMenu } from "@ais3p/ui-framework";

const VersionForms = observer(({ node, menuId, collect }) => {
  const titleObject = {
    "text.form.Text": "Текстовая форма АИС"
  };

  
  const fileType = useCallback((form) => {
    const fileType = "download";
    const type = form.contentType && form.contentType.split("/")[0];
    if (form.contentType && form.contentType === "application/pdf" || type === "text") {
      return "iframe";
    }
    if (type === "image") {
      return "image";
    }
    return fileType;
  }, []);

  const forms = useMemo(() => {
    const forms = [];
    node.forms && node.forms.forEach((form) => {
      forms.push(
        <ContextMenu.Trigger
          menuId={menuId}
          collect={collect}
          context={{ ...form, class: form.class || "files.File", fileType: fileType(form) }}
        >
          <AisIcon
            className={"expander big"}
            data-tooltip={form.name || titleObject[form.class]}
            item={{ ...form, class: form.class || "files.File", fileType: fileType(form) }}
          />
        </ContextMenu.Trigger>
      );
    });
    return forms;
  }, [node, node.forms, menuId, collect, fileType]);

  if (forms.length < 1) {
    return null;
  }

  return (
    <div className="ais-wm-version-forms" data-tooltip={"Формы РМ"}>
      {forms}
    </div>
  );
});

export default VersionForms;
