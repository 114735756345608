import React, { useRef, useEffect,  useMemo } from "react";
import PropTypes from "prop-types";
import classNames  from "classnames";

import CodeLineNum from "./CodeLineNum";
import CodeLineControls from "./CodeLineControls";
import CodeLineContent from "./CodeLineContent";
import Indicator from "~/core/components/Indicator";
import { observer } from "mobx-react";
import Line from "../../models/Line";

/**
 * Компонент для отображения линии в коде
 * 
 * @param {Object} params набор параметров
 * @param {Object} params.index индекс линии
 * @param {String} params.className пользовательский className
 * @param {Array<Token>} params.line данные о линии в коде- массив токенов
 * @param {Object} params.getLineProps callback ф-я которая должна вернуть св-ва, 
 *                                 чтобы выставить их DOM компоненты для отрисовки линии
 * @param {Object} params.getTokenProps callback ф-я которая должна вернуть св-ва, 
 *                                 чтобы выставить их DOM компоненты для отрисовки токена
 * @param {CodeFile} params.codeFile объект, хранящий в себе информацию об открытом файле 
 * @param {Boolean} params.isFocused флаг, указывающий, что линия в коде находитя в фокусе
 * @param {String} props.repoName название репозитория
 * 
 * @type {CodeLine}
 * @returns {Component}
 */
const CodeLine = observer(({ 
  className, index, line, getLineProps, getTokenProps, codeFile, needScrollToThisLine, repoName }) => {
  const elRef = useRef(null);

  useEffect(() => {
    if (needScrollToThisLine && elRef.current) {
      elRef.current.scrollIntoView();
    }
  }, [needScrollToThisLine, elRef.current]);

  const codeObj = useMemo(() => {
    return codeFile.getCodeObject(index + 1);
  }, [index]);

  const isFocused = useMemo(() => {
    // if (!codeFile.focusCodeObject && !codeFile.focusCodeLine) {
    //   return false;
    // }
    if (codeFile.focusCodeObject) {
      return codeFile.focusCodeObject.startLine <= (index + 1) && codeFile.focusCodeObject.endLine > index;
    }

    if (codeFile.focusCodeLine === (index + 1)) {
      return true;
    }
    return false;
  }, [index, codeFile.focusCodeObject]);

  return (
    <div
      ref={elRef} 
      className={classNames("code-line", className)}
      {...getLineProps({ line, key: index })}
    >
      <CodeLineNum index={index} line={line} />
      <Indicator 
        data={{
          uid:          codeObj && codeObj.uid, 
          hasRelations: codeObj && codeObj.hasRelations
        }}
      />
      <CodeLineControls 
        index={index} 
        line={line} 
        codeFile={codeFile} 
        isFocused={isFocused}
        repoName={repoName}
      />
      <CodeLineContent 
        index={index} 
        line={line} 
        getTokenProps={getTokenProps}
        isFocused={isFocused}
      />
    </div>
  );
});

CodeLine.propTypes = {
  index:                PropTypes.number.isRequired,
  className:            PropTypes.string,
  line:                 PropTypes.instanceOf(Line),
  needScrollToThisLine: PropTypes.bool,
  getLineProps:         PropTypes.func,
  getTokenProps:        PropTypes.func, 
  codeFile:             PropTypes.object,
  repoName:             PropTypes.string

};


export default CodeLine;
