import { observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import AisIcon from "~/core/components/AisIcon";
import TableDimentions from "./TableDimentions";
import { getClassTitle } from "~/core/constants/Classes";
import useStores from "~/core/utils/useStores";


const PlusMenuItem = observer(({ item, onItemClick }) => {
  const { icon, title, class: className, availableKindsArray } = item;
  const { kindsStore } = useStores();

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      onItemClick && onItemClick(item);
    },
    [item]
  );

  const onClickWithKind = useCallback(
    (e, kind) => {
      e.stopPropagation();
      onItemClick && onItemClick(item, kind);
    },
    [item]
  );

  const onClickDimention = useCallback(
    (x, y) => {
      onItemClick && onItemClick({ ...item, "@columns": x, "@rows": y });
    },
    [item]
  );

  const iconRender = useMemo(() => {
    // if (className === "text.container.EnumerationItem") {
    //   return (
    //     <div  
    //       className="list-bullet-render"
    //     ></div>
    //   );
    // }
    return <AisIcon className={"add-icon"} icon={icon} />;
  }, [icon, className]);

  const dimentions = useMemo(() => {
    if (className !== "text.element.Table") {
      return null;
    }
    return <TableDimentions onClick={onClickDimention} />;
  }, [className, onClickDimention]);

  if (availableKindsArray && availableKindsArray.length) {
    const level = Math.ceil(item.level / 2);
    return (
      <div className="items-holder">
        {availableKindsArray.map((kind, i) => {
          const kindItem = kindsStore.getKind(kind.kind);
          return (
            <div
              key={kind.kind}
                onClick={(e) => { // eslint-disable-line
                onClickWithKind(e, kind);
              }} 
              className="add-item"
              title={kind.kind === "type-only" ? title : kind.kind}
              data-tooltip={`${kind.kind === "type-only" ? getClassTitle(className) : kindItem && kindItem.name}${item.level >= 0 ? ` уровень ${level}` : ""}`}
              data-tooltip-at={i > 0 ? "left" : "top"}
            >
              {kind.kind === "type-only" ? iconRender : <AisIcon className={"add-icon"} icon={kind.icon} />}
              {title && <div className="number">{title}</div>}
              {dimentions}
            </div>
          );
        })}
      </div>
    );
  } else if (availableKindsArray && !availableKindsArray.length) {
    return null;
  }

  return (
    <div 
      onClick={onClick}  
      className={"add-item"}
      data-tooltip={getClassTitle(className)}
    >
      {iconRender}
      {title && <div className="number">{title}</div>}
      {dimentions}
    </div>
  );
});

export default PlusMenuItem;
