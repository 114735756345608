import Server from "~/core/api/server";

class RelationsApi extends Server {
  methods = {
    getKinds: {
      url:         this.getServiceUrl("binder", "api/v2/types"),
      method:      "GET",
      textProcess: "Получение видов связей...",
      textSuccess: null,
      timeout:     4000,
      textError:   this
        .template`Во время получения видов связей произошла ошибка: ${0}`
    },
    getKind: {
      url: (params) => {
        return this.getServiceUrl("binder", this.template`api/v2/types/${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка данных вида связи ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных вида связи произошла ошибка: ${0}`
    },
    getRelations: {
      url:         this.getServiceUrl("binder", "api/v2/links/found-links"),
      method:      "POST",
      textProcess: "Получение связей...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время получения связей произошла ошибка: ${0}`
    },
    getRelationsFromComposer: {
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      textProcess: "Получение связей...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время получения связей произошла ошибка: ${0}`
    },
    getRelation: {
      url: (params) => {
        return this.getServiceUrl("binder", this.template`api/v2/links/${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка данных связи ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных связи произошла ошибка: ${0}`
    },
    bind: {
      url:         this.getServiceUrl("binder", "api/v2/links"),
      method:      "POST",
      textProcess: "Создание связи ...",
      textSuccess: null,
      timeout:     8000,
      textError:   this.template`Во время создания связи произошла ошибка: ${0}`
    },
    delete: {
      url: (params) => {
        return this.getServiceUrl(
          "binder",
          this.template`api/v2/links/${0}`,
          params
        );
      },
      method:      "DELETE",
      textProcess: "Удаление связи ...",
      textSuccess: null,
      timeout:     8000,
      textError:   this.template`Во время Удаления связи произошла ошибка: ${0}`
    }
  };

  async getKinds() {
    return await this.request("getKinds");
  }

  async getRelationKind(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("getKind", reqData);
  }

  async getRelations(pairArray) {
    try {
      const promises = [];
      const window = 5000; // размер окна запроса, чтобы не превысить размер хедера в GET
      const data = [...pairArray];
      while (data.length) {
        promises.push(
          this.request("getRelations", data.splice(0, window))
        );
      }

      const allPromises = await Promise.all(promises);
      const chunkData = [].concat(...allPromises);
      return chunkData;
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async getRelationsFromComposer(uids) {
    const reqData = {
      variables: {
        uids
      },
      query: `query ObjectsQuery ($uids: JSON!){
  Objects(
    ids_and_versions: $uids
  ) {
    ... on AisObject {
      uid
      class
      version
      links {
        uid
        class
        type {
          uid
          class
          name
          sourceCaption
          destCaption
        }
        source {
          uid
          class
          version
          title
          members {
            kindUid
          }
          representation
          fullObjectPath {
            uid
            class
            version
            title
            members {
              kindUid
            }
          }
        }
        dest {
          uid
          class
          version
          title
          members {
            kindUid
          }
          representation
          fullObjectPath {
            uid
            class
            version
            title
            members {
              kindUid
            }
          }
        }
      }
    }
    ... on Object {
      id
      class
      version
    }
  }
}`
    };
    const data = await this.request("getRelationsFromComposer", reqData);
    return data && data.Objects;
  }

  async bind(typeId, source, dest) {
    if (!typeId || !source || !dest) {
      return null;
    }
    const reqData = {
      typeId,
      sourceId:      source.uid,
      sourceClass:   source.class || source.className,
      sourceVersion: source.version || 0,
      destId:        dest.uid,
      destClass:     dest.class || dest.className,
      destVersion:   dest.version || 0
    };
    return await this.request("bind", reqData);
  }

  async loadRelation(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("getRelation", reqData);
  }

  async deleteRelation(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("delete", reqData);
  }
}

export default RelationsApi;
