import { observable, action } from "mobx";

import { iconRender } from "~/core/utils";


import ObjectStore from "./objectStore";
import ConfigStore from "./configStore";
import UiStore from "./uiStore";
import AccountStore from "~/modules/account/stores/accountStore";
import UserStore from "~/modules/users/stores/userStore";
import RelationStore from "~/modules/relations/stores/relationStore";
import KindsStore from "~/modules/kindsAndAttrs/stores/kindsStore";
import WSStore from "~/modules/webSocket/stores/wsStore";
import WorkflowStore from "~/modules/workflow/stores/workflowStore";
import AboutStore from "~/modules/about/data/stores/AboutStore";
import TextStore from "~/modules/newText/stores/DataStore";
import CustomError from "../../components/CustomError";

class RootStore {
  @observable
  userStore = null;
  @observable
  accountStore = null;
  @observable
  relationStore = null;
  @observable
  objectStore = null;
  @observable
  uiStore = null;
  @observable
  wsStore = null;
  @observable
  workflowStore = null;
  @observable
  aboutStore = null;
  @observable
  textStore = null;
  @observable
  modes = new Map();

  constructor() {
    // запросы будут ходить не на сервисы, а будет использоваться localStorage
    this.useLocalStorage = false;

    // Стор пользователей
    this.uiStore = new UiStore(this);
    this.userStore = new UserStore(this);

    // учетная запись
    this.accountStore = new AccountStore(this);
    this.accountStore.init();

    // список объектов
    this.objectStore = new ObjectStore(this);

    this.relationStore = new RelationStore(this);
    this.configStore = new ConfigStore(this);
    this.kindsStore = new KindsStore(this);
    this.workflowStore = new WorkflowStore(this);
    this.wsStore = new WSStore(this);
    this.aboutStore = new AboutStore(this);
    this.textStore = new TextStore(this);

    this.iconString = this.iconString.bind(this);
  }

  iconString(item) {
    let userIconString = null;
    if (this.accountStore !== undefined) {
      userIconString = this.accountStore.getIconString(item);
    }
    if (userIconString) {
      return userIconString;
    }
    return iconRender(item, true);
  }

  @action
  enableMode(mode) {
    this.modes.set(mode, true);
  }

  @action
  disableMode(mode) {
    this.modes.delete(mode);
  }

  @action
  setErrorText(text, critical, status) {
    this.uiStore.setErrorText(text, critical, status);
  }
  /**
   * 
   * @param {Object || string} error объект ошибки либо строка с текстом ошибки 
   * @param {Boolean} critical флаг критической ошибки
   * * @param {Number} status статус-код ошибки
   */

  onError(error, critical, status = null) {
    // чтобы оставить обратную совместимость с вызовом метода с аргументами (string, critical)
    let criticalError = false;
    let statusError = status;
    // проверка второго параметра
    if (typeof critical === "number") {
      statusError = critical;
    } else {
      criticalError = critical;
      let err = error;
      if (typeof err === "string") {
        err = {
          code:    undefined,
          message: error,
          status:  statusError,
          detail:  ""
        };
      } else if (err instanceof Error || err instanceof CustomError) {
        err = error;
      } else {
        err = {
          code:    undefined,
          message: JSON.stringify(error),
          status:  statusError,
          detail:  ""
        };
      } 
      console.warn(err);
      this.setError(err.message, criticalError, err.status, err.detail);
    }
  }

  @action
  setError(text, critical, status, detail) {
    this.uiStore.setErrorText(text, critical, status);
    this.uiStore.setErrorDetail(detail);
  }

  /**
   * Деструктор хранилища
   */
  destroy() {
    try {
      if (this.uiStore && this.uiStore.destroy) {
        this.uiStore.destroy();
      }

      if (this.userStore && this.userStore.destroy) {
        this.userStore.destroy();
      }

      if (this.accountStore && this.accountStore.destroy) {
        this.accountStore.destroy();
      }

      if (this.objectStore && this.objectStore.destroy) {
        this.objectStore.destroy();
      }

      if (this.relationStore && this.relationStore.destroy) {
        this.relationStore.destroy();
      }

      if (this.configStore && this.configStore.destroy) {
        this.configStore.destroy();
      }

      if (this.kindsStore && this.kindsStore.destroy) {
        this.kindsStore.destroy();
      }
      if (this.workflowStore && this.workflowStore.destroy) {
        this.workflowStore.destroy();
      }

      if (this.wsStore && this.wsStore.destroy) {
        this.wsStore.destroy();
      }

      if (this.aboutStore && this.aboutStore.destroy) {
        this.aboutStore.destroy();
      }
      if (this.textStore && this.textStore.destroy) {
        this.textStore.destroy();
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default RootStore;
