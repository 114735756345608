/**
 * Получить иконку файла из названия файла.
 * 
 * @param {String} fileName имя файла
 * 
 * @returns {String} название иконки для файла
 */
const getFileIcon = (fileName) => {
  if (!fileName) {
    return;
  }
  const fileExt = fileName.split(".").pop()
    .toLowerCase();
  switch (fileExt) {
    case "doc":
      return "file-doc-M";
    case "docx":
      return "file-docx-M";
    case "odt":
      return "file-odt-M";
    case "xls":
      return "file-xls-M";
    case "pdf":
      return "file-pdf-M";
    case "c":
    case "cpp":  
      return "file_c-M";
    case "h":  
      return "file_h-M";
    case "png":
    case "jpg":  
    case "jpeg":  
    case "bmp":  
    case "gif":  
    case "tiff":  
    case "psd":  
    case "ai":  
    case "indd":  
    case "raw":  
      return "file-picture-M";
    case "txt":  
    case "md":  
      return "file-text-M";
  }

  return "file-unknown-M";
};

export default getFileIcon;
