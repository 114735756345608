import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import BoundaryItem from "./SchemaBoundaryItem";
import SchemaObjectItemModel from "../../models/schemaObjectItem";

/**
 * Компонент для отображения записи в последовательном списке объектов
 * 
 * @param {Object} props набор параметров
 * @param {SchemaObjectItemModel} props.item  запись Доли в схеме отчетов о трассировке
 */
const TraceItem = observer(({ item }) => {
  const targetInfo = useMemo(() => {
    const res = [];
    if (!item.target) {
      return null;
    }
    if (item.target.class) {
      res.push(
        <div key="class" className="target-item">
          <label>Class:</label>
          <span>{item.target.class}</span>
        </div>
      );
    }
    if (item.target.version || item.target.version === 0) {
      res.push(
        <div key="version" className="target-item">
          <label>Версия:</label>
          <span>{item.target.version}</span>
        </div>
      );
    }

    return res;
  }, [item && item.target]);

  return (
    <div 
      className="schema-trace-item"
    >
      <div 
        className="schema-trace-item-title"
      >
        <span>{item.description}</span>
      </div>
      {targetInfo && targetInfo.length > 0 &&
        <div className="schema-trace-item-target">
          {targetInfo}
        </div>
      }
      <div 
        className="schema-trace-item-boundaries"
      >
        {
          item.boundaries && item.boundaries.length === 0 &&
          <div className="schema-trace-item-boundaries-empty">
            Нет ограничений
          </div>
        }
        {item.boundaries.map((b, index) => {
          return (
            <BoundaryItem 
              key={index} 
              index={index}
              schemaObjectItem={item} 
              boundary={b} 
            />
          );
        })}
      </div> 
    </div>
  );
});

TraceItem.propTypes = {
  item: PropTypes.instanceOf(SchemaObjectItemModel).isRequired
};


export default TraceItem;
