import packageJson from "~/../package.json";

const servicesVersions = [
  {
    name:    "ui.spa",
    version: packageJson.version
  },
  {
    name:    "ui.framework",
    version: "0.6.2"
  },
  {
    name:    "transport.gateway",
    version: "0.5.0"
  },
  {
    name:    "app.approvals",
    version: "0.1.0"
  },
  {
    name:    "app.binder",
    version: "0.3.0"
  },
  {
    name:    "app.composer",
    version: "0.2.1"
  },
  {
    name:    "app.kindsattrs",
    version: "0.6.4"
  },
  {
    name:    "app.library",
    version: "0.7.3"
  },
  {
    name:    "app.sourcecode",
    version: "0.2.2"
  },
  {
    name:    "app.tables",
    version: "0.2.4"
  },
  {
    name:    "app.tasks",
    version: "0.2.6"
  },
  {
    name:    "app.text",
    version: "0.21.0"
  },
  {
    name:    "app.tracer",
    version: "0.7.1"
  },
  {
    name:    "app.workflow",
    version: "2.9.9"
  },
  {
    name:    "common.admin",
    version: "0.7.0"
  },
  {
    name:    "common.journal",
    version: "0.2.4"
  },
  {
    name:    "common.styles",
    version: "0.1.0"
  },
  {
    name:    "common.users",
    version: "0.2.2"
  },
  {
    name:    "impexp.mathml",
    version: ""
  },
  {
    name:    "impexp.off",
    version: "0.5.3"
  },
  {
    name:    "impexp.pdf",
    version: ""
  },
  {
    name:    "impexp.reqs",
    version: "0.2.0"
  },
  {
    name:    "storage.files",
    version: "0.4.2"
  }
];
// eslint-disable-next-line import/prefer-default-export
export { servicesVersions };
