import moment from "moment";
import { observable, computed, action, toJS } from "mobx";

// import BaseRedmineModel from "./baseRedmineModel";
import TrackerModel from "./trackerModel";
import RedmineUserModel from "./userModel";
import ProjectModel from "./projectModel";
import AttachmentModel from "./attachmentModel";
import JournalModel from "./journalModel";
import AisVersion from "~/core/data/models/AisVersion";


import { DOMAIN_ISSUE } from "~/core/constants/Domains";
import IssueStore from "../stores/IssueStore";
import RootStore from "~/core/data/stores/rootStore";
import { CLS_ISSUE } from "~/core/constants/Classes";

/**
 * Модель задачи
 *
 * @class IssueModel
 */
class IssueModel extends AisVersion {
  /**
   * id задачи в Redmine
   *
   * @type String
   */
  @observable
  redmineId = undefined;

  /**
   * uid задачи для АИС
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * id статуса
   *
   * @type String
   */
  @observable
  statusId = undefined;

  /**
   * Тема задачи
   *
   * @type String
   */
  @observable
  subject = undefined;

  /**
   * Описание задачи
   *
   * @type String
   */
  @observable
  description = undefined;

  /**
   * Дата создания задачи
   *
   * @type DateTime
   */
  @observable
  createdOn = undefined;

  /**
   * Дата обновления задачи
   *
   * @type DateTime
   */
  @observable
  updatedOn = undefined;

  /**
   * Дата начала задачи
   *
   * @type DateTime
   */
  @observable
  startDate = undefined;

  /**
   * Срок завершения задачи
   *
   * @type DateTime
   */
  @observable
  dueDate = undefined;

  /**
   * Оценка временных затрат в часах
   *
   * @type Number
   */
  @observable
  estimatedHours = null;

  /**
   * Готовность задачи в %
   *
   * @type Number
   */
  @observable
  doneRatio = null;


  /**
   * id приоритета
   *
   * @type String
   */
  @observable
  priorityId = undefined;

  /**
   * Автор задачи в Redmine
   *
   * @type RemineUserModel
   */
  @observable
  author = undefined;

  /**
   * Пользователь в Redmine, на которого назначена задача
   *
   * @type RedmineUserModel
   */
  @observable
  assignedTo = undefined;

  /**
   * Трекер задачи
   *
   * @type TrackerModel
   */
  @observable
  tracker = undefined;

  /**
   * проект
   *
   * @type ProjectModel
   */
  @observable
  project = undefined;

  /**
   * Список прикрепленных файлов
   *
   * @type Array <AttachmentModel>
   */
  @observable
  attachments = [];

  /**
   * Список наблюдателй
   *
   * @type Map <UserModel>
   */
  @observable
  watchers = new Map();

  /**
   * Список изменений
   *
   * @type Array <JournalModel>
   */
  @observable
  journal = [];


  /**
   * Флаг для сортировки
   *
   * @type Number
   */
  @observable
  order = 0;

  /**
   * Массив подзадач 
   *
   * @type Array<Object>
   */
  @observable
  children = [];

  /**
   * Родительская задача
   *
   * @type Object
   */
  @observable
  parent = undefined;

  /**
   * Массив связей на задачу
   *
   * @type Array<Object>
   */
  @observable
  relations = [];

  /**
   * Массив доступных статусов
   *
   * @type Array<Object>
   */
  @observable
  allowedStatuses = [];

  /**
   * Флаг - показывать ли для этой задачи журнал изменений
   *
   * @type Boolean
   */
  @observable
  isShownJournal = false;

  /**
   * Флаг - режим редактирования
   *
   * @type Boolean
   */
  @observable
  isEditingMode = false;

  /**
   * Временные значения
   *
   * @type Object
   */
  @observable
  tmpValues = undefined;

  /**
   * Флаг - есть ли изменения в tmpValues
   *
   * @type Boolean
   */
  @observable
  hasChanges = false;

  /**
   * Флаг - идет ли обработка задачи со стороны сервера
   *
   * @type Boolean
   */
  @observable
  isPending = false;

  /**
   * Участник вида с атрибутами
   *
   * @type KindMember
   */
  @observable
  kindMember = undefined;

  /**
   * Домен принадлежности объекта в ObjectStore
   *
   * @type String
   */
  domain = DOMAIN_ISSUE;

  /**
   * Класс объекта в АИСППП
   *
   * @type String
   */
  @observable
  class = CLS_ISSUE;

  /**
   * Кол-во записей на странице в журнале
   *
   * @type Number
   */
  @observable
  journalPerPage = 20;

  /**
   * Текущая страница в журнале
   *
   * @type Number
   */
  @observable
  journalCurrentPage = 1;

  /**
   * Хранилище задач
   *
   * @type IssueStore
   */
  issueStore = undefined;


  /**
   * Валидатор темы задачи
   *
   * @param {String} name название задачи
   *
   * @return {Object} { success: true|false, message: ""}
   */
  static validateSubject(subject = "") {
    const res = subject.length >= 2;
    if (!res) {
      return {
        success: false,
        message: "Название темы должно быть больше одного символа"
      };
    }
    return { success: true };
  }

  /**
   * Валидатор описания задачи
   *
   * @param {String} description название задачи
   *
   * @return {Object} { success: true|false, message: ""}
   */
  static validateDescription(description = "") {
    const res = description.length >= 2;
    if (!res) {
      return {
        success: false,
        message: "Название должно быть больше одного символа"
      };
    }
    return { success: true };
  }

  /**
   * Валидатор готовности задачи
   *
   * @param {Number} value готовность задачи
   *
   * @return {Object} { success: true|false, message: ""}
   */
  static validateDoneRation(value) {
    if (!value) {
      return { success: true };
    }
    if (isNaN(Number(value))) {
      return {
        success: false,
        message: "Допустимо только значение типа :\"Число\"."
      };
    } else {
      if (value < 0)
        return {
          success: false,
          message: "Значение должно быть >= 0"
        };

      if (value > 100)
        return {
          success: false,
          message: "Значение должно быть <= 100"
        };
    }

    return { success: true };
  }

  /**
   * Валидатор оценки временных затрат задачи
   *
   * @param {Number} value оценка временных затрат задачи
   *
   * @return {Object} { success: true|false, message: ""}
   */
  static validateEstimatedHours(value) {
    if (!value) {
      return { success: true };
    }

    if (isNaN(Number(value))) {
      return {
        success: false,
        message: "Допустимо только значение типа :\"Число\"."
      };
    } else {
      if (value < 0)
        return {
          success: false,
          message: "Значение должно быть >= 0"
        };
    }



    return { success: true };
  }

  static titlePrefix(tracker, redmineId) {
    return `${tracker && (tracker.title || tracker.name)} #${redmineId}`;
  }

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id задачи в Redmine
   * @param {String} params.uid uid задачи в АИС
   * @param {String} params.subject тема задачи
   * @param {String} params.description описание задачи
   * @param {String} params.statusId id статуса задачи
   * @param {Array<Object>} params.allowedStatuses список статусов задачи, которые можно будет ей задать:
   *   {id, name, is_closed}
   * @param {Date} params.createdOn дата создания задачи
   * @param {Date} params.updatedOn дата обновления задачи
   * @param {Date} params.startDate дата начала задачи
   * @param {Date} params.dueDate срок завершегия задачи
   * @param {Number} params.estimatedHours оценка времени в часах
   * @param {Number} params.doneRatio готовность задачи в %
   * @param {String} params.priorityId id приоритета задачи
   * @param {Object} params.assignedTo пользователь, на которого назначена задача
   * @param {String} params.assignedTo.id id пользователя в Redmine, на которого назначена задача
   * @param {String} params.assignedTo.name имя пользователя в Redmine, на которого назначена задача
   * @param {String} params.assignedTo.uid uid пользователя в АИС, на которого назначена задача
   * @param {Object} params.author автор задачи
   * @param {String} params.author.id id автора задачи в Redmine
   * @param {String} params.author.name имя автора задачи в Redmine
   * @param {String} params.author.uid uid автора задачи в АИС
   * @param {Object} params.tracker трекер задачи
   * @param {String} params.tracker.id id трекера задачи в Redmine
   * @param {String} params.tracker.name название трекера задачи в Redmine
   * @param {String} params.tracker.uid uid вида задачи в АИС
   * @param {Object} params.project проект задачи
   * @param {String} params.project.id id проекта задачи в Redmine
   * @param {String} params.project.name название проекта задачи в Redmine
   * @param {String} params.project.uid uid проекта задачи в АИС
   * @param {Array<Object>} params.attachments список прикрепленных файлов
   * @param {Array<Object>} params.watchers список наблюдателей
   * @param {Array<Object>} params.journal список изменений
   * @param {Object} params.parent родительская задача
   * @param {Array<Object>} params.children список подзадач
   * @param {Array<Object>} params.relations список связей с другими задачами
   * @param {IssueStore} issueStore хранилище задач
   * @retrun {IssueModel}
   */
  static create({
    id,
    uid,
    subject,
    description,
    statusId,
    allowedStatuses,
    createdOn,
    updatedOn,
    startDate,
    dueDate,
    estimatedHours,
    doneRatio,
    priorityId,
    assignedTo,
    author,
    tracker,
    project,
    order,
    attachments,
    watchers,
    journal = [],
    children,
    parent,
    relations
  }, issueStore) {
    return new IssueModel({
      redmineId:   id,
      uid,
      subject,
      description,
      statusId,
      allowedStatuses,
      createdOn,
      updatedOn,
      startDate,
      dueDate,
      estimatedHours,
      doneRatio,
      priorityId,
      assignedTo:  assignedTo && RedmineUserModel.create(assignedTo, issueStore),
      author:      author &&  RedmineUserModel.create(author, issueStore),
      tracker:     tracker && TrackerModel.create(tracker, issueStore),
      project:     project && ProjectModel.create(project, issueStore),
      order,
      attachments: attachments && attachments.map((item) => {
        return AttachmentModel.create(item, issueStore);
      }),
      watchers: watchers && watchers.map((user) => {
        return RedmineUserModel.create(user, issueStore);
      }),
      journal: journal && journal.map((j) => {
        return JournalModel.create(j, issueStore);
      }),
      children,
      parent,
      relations
    }, issueStore);
  }

  constructor(params, store) {
    let rootStore;
    let issueStore;
    if (store instanceof IssueStore) {
      issueStore = store;
      rootStore = store.rootStore;
    }

    if (store instanceof RootStore) {
      rootStore = store;
    }

    super(params, rootStore.objectStore);

    this.issueStore = issueStore;
    this.rootStore = rootStore;
    this.redmineId = params.redmineId;
    this.uid = params.uid;
    this.subject = params.subject;
    this.description = params.description;
    this.statusId = params.statusId;
    this.allowedStatuses = params.allowedStatuses;
    this.createdOn = params.createdOn && moment(params.createdOn, "DD.MM.YYYY HH:mm:ss").toDate(),
    this.updatedOn = params.updatedOn && moment(params.updatedOn, "DD.MM.YYYY  HH:mm:ss").toDate(),
    this.startDate = params.startDate && moment(params.startDate, "DD.MM.YYYY").toDate(),
    this.dueDate = params.dueDate && moment(params.dueDate, "DD.MM.YYYY").toDate();
    this.estimatedHours = params.estimatedHours;
    this.doneRatio = params.doneRatio;
    this.priorityId = params.priorityId;
    this.assignedTo = params.assignedTo;
    this.author = params.author;
    this.tracker = params.tracker;
    this.project = params.project;
    this.order = params.order;
    (params.attachments  || []).forEach((attachment) => {
      this.addAttachment(attachment);
    });
    (params.watchers  || []).forEach((user) => {
      this.addWatcher(user);
    });
    (params.journal || []).forEach((j) => {
      this.addJournal(j);
    });

    this.parent = params.parent;
    this.children = params.children || [];
    this.relations = params.relations || [];

    // Переводим сразу в режим редактирования новую задачу
    this.setIsEditingMode(!this.redmineId);
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "issueModel";
  }

  
  /**
   * Получить название иконки для задачи
   * @return {String}
   */
  @computed
  get iconString() {
    return this.getIconByTracker(this.tracker);
  }

  getIconByTracker(tracker) {
    if (this.rootStore && tracker) {
      const kind = this.rootStore.kindsStore.getKind(tracker.uid);
      if (kind) {
        return this.rootStore.accountStore.getIcon(kind.uid);
      }
    }

    return "reglament-M";
  } 

  /**
   * Флаг, указывающий если связь сущности в Redmine сущностью в АИС
   *
   * @return {Boolean}
   */
  @computed
  get isLinked() {
    return !!this.uid;
  }

  /**
   * Флаг, указывающий новая ли сущность
   *
   * @return {Boolean}
   */
  @computed
  get isNew() {
    return !this.redmineId;
  }

  /**
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.subject тема задачи
   * @param {String} params.description описание задачи
   * @param {String} params.statusId id статуса задачи
   * @param {Date} params.startDate дата начала задачи
   * @param {Date} params.dueDate срок завершегия задачи
   * @param {Number} params.estimatedHours оценка времени в часах
   * @param {Number} params.doneRatio готовность задачи в %
   * @param {String} params.priorityId приоритет задачи
   * @param {String} params.assignedToUid uid пользователя в АИС, на которого назначена задача
   * @param {String} params.authorUid uid автора задачи в АИС
   */
  @action
  update(data) {
    this.redmineId = data.id || this.redmineId;
    this.uid = data.uid || this.uid;
    if (data.hasOwnProperty("name")) {
      this.name = data.name;
    }
    if (data.hasOwnProperty("subject")) {
      this.subject = data.subject;
    }
    if (data.hasOwnProperty("description")) {
      this.description = data.description;
    }
    if (data.hasOwnProperty("statusId")) {
      this.statusId = data.statusId;
    }
    if (data.hasOwnProperty("allowedStatuses")) {
      this.allowedStatuses = data.allowedStatuses;
    }
    if (data.hasOwnProperty("estimatedHours")) {
      this.estimatedHours = data.estimatedHours ? data.estimatedHours : undefined;
    }
    if (data.hasOwnProperty("doneRatio")) {
      this.doneRatio = data.doneRatio;
    }
    if (data.hasOwnProperty("priorityId")) {
      this.priorityId = data.priorityId;
    }
    if (data.hasOwnProperty("order")) {
      this.order = data.order;
    }
    if (data.hasOwnProperty("assignedTo")) {
      this.assignedTo = data.assignedTo ? RedmineUserModel.create(data.assignedTo, this.issueStore) : undefined;
    }

    if (data.hasOwnProperty("author")) {
      this.author  = data.author ? RedmineUserModel.create(data.author, this.issueStore) : undefined;
    }

    if (data.hasOwnProperty("project")) {
      this.project  = data.project ? ProjectModel.create(data.project, this.issueStore) : undefined;
    }
    
    if (data.hasOwnProperty("createdOn")) {
      if (data.createdOn) {
        this.createdOn = (data.createdOn instanceof Date) ?
          data.createdOn :
          moment(data.createdOn, "DD.MM.YYYY HH:mm:ss").toDate();
      } else {
        this.createdOn = undefined;
      }
    }

    if (data.hasOwnProperty("createdOn")) {
      if (data.updatedOn) {
        this.updatedOn = (data.updatedOn instanceof Date) ?
          data.updatedOn :
          moment(data.updatedOn, "DD.MM.YYYY HH:mm:ss").toDate();
      } else {
        this.updatedOn = undefined;
      }
    }

    if (data.hasOwnProperty("startDate")) {
      if (data.startDate) {
        this.startDate = (data.startDate instanceof Date) ?
          data.startDate :
          moment(data.startDate, "DD.MM.YYYY HH:mm:ss").toDate();
      } else {
        this.startDate = undefined;
      }
    }

    if (data.hasOwnProperty("dueDate")) {
      if (data.dueDate) {
        this.dueDate = (data.dueDate instanceof Date) ?
          data.dueDate :
          moment(data.dueDate, "DD.MM.YYYY HH:mm:ss").toDate();
      } else {
        this.dueDate = undefined;
      }
    }
    

    if (data.hasOwnProperty("attachments")) {
      // this.attachments.clear();
      this.attachments = [];
      data.attachments.forEach((item) => {
        this.addAttachment(AttachmentModel.create(item, this.issueStore));
      });
    }

    if (data.hasOwnProperty("watchers")) {
      this.watchers.clear();
      data.watchers.forEach((user) => {
        this.addWatcher(RedmineUserModel.create(user, this.issueStore));
      });
    }

    if (data.journal) {
      this.journal.clear();
      data.journal.forEach((item) => {
        this.addJournal(JournalModel.create(item, this.issueStore));
      });
    }

    if (data.hasOwnProperty("parent")) {
      this.parent = data.parent;
    }
    
    if (data.hasOwnProperty("children")) {
      this.children = data.children;
    }

    if (data.hasOwnProperty("relations")) {
      this.relations = data.relations;
    }
  }

  @action
  setParent(parent) {
    this.parent = parent;
  }

  /**
   * Сделать снимок состояния модели, чтобы можно было откатиться к прежнему состоянию - revert
   */
  createSnapshot() {
    this.snapshot = this.config;
  }

  /**
   * Откатиться к прежнему состоянию
   */
  revert() {
    if (this.snapshot) {
      this.update(this.snapshot);
    }
    this.snapshot = undefined;
  }

  /**
   * Зафиксировать состояние  и уничтожить снимок состояния модели
   */
  commit() {
    this.snapshot = undefined;
  }

  /**
   * Строковое представление задачи
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.subject;
  }

  /**
   * Строковое представление номера задачи
   *
   * @return {String}
   */
  @computed
  get titlePrefix() {
    if (this.isNew) {
      return this.tracker && this.tracker.title || "Новая задача";
    }
    // Сделал вывод через статичный метод, тк для отображения номера задачи
    // формирование такого номера еще потребуется для формирвоания списка подзадач
    return IssueModel.titlePrefix(this.tracker, this.redmineId);
  }

  /**
   * Связан ли автор с пользователем в АИС
   *
   * @return {Boolean}
   */
  @computed
  get isAuthorLinked() {
    return !!this.author.uid;
  }

  /**
    * Связан ли назанченец задачи с пользователем в АИС
   *
   * @return {Boolean}
   */
  @computed
  get isAssignedToLinked() {
    return !!this.assignedTo.uid;
  }

  /**
   * Объект статуса задачи
   *
   * @return {StatusModel}
   */
  @computed
  get status() {
    if (!this.issueStore) {
      console.error("У модели IssueModel не задан issueStore!");
      return undefined;
    }

    return this.issueStore.statuses.get(this.statusId);
  }

  /**
   * Объект приоритета задачи
   *
   * @return {StatusModel}
   */
  @computed
  get priority() {
    if (!this.issueStore) {
      console.error("У модели IssueModel не задан issueStore!");
      return undefined;
    }

    return this.issueStore.priorities.get(this.priorityId);
  }

  /**
   * Список прикерпленных файлов
   *
   * @return {Array <AttachmentModel>}
   */
  @computed
  get attachmentList() {
    // return Array.from(this.attachments.values());
    return this.attachments;
  }

  @computed
  get relatedIssues() {
    const result = [];
    let level = 0;
    if (this.parent) {
      result.push({ 
        ...this.parent, 
        title:       this.parent.subject, 
        titlePrefix: IssueModel.titlePrefix(this.parent.tracker, this.parent.redmineId || this.parent.id),
        iconString:  this.getIconByTracker(this.parent.tracker),
        level 
      }); 
      level += 1;
    }

    result.push({ 
      id:          this.id,
      uid:         this.uid,
      subject:     this.subject,
      title:       this.subject,
      titlePrefix: this.titlePrefix,
      tracker:     this.tracker,
      iconString:  this.iconString,
      level 
    });

    return result.concat(this.processChildren(this.children, level + 1));
  }

  processChildren(children = [], level) {
    let result = [];
    children.forEach((child) => {
      result.push({ 
        ...child, 
        title:       child.subject, 
        titlePrefix: IssueModel.titlePrefix(child.tracker, child.id),
        iconString:  this.getIconByTracker(child.tracker), 
        level 
      });
      if (child.children && child.children.length > 0) {
        result = result.concat(this.processChildren(child.children,  level + 1));
      }
    });

    return result;
  }

  /**
   * Добавить файл
   *
   * @param {AttachmentModel}
   */
  @action
  addAttachment(attachment) {
    // T4277: Храним файл в списке под ключем redmineId, тк если пользователь начнет повторно прикерплять
    // уже загруженный файл, то хранилище АИС вернет uid уже до этого загруженного файла. А это приводит
    // к отображению только одного файла в списке, а не несколько, как приходит от Redmine
    // this.attachments.set(attachment.redmineId, attachment);
    this.attachments.push(attachment);
  }

  /**
   * Удалить файл
   *
   * @param {AttachmentModel}
   */
  @action
  deleteAttachment(attachment) {
    // T4277: Храним файл в списке под ключем redmineId, тк если пользователь начнет повторно прикерплять
    // уже загруженный файл, то хранилище АИС вернет uid уже до этого загруженного файла. А это приводит
    // к отображению только одного файла в списке, а не несколько, как приходит от Redmine
    // this.attachments.delete(attachment.redmineId);
    const index = this.attachments.indexOf(attachment);
    if (index > -1) {
      this.attachments.splice(index, 1);
    }
  }

  /**
   * Получить прикрепленный файл по его Id в хранилище Redmine
   *
   * @param {String} redmineId id файла в хранилище Redmine
   *
   * @return {AttachmentModel}
   */
  getAttachment(redmineId) {
    // return this.attachments.get(redmineId);
    return this.attachments.filter((attachment) => {
      return attachment.redmineId === redmineId;
    })[0];
  }

  /**
   * Добавить запись истории изменений
   *
   * @param {JurnalModel} item запись журнала изменений
   */
  @action
  addJournal(item) {
    this.journal.push(item);
  }

  /**
   * Список наблюдателей
   *
   * @return {Array <UserModel>}
   */
  @computed
  get watcherList() {
    return Array.from(this.watchers.values());
  }

  /**
   * Список uid'ов наблюдателей
   *
   * @return {Array <UserModel>}
   */
  @computed
  get watcherUids() {
    return this.watcherList.map((u) => {
      return u.uid;
    });
  }

  /**
   * Добавить наблюдателя
   *
   * @param {UserModel}
   */
  @action
  addWatcher(user) {
    this.watchers.set(user.uid, user);
  }

  /**
   * Удалить файл
   *
   * @param {AttachmentModel}
   */
  @action
  deleteWatcher(user) {
    this.watchers.delete(user.uid);
  }

  /**
   * Получить наблюдателя
   *
   * @param {String} uid пользователя
   *
   * @return {WatcherModel}
   */
  getWatcher(uid) {
    return this.watchers.get(uid);
  }

  /**
   * Отобразить/скрыть журнал изменений у задачи
   *
   */
  @action
  toggleShowJournal() {
    this.isShownJournal = !this.isShownJournal;
  }

  @computed
  get authorUid() {
    return this.author && this.author.uid;
  }

  @computed
  get assignedToUid() {
    return this.assignedTo && this.assignedTo.uid;
  }

  /**
   * Просрочена ли задача
   */
  get isExpired() {
    return this.dueDate && this.dueDate < new Date();
  }

  /**
   * Задать/отменить режим редактирования
   *
   * @param {Boolean} mode режим редактирования true/false
   */
  @action
  setIsEditingMode(mode) {
    this.isEditingMode = mode;
    if (mode) {
      this.tmpValues = {
        subject:        this.subject,
        description:    this.description,
        priorityId:     this.priorityId,
        // statusId:       this.isNew ? undefined : this.statusId, // статус у новой задачи будет выставлять сам Redmine
        createdOn:      this.createdOn,
        updatedOn:      this.updatedOn,
        startDate:      this.startDate,
        dueDate:        this.dueDate,
        estimatedHours: this.estimatedHours,
        doneRatio:      this.doneRatio,
        order:          this.order,
        trackerUid:     this.tracker && this.tracker.uid,
        projectUid:     this.project && this.project.uid,
        assignedToUid:  this.assignedTo && this.assignedTo.uid,
        authorUid:      this.author && this.author.uid,
        attachments:    this.attachmentList.map((a) => {
          return a.config;
        }),
        addAttachments:    [],
        deleteAttachments: [],
        addWatchers:       new Set(),
        deleteWatchers:    new Set(),
        watchers:          new Set(this.watcherList),
        watcherUids:       this.watcherUids
      };

      if (!this.isNew) {
        this.tmpValues.statusId = this.statusId; // статус у новой задачи будет выставлять сам Redmine
      }
    } else {
      this.tmpValues = undefined;
      this.hasChanges = false;
    }
  }

  @computed
  get jsonTmpValues() {
    if (!this.tmpValues) {
      return {};
    }
    const data =  {
      ...this.tmpValues,
      addWatchers:    Array.from(this.tmpValues.addWatchers.values()),
      deleteWatchers: Array.from(this.tmpValues.deleteWatchers.values())
    };

    if (data.attributes) {
      data.attributes = data.attributes.attributes;
    }

    // удляем лишний параметр watchers, тк этот парметр не обрабатывается сервисом 
    // (обрабатываются только addWatchers и deleteWatchers) и там находятся объекты RedmineModel
    // которые при вызове toJS начинают закольцовывать преобразования из-за присутствия в них ссылки на rootObject 
    delete data.watchers;
    const res = toJS(data);
    return res;
  }

  /**
   * Задать временное значение поля в задаче
   *
   * @param {String} name название поля
   * @param {Any} value значение поля
   */
  @action
  setTmpValue(name, value) {
    this.hasChanges = true;
    this.tmpValues = {
      ...this.tmpValues,
      [name]: value
    };
  }

  /**
   * Верно ли заданы все временные параметры задачи
   *
   * @return {Boolean}
   */
  @computed
  get isTmpValuesValid() {
    if (!this.tmpValues) {
      return false;
    }

    const attributesIsValid = this.tmpValues.attributes ? this.tmpValues.attributes.isValid : true;
    const res = IssueModel.validateSubject(this.tmpValues.subject).success &&
                IssueModel.validateDescription(this.tmpValues.description).success &&
                IssueModel.validateDoneRation(this.tmpValues.doneRatio).success &&
                IssueModel.validateEstimatedHours(this.tmpValues.estimatedHours).success &&
                !!this.tmpValues.startDate &&
                (this.tmpValues.dueDate ? this.tmpValues.startDate <= this.tmpValues.dueDate : true) &&
                !!this.tmpValues.assignedToUid &&
                !!this.tmpValues.priorityId &&
                (this.isNew ? true : !!this.tmpValues.statusId) &&
                (this.isNew ? attributesIsValid : true) &&
                !!this.tmpValues.projectUid &&
                (this.kindMember ? this.kindMember.isValid : true);

    return res;
  }

  /**
   * Задать флаг обработки задачи
   *
   * @param {Boolean} peding идет ли обработка задачи
   */
  @action
  setIsPending(pending) {
    this.isPending = pending;
  }

  /**
   * Задать участника Вида
   *
   * @param {kindMember} member участник вида
   */
  @action
  setKindMember(member) {
    this.kindMember = member;
  }

  /**
   * @computed
   * Uid проекта в АИСППП, к которому принадлежит задача
   *
   * @return {String} 
   */
  @computed
  get projectUid() {
    return this.project && this.project.uid;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  @computed
  get config() {
    return {
      id:             this.redmineId,
      uid:            this.uid,
      subject:        this.subject,
      description:    this.description,
      statusId:       this.statusId,
      priorityId:     this.priorityId,
      createdOn:      this.createdOn,
      updatedOn:      this.updatedOn,
      startDate:      this.startDate,
      dueDate:        this.dueDate,
      estimatedHours: this.estimatedHours,
      doneRatio:      this.doneRatio,
      order:          this.order,
      attachments:    this.attachmentList.map((a) => {
        return a.config;
      }),
      watchers: this.watcherList.map((w) => {
        return w.config;
      }),
      tracker:    this.tracker && this.tracker.config,
      project:    this.project && this.project.config,
      assignedTo: this.assignedTo && this.assignedTo.config,
      author:     this.author && this.author.config
    };
  }

  /**
   * @computed
   * Получить отсортированный журнал
   *
   * @return {Array<JounnalModel>} данные
   */
  @computed
  get journalSorted() {
    return this.journal.slice()
      .sort((a, b) => {
        if (a.createdOn > b.createdOn) {
          return -1;
        }

        if (a.createdOn < b.createdOn) {
          return 1;
        }

        return 0;
      });
  }

  /**
   * @computed
   * Получить данные журнала, согласно пагинатора
   *
   * @return {Array<JounnalModel>} данные
   */
  @computed
  get journalData() {
    const startIndex = (this.journalCurrentPage - 1) * this.journalPerPage;
    const endIndex =  startIndex + this.journalPerPage;
    const data = this.journalSorted.slice(startIndex, endIndex);
    return data; 
  }

  @computed
  get journalTotalRecords() {
    return this.journal.length;
  }

  /**
   * @action
   * Задать номер текущей страницы у журнала
   *
   */
  @action
  journalSetCurrentPage(value) {
    this.journalCurrentPage = value;
  }

  /**
   * @action
   * Задать кол-во записей на стринице в журнале
   *
   */
  @action
  journalSetPerPage(value) {
    this.journalPerPage = value;
  }

  /**
   * @computed
   * Старт номеров записей на текущей странице у журнала
   *
   * @return {Number}
   */
  @computed
  get journalNumStart() {
    return (this.journalCurrentPage - 1) * this.journalPerPage;
  }

  /**
   * @computed
   * Кол-во страниц у журнала
   *
   * @return {Number}
   */
  @computed
  get journalPageCount() {
    if (this.journalTotalRecords === 0) {
      return 0;
    }

    return  Math.ceil(this.journalTotalRecords / this.journalPerPage);
  }

  /**
   * @computed
   * Можно ли перейти на предыдущую страницу в журнале
   *
   * @return {Boolean}
   */
  @computed
  get journalCanPreviousPage() {
    return this.journalCurrentPage > 1;
  }

  /**
   * @computed
   * Можно ли перейти на следуюущую страницу в журнале
   *
   * @return {Number}
   */
  @computed
  get journalCanNextPage() {
    return this.journalCurrentPage < this.journalPageCount;
  }

  /**
   * Задать кол-во записей на страницу
   *
   * @param {Number} size кол-во записей на страницу в журнале
   */
  journalSetPageSize(size) {
    this.journalSetCurrentPage(1);
    this.journalSetPerPage(size);
  }

  /**
   * Перейти на  страницу
   *
   * @param {Number} page номер страницы, на которую нужно перейти в журнале
   */
  journalGoToPage(page) {
    if (page < 1 || page > this.journalPageCount) {
      return;
    }

    this.journalSetCurrentPage(page);
  }

  /**
   * Перейти на следующую страницу в журнале
   *
   */
  journalGoToNextPage() {
    if (!this.journalCanNextPage) {
      return;
    }

    this.journalSetCurrentPage(this.journalCurrentPage + 1);
  }

  /**
   * Перейти на предыдущую страницу в журнале
   *
   */
  journalGoToPreviousPage() {
    if (!this.journalCanPreviousPage) {
      return;
    }

    this.journalSetCurrentPage(this.journalCurrentPage - 1);
  }


  /**
   * Уничтожить объект.
   */
  destroy() {

  }
}

export default IssueModel;
