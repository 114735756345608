import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStores } from "../../../core/utils";
import { DOMAIN_LIBRARY, DOMAIN_TEXT } from "../../../core/constants/Domains";

const TitleRender = observer(({ uid, domain, version, fallback }) => {
  const { objectStore } = useStores();
  const item = useMemo(() => {
    const countedDomain = domain === DOMAIN_TEXT ? DOMAIN_LIBRARY : domain;
    if (countedDomain === DOMAIN_LIBRARY && version > 0) {
      const parent = objectStore.getVersion(uid, countedDomain, 0);
      let item = null;
      if (parent) {
        parent.childItems.forEach((child) => {
          if (child.number === version) {
            item = child;
          }
        });
      }
      if (item) {
        return item;
      }
    }
    return objectStore.getVersion(uid, countedDomain);
  }, [uid, domain]);

  if (!item) {
    if (!fallback) {
      return null;
    }
    return fallback;
  }

  return item.title;
});

export default TitleRender;
