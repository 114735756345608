import { observable, computed, action } from "mobx";
import { getDomainByClass } from "~/core/utils";
import processComposerAisObject from "~/core/utils/aisHelpers";

/**
 * Модель записи объекта в данных отчета о трассировке
 *
 * @class  DataObjectItemModel
 */
class DataObjectItemModel {
  /**
   * uid объекта
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Class объекта
   *
   * @type String
   */
  @observable
  class = undefined;

  /**
   * Версия объекта
   *
   * @type Number
   */
  @observable
  version = undefined;

  /**
   * Представление обхекта
   *
   * @type Array<Object>
   */
  @observable
  represantation = [];

  /**
   * Виды  объекта
   *
   * @type Array<Object>
   */
  @observable
  members = [];

  /**
   * Флаг, уазывающий, что происходит обработка represantation в предствлание объекта АИС
   *
   * @type Boolean
   */
  @observable
  isPending = false;

  /**
   * Объект АИС системы, полученный из represantation
   *
   * @type AisObject
   */
  @observable
  object = undefined;

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id объекта
   * @param {String} params.class class объекта
   * @param {Number} params.version версия объекта
   * @param {Array<Object>} params.representation представление объекта
   * @param {Array<Object>} params.members виды объекта
   *
   * @retrun {DataObjectItemModel}
   */
  static create({
    uid,
    class:klass,
    version,
    representation,
    fullObjectPath,
    members
  }, rootStore) {
    return new  DataObjectItemModel({
      uid,
      class: klass,
      version,
      representation,
      fullObjectPath,
      members
    }, rootStore);
  }

  constructor(params, rootStore) {
    this.uid = params.uid;
    this.class = params.class;
    this.domain = getDomainByClass(this.class);
    this.version = params.version;
    this.representation = params.representation || [];
    this.fullObjectPath = params.fullObjectPath;
    this.members = params.members || [];
    
    this.rootStore = rootStore;

    this.processRepresantation(params);
  }

  /**
   * id объекта
   * 
   * @returns {String}
   */
  @computed
  get id() {
    return this.uid;
  }

  /**
   * тип данных
   * 
   * @returns {String}
   */
  get dataType() {
    return "object";
  }

  /**
   * Строковое представление связи
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.object && this.object.title;
  }

  /**
   * Задать АИС объект
   * 
   * @param {Object} obj задаваемый объект
   */
  @action
  setObject(obj) {
    this.object = obj;
  }

  /**
   * Задать признак обработки данных
   * 
   * @param {Boolean} val значение признака обработки данных
   */
  @action
  setPending(val) {
    this.isPending = val;
  }

  @computed
  get membersData() {
    let members = [];
    if (this.members && this.members.length) {
      members = members.concat(this.members.map((member) => {
        if (member.kind && member.kind.uid) {
          const codeValues = {};
          const values = {};
          const dataObj = { 
            ...member, 
            kindUid:  member.kind.uid, 
            objectId: this.uid, 
            version:  this.version 
          };
          if (member.codeValues && member.codeValues.length) {
            member.codeValues.forEach((codeValue) => {
              if (codeValue.code && codeValue.code.uid) {
                codeValues[codeValue.code.uid] = codeValue.value;
              }
            });
          }
          if (member.values && member.values.length) {
            member.values.forEach((value) => {
              if (value.attribute && value.attribute.uid) {
                values[value.attribute.uid] = value.value;
              }
            });
          }
          dataObj.codeValues = codeValues;
          dataObj.values = values;
          return (dataObj);
        } else {
          return null;
        }
      }));
    }
    return members;
  }
  /**
   * Разбор представления(representation) объекта, полученного от Composer
   * 
   * @param {Boolean} val значение признака обработки данных
   */
  async processRepresantation(data) {
    this.setPending(true);
    try {
      const obj = await processComposerAisObject(data, this.rootStore.objectStore);
      this.setObject(obj);
    } catch (ex) {
      console.error(ex.message, { representation: this.representation, domain: this.domain, version: this.version });
    } finally {
      this.setPending(false);
    }
  }
}

export default DataObjectItemModel;
