import { observer } from "mobx-react";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef
} from "react";
// import useStores from "~/core/utils/useStores";
import Indicator from "../../../core/components/Indicator";

import InlinePicture from "./InlinePicture";
import TextItemIcon from "./TextItemIcon";

const Picture = observer(({ data, renderItem, setContextMenu }) => {
  const {
    uid,
    inlineItem,
    caption,
    scrollItemId,
    isFocusUid,
    diffClass,
    additionalClasses
  } = data;
  // const { layoutStore } = useStores();
  const captionRender = caption && renderItem(caption);

  const hasChunks = useMemo(() => {
    return !!(caption && caption.textId);
  }, [caption && caption.textId]);

  const onClickIcon = useCallback(() => {
    if (!hasChunks) {
      caption && caption.createOnlyChunk();
    } else {
      data.setEditing();
    }
    data.setFocusUid();
  }, [data, hasChunks]);

  const title = useMemo(() => {
    return caption && `${caption.prefix}${caption.number} ${caption.title}`; ;
  }, [caption && caption.prefix, caption && caption.number, caption && caption.title]);

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div id={uid} ref={element} className={`image element ${additionalClasses} ${diffClass}`}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <TextItemIcon setContextMenu={setContextMenu} onClick={onClickIcon} data={data} />
        <div className="image-body" onClick={onClickIcon}>
          <div 
            className="image-content"
          >
            {inlineItem && <InlinePicture data={inlineItem} title={title} />}
          </div>
          {caption && captionRender}
        </div>
      </div>
    </div>
  );
});

export default Picture;
