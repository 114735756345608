import React, { useMemo, useCallback, useState, useEffect } from "react";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import { Components, Field, Modal } from "@ais3p/ui-framework";
import "./css/login.scss";

const Login = observer(() => {
  const { accountStore, rootStore } = useStores();

  const { pending, isLoggedIn, isAutoLoginPending, login } = accountStore;
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [loginValue, setLoginValue] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [password, setPassword] = useState("");
  const [attemptLogin, setAttemptLogin] = useState(0);
  const [restore, setRestore] = useState(true);
  const pattern = /^(?!.*[А-Яа-яЁё])/;

  useEffect(() => {
    if (attemptLogin > 2) {
      rootStore.onError(
        "Забыли логин или пароль? Пожалуйста, свяжитесь с администратором.",
        false, 401);
    };
  }, [attemptLogin]);

  const onLoginSubmit = useCallback(async() => {
    accountStore.setError("", null);
    await accountStore.performLogin(loginValue, password, restore);
    if (accountStore.errorObj) {
      if (accountStore.errorObj.status === 401) {
        setErrorPass(accountStore.errorObj?.text);
        setIsValidLogin(false);
        setIsValidPassword(false);
        setAttemptLogin((prev) => {
          return prev + 1;
        });
      }
    }
  }, [accountStore, loginValue, password, restore]);

  const title = useMemo(() => {
    return isAutoLoginPending ? "Восстановление..." : "Выполните вход";
  }, [isAutoLoginPending]);

  const validatePassword = useCallback((value) => {
    const isValid = pattern.test(value);
    if (value === "") {
      setErrorPass("Введите пароль");
      setIsValidPassword(false);
      return false;
    } else if (isValid) {
      setIsValidPassword(true);
      setErrorPass("");
      return true;
    } else {
      setErrorPass("Пароль не должен содержать кириллицу");
      setIsValidPassword(false);
      return false;
    }
  }, [isValidLogin, loginValue, password]);

  const validateLogin = useCallback((value) => {
    const isValid = pattern.test(value);
    if (value === "") {
      setErrorLogin("Введите логин");
      setIsValidLogin(false);
      return false;
    } else if (isValid) {
      setIsValidLogin(true);
      setErrorLogin("");
      return true;
    } else {
      setErrorLogin(() => {
        return "Логин не должен содержать кириллицу";
      });
      setIsValidLogin(false);
      return false;
    }
  }, []);

  const handleChangePassword = useCallback((value) => {
    setErrorPass("");
    validateLogin(loginValue);
    validatePassword(value);
    setPassword(value);
  }, [loginValue, validateLogin, validatePassword]);

  const handleChangeLogin = useCallback((value) => {
    setErrorLogin("");
    validatePassword(password);
    validateLogin(value);
    setLoginValue(value);
  }, [password, validatePassword, validateLogin]);

  const content = useMemo(() => {
    if (!isAutoLoginPending) {
      return null;
    }
    return (
      <div className="login-modal-content">
        Сессия пользователя <b>{login}</b> будет восстановлена 
      </div>
    );
  }, [isAutoLoginPending]);

  const modalButton = (
    <Components.Button
      text="Войти"
      icon="login-M"
      onPress={onLoginSubmit}
      isLoading={pending}
      color="action"
      isDisabled={!isValidLogin || !isValidPassword}
    />
  );
  return (
    <Modal.Window
      name="login"
      icon="plane-M"
      show={!isLoggedIn}
      title={title}
      buttons={modalButton}
      onKeyPressEnter={isValidLogin && isValidPassword && onLoginSubmit}
    >
      {content ? (
        content
      ) : (
        <>
          <div className="login-container">
            <Field.String
              icon=""
              label="Логин"
              name="login"
              onChange={handleChangeLogin}
              value={loginValue}
              autoFocus={true}
              theme="dark"
              hint={errorLogin}
              isValid={isValidLogin}
            />
            <Field.String
              icon=""
              label="Пароль"
              name="password"
              typeOfInput="password"
              onChange={handleChangePassword}
              value={password}
              theme="dark"
              isValid={isValidPassword}
              hint={errorPass}
            />
          </div>
          <Components.Tooltip content="Отключите эту опцию, если хотите начать с новым рабочим пространством">
            <div className="login-workspace">
              <Field.Boolean 
                onChange={setRestore}
                value={restore}
                name="boolean"
                label="Войти с сохраненным рабочим пространством"
              />
            </div>
          </Components.Tooltip>
        </>
      )}
    </Modal.Window>
  );
});

export default Login;
