import React, { useCallback, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";

import { Layout, ContextMenu, Notification } from "@ais3p/ui-framework";

import { Login, WorkspaceForm } from "~/modules/account";
import AppHeader from "~/core/components/AppHeader";
import Tooltip from "~/core/components/Tooltip";

import "~/App.scss";
import LibraryTool from "~/modules/library";
import Viewer from "~/modules/viewer";
import RepoTool from "~/modules/repo";
import About from "~/modules/about/components/About";
import Material from "~/modules/newText";

import TraceAnalyzerTool from "~/modules/traceReporter/tools/TraceAnalyzer";

import ConnectionSidepanel from "~/modules/relations/tools/ConnectionSidepanel";
import KindsSidepanel from "~/modules/kindsAndAttrs/tools/KindsSidepanel";

import IssuesTool from "~/modules/issues/tools/IssuesTool";
import WebSocketTool from "~/modules/webSocket/tools/WebSocketTool";
import SearchEntitiesTool from "~/modules/searchEntities";
import CheckListToolTool from "~/modules/checkList";

import JournalSidepanel from "~/modules/journal/tools/JournalSidepanel";

import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL,
  SIDEPANEL_VALIDATION,
  SIDEPANEL_WORKFLOW
} from "~/core/constants/SidePanels";
import { CTX_MENU_AIS_OBJECT_LINK } from "~/core/constants/ContextMenu";
import ValidationSidepanel from "../../modules/validation";
import WorkflowSidePanel from "../../modules/workflow/tools/WorkflowSidePanel";
import TitleRender from "../../modules/library/components/TitleRender";
import { useStores, truncateString, copyTextToClipboard } from "../utils";
import getPropsToOpenLayoutTool from "../utils/layoutHelpers";
import { parseUrlSearchParams, generateAisObjectUrl } from "../utils/aisHelpers";

const toolComponents = {
  library:                 <LibraryTool />,
  text:                    <Material />,
  viewer:                  <Viewer />,
  sourcecode:              <RepoTool />,
  tasks:                   <IssuesTool />,
  websocket:               <WebSocketTool />,
  traceAnalyzer:           <TraceAnalyzerTool />,
  searchEntities:          <SearchEntitiesTool />,
  checkList:               <CheckListToolTool />,
  [SIDEPANEL_RELATIONS]:   <ConnectionSidepanel />,
  [SIDEPANEL_KINDS_ATTRS]: <KindsSidepanel />,
  [SIDEPANEL_JOURNAL]:     <JournalSidepanel />,
  [SIDEPANEL_VALIDATION]:  <ValidationSidepanel />,
  [SIDEPANEL_WORKFLOW]:    <WorkflowSidePanel />
};

const toolIcons = {
  tasks:                   "app-spzi-M",
  websocket:               "arrow-rightleft-M",
  library:                 "app-library-M",
  text:                    "app-texteditor-M",
  viewer:                  "wmaterial-M",
  sourcecode:              "app-tree-M",
  viewer3d:                "object-M",
  traceAnalyzer:           "tracer-gap-analyser-M",
  searchEntities:          "search-M",
  checkList:               "domain-M",
  [SIDEPANEL_RELATIONS]:   "app-relations-M",
  [SIDEPANEL_KINDS_ATTRS]: "app-attributes-M",
  [SIDEPANEL_JOURNAL]:     "log-M",
  [SIDEPANEL_VALIDATION]:  "ok-M",
  [SIDEPANEL_WORKFLOW]:    "app-workflow-M"
};

const toolNames = {
  tasks:                   "Задачи",
  websocket:               "Web Socket",
  library:                 "Библиотека",
  text:                    "Редактор текста",
  viewer:                  "Просмотр",
  sourcecode:              "Репозитории",
  viewer3d:                "Просмотр 3Д",
  traceAnalyzer:           "Анализ связей",
  searchEntities:          "Поиск артефактов",
  checkList:               "DQ Check list",
  [SIDEPANEL_RELATIONS]:   "Отслеживание связей",
  [SIDEPANEL_KINDS_ATTRS]: "Виды и атрибуты",
  [SIDEPANEL_JOURNAL]:     "Журнал изменений",
  [SIDEPANEL_VALIDATION]:  "Утверждение",
  [SIDEPANEL_WORKFLOW]:    "Жизненные циклы"
};

const modeButtonsDummie = [
  {
    id:            "common.Workspaces",
    icon:          "table-divide-M",
    type:          "drop",
    labelPosition: "top",
    item:          {
      name: "Действия с рабочим пространством",
      id:   "layout"
    },
    children: []
  }
];
const buttonsDummie = [
  { type: "spacer", id: "s1" },
  {
    id:   "library.Tree",
    icon: "app-library-M",
    item: {
      name:      "Библиотека",
      id:        uuid(), // id root библиотеки
      props:     {},
      icon:      "app-library-M",
      component: "library"
    }
  },
  { type: "spacer", id: "s2" },
  {
    id:   "websocket.Tracker",
    icon: "arrow-rightleft-M",
    item: {
      name:      "WebSocket",
      id:        uuid(),
      props:     {},
      icon:      "arrow-rightleft-M",
      component: "websocket"
    }
  },
  {
    id:   "relations.TraceAnalyzer",
    icon: "tracer-gap-analyser-M",
    item: {
      name:      "Анализ связей",
      id:        uuid(),
      props:     {},
      icon:      "tracer-gap-analyser-M",
      component: "traceAnalyzer"
    }
  },
  {
    id:   "search.SearchEntities",
    icon: "search-M",
    item: {
      name:      "Поиск артефактов",
      id:        uuid(),
      props:     {},
      icon:      "search-M",
      component: "searchEntities"
    }
  }
];

/**
 * Контейнер приложения АИС ППП
 * 
 * @param {Boolean} hasError признак наличия системной ошибки
 */
const AppContainer = observer(({ hasError, menuCollect }) => {
  const { accountStore, uiStore, objectStore } = useStores();
  const { showConfirm } = uiStore;

  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [layoutStore, setLayoutStore] = useState(null);

  
  const onSaveLayout = useCallback(() => {
    if (layoutStore) {
      const presset = layoutStore.getPresset();
      accountStore.showForm(false, presset);
    }
  }, [layoutStore]);

  const onMountLayout = useCallback((layoutStore) => {
    setLayoutStore(layoutStore);
    const urlParams = parseUrlSearchParams(window.location.search);
    if (urlParams && Object.keys(urlParams).length > 0) {
      try {
        // const { id, name, props, component } = urlParams;
        // if (!!id && !!name && !!props && !!component) {
        //   layoutStore.open(urlParams);
        // }
        const { uid, domain, version = 0 } = urlParams;
        if (!!uid && !!domain) {
          goToObject(uid, domain, version, layoutStore);
        }
      } catch (ex) {
        console.error(ex);
      }
    }
  }, []);

  const goToObject = async(uid, domain, version, layoutStore) => {
    try {
      const obj = await objectStore.fetchRepresentation(uid, domain, version);
      if (obj) {
        const props = await getPropsToOpenLayoutTool(obj, objectStore);
        props && layoutStore.open(props);
      }
    } catch (ex) {
      console.error(ex);
      Notification.warning("Не могу перейти к объекту! Некорректная внешняя ссылка.", 
        { autoClose: 3000 });
    }
  };

  const savePresset = useCallback((data, name) => {
    accountStore.savePresset(data, name);
  }, []);

  const onShowAbout = useCallback((isVisible) => {
    setIsAboutVisible(isVisible);
  }, []);

  const onMenuItemClick = useCallback(async(action, context) => {
    const { object } = context;

    if (action === "url" && object) {
      const url = await generateAisObjectUrl(object, objectStore);
      if (url) {
        copyTextToClipboard(url.href, 
          () => {
            Notification.success(
              `URL ссылка на объект "${truncateString(object.title, 32)}" скопирована в буфер обмена`, 
              { autoClose: 1000 });
          }, 
          (err) => {
            Notification.warning(
              `URL ссылка на объект "${truncateString(object.title, 32)}" не скопирована в буфер обмена: ${err}`, 
              { autoClose: 2000 });
          });
      }
    }
  }, [objectStore]);

  const {
    isLoggedIn,
    layoutPresset,
    loadingPresset,
    pendingWorkspaces,
    workspacesArray,
    permissions
  } = accountStore;

  const allowedTools = permissions.get("tool");
  const allowedObjects = permissions.get("object");

  const onClickWS = useCallback((item) => {
    if (item && item.id) {
      accountStore.loadWSbyId(item.id);
    }
  }, [accountStore]);

  const modeButtons = useMemo(() => {
    const res = [];
    if (!allowedTools || !allowedObjects) {
      return res;
    }

    modeButtonsDummie.forEach((button) => {
      if (button.type === "spacer") {
        res.push(button);
      } else {
        const toolPerms = allowedTools.get(button.id);
        if (toolPerms && toolPerms.has("use")) {
          const buttonObj = { ...button };
          if (button.id === "common.Workspaces") {
            const wsChildren = [];
            const wsPerms = allowedObjects.get("common.Workspace");
            if (wsPerms && wsPerms.has("read")) {
              workspacesArray.forEach((ws) => {
                wsChildren.push({ ...ws.buttonConfig, ws, onClick: onClickWS });
              });
            }
            if (wsPerms && wsPerms.has("create")) {
              wsChildren.push({
                id:            "common.Workspaces.edit",
                icon:          "save-M",
                labelPosition: "top",
                loading:       pendingWorkspaces,
                item:          {
                  fixed: true,
                  name:  "Сохранить текущее рабочее пространство",
                  id:    "saveLayout"
                },
                onClick: onSaveLayout
              });
            }
            buttonObj.children = wsChildren;
          }

          res.push(buttonObj);
          if (button.id === "common.Workspaces") {
            res.push({ type: "spacer", id: `mb${button.id}` });
          }
        }
      }
    });
    
    return res;
  }, [modeButtonsDummie, allowedTools, allowedObjects, onSaveLayout, workspacesArray, onClickWS]);

  const buttons = useMemo(() => {
    const res = [];
    if (!allowedTools) {
      return res;
    }
    buttonsDummie.forEach((button) => {
      if (button.type === "spacer") {
        res.push(button);
      } else {
        if (allowedTools.has(button.id)) {
          const buttonObj = { ...button };
          res.push(buttonObj);
        }
        if (button.id === "repo.Viewer") {
          const buttonObj = { ...button };
          res.push(buttonObj);
        }
        if (button.id === "websocket.Tracker") {
          const buttonObj = { ...button };
          res.push(buttonObj);
        }
        if (button.id === "relations.TraceAnalyzer") {
          const buttonObj = { ...button };
          res.push(buttonObj);
        }
        if (button.id === "search.SearchEntities") {
          const buttonObj = { ...button };
          res.push(buttonObj);
        }
      }
    });
    return res;
  }, [buttonsDummie, allowedTools]);

  const header = useMemo(() => {
    return (
      <AppHeader
        onShowAbout={onShowAbout}
        menuCollect={menuCollect}
        buttons={buttons}
        modeButtons={modeButtons}
      />
    );
  }, [onShowAbout, menuCollect, buttons, modeButtons]);


  const uid = useMemo(() => {
    return isLoggedIn ? accountStore.uid : undefined;
  }, [isLoggedIn, accountStore.uid]);
  
  const renderTabTitle = useCallback((props) => {
    return <TitleRender {...props} />;
  }, []);

  return (
    <div
      id="ais-app-root"
      className={`app-container ${!isLoggedIn ? "login" : ""} ${hasError ? "error" : ""
      }  ${showConfirm ? "confirm" : ""}`}
    >
      {isLoggedIn && !loadingPresset && (
        <Layout
          header={header}
          savePresset={savePresset}
          renderTabTitle={renderTabTitle}
          presset={layoutPresset}
          persistId={uid}
          menuCollect={menuCollect}
          onMount={onMountLayout}
          panels={[]}
          components={toolComponents}
          toolIcons={toolIcons}
          toolNames={toolNames}
          multipleTabs={true}
          blocks={[
            {
              id:        "root",
              direction: "row",
              isRoot:    true
            }
          ]}
        />
      )}
      <WorkspaceForm />
      <Login />
      <About isVisible={isAboutVisible} onShow={onShowAbout} />
      <Tooltip delay={{ show: 100, hide: 100 }} />
      <div className="error-overlay" />
      <ContextMenu.Menu
        id={CTX_MENU_AIS_OBJECT_LINK} 
        items={[
          {
            icon:   "copy-M",
            type:   "copy",
            title:  "Скопировать ссылку",
            action: "url"
          }]} 
        onMenuClick={onMenuItemClick}
      />
    </div>
  );
});

export default AppContainer;
