import { domainExists, DOMAIN_BINDER, DOMAIN_KINDS, DOMAIN_ISSUE } from "../constants/Domains";
import { CLS_KIND } from "../constants/Classes";

/**
 * Получить название домена по классу объекта
 * 
 * @param {String} cls название класса
 * @returns {String}
 */
const getDomainByClass = (cls = "") => {
  if (cls === "ais3p.binder.link") {
    // временное решение, тк в БД  уже есть записи с таким классом
    return DOMAIN_BINDER;
  }

  if (cls === CLS_KIND) {
    // временное решение, тк в БД  уже есть записи с таким классом
    return DOMAIN_KINDS;
  }

  if (cls === "issues.Issue") {
    // временное решение, тк в БД  уже есть записи с таким классом
    return DOMAIN_ISSUE;
  }

  const domain = (cls.split(".")[0] || "").toLowerCase();

  if (domainExists(domain)) {
    return domain;
  }

  return undefined;
}; 

export default getDomainByClass;
