import { observable, action, computed, toJS } from "mobx";

import AttrValue from "./AttrValue";

/**
 * Модель участника Вида
 * Здесь хранится информация: тип Вида (kindUid), представление самого объекта (objectId),
 * выставленные значения у атрибутов (values) и выставленные значения кодов (codeValues)
 */
class KindMember {
  /**
   * Признак обрабатывания запросов к сервисам
   * 
   * @type {Boolean}
   */
  @observable 
  pending = false;

  /**
   * Уникальный идентификатор участника Вида
   * 
   * @type {String}
   */
  @observable 
  uid = null;

  /**
   * Уникальный идентификатор Вида, к которому принадлежит участник
   * 
   * @type {String}
   */
  @observable 
  kindUid = null;

  /**
   * Уникальный идентификатор объекта в АИС
   * 
   * @type {String}
   */
  @observable 
  objectId = null;

  /**
   * Набор кодов
   * 
   * @type {Map}
   */
  @observable 
  codes = new Map();

  /**
   * Набор атрибутов
   * 
   * @type {Map}
   */
  @observable 
  attributes = new Map();

  /**
   * Представитель Вида
   * 
   * @type {KindItem}
   */
  @observable 
  item = null;

  
  /**
   * Конструктор 
   * 
   * @param {Object} data набор данных для создания модели предтавителя Вида
   * @param {KindStore} store хранилище для работы с Видами и Атрибутами
   * @param {KindItem} item представитель Вида
   */
  constructor(data, store, item) {
    this.store = store;
    this.api = store.api;

    this.uid = data.uid;
    this.kindUid = data.kindUid;
    this.objectId = data.objectId;
    this.item = item;
    
    if (this.kind) {
      this.kind.attributes.forEach((attribute) => {
        this.attributes.set(
          attribute.id,
          new AttrValue({ id: attribute.id, type: attribute.type }, this.store)
        );
      });
    }

    if (data.values) {
      Object.keys(data.values).forEach((key) => {
        const value = data.values[key];
        const attribute = this.store.getAttr(key);
        if (attribute) {
          this.attributes.set(
            key,
            new AttrValue({ value, id: key, type: attribute.type }, this.store)
          );
        }
      });
    }

    if (data.codeValues) {
      this.codes.replace(data.codeValues);
    }
  }

  /**
   * Вернуть uid участника Вида
   */
  @computed
  get id() {
    return this.uid;
  }

  /**
   * Задать id объекта АИС
   * 
   * @param {String} id объекта АИС
   */
  @action
  setObjectId(id) {
    this.objectId = id;
  }

  /**
   * Задать uid представителя Вида
   * Вызхывается после метода создания на сервисе
   * 
   * @param {String} uid новый uid представителя Вида
   */
  @action
  setUid(uid) {
    this.uid = uid;
  }

  /**
   * Задать признак обработки запросов к сервисам
   * 
   * @param {Boolean} value значение признака true|false
   */
  @action
  setPending(value) {
    this.pending = value;
  }

  /**
   * Удалить участника вида
   * 
   * @returns {Promise}
   */
  @action
  async delete() {
    this.item.removeMember(this.uid);
    await this.item.save();
  }

  /**
   * Отменить изменения у атрибутов 
   */
  @action
  revert() {
    if (this.needsPersist) {
      this.attributes.forEach((attr) => {
        attr.revert();
      });
    }
  }

  /**
   * Сохранить изменения у атрибутов 
   * 
   * @returns {Promise<Object>}
   */
  @action
  async save() {
    if (!this.needsPersist) {
      return;
    }

    let data = null;
    this.setPending(true);
    try {
      if (this.hasUid) {
        data = await this.update();
      } else {
        data = await this.create();
      }
      this.attributes.forEach((attr) => {
        attr.save();
      });
    } finally {
      this.setPending(false);
    }
    return data;
  }

  /**
   * Метод для обновления значения атрибутов на сервере
   * 
   * @returns {Promise<Object>}
   */
  @action
  async update() {
    const attributes = {};
    this.attributes.forEach((attr) => {
      attributes[attr.id] = attr.valueToSend;
    });
    const data = await this.api.changeAttributes(this.uid, { values: attributes });
    return data;
  }

  /**
   * Метод для создания нового представителя Вида на сервере
   * 
   * @returns {Promise<Object>}
   */
  @action
  async create() {
    const attributes = {};
    this.attributes.forEach((attr) => {
      attributes[attr.id] = attr.valueToSend;
    });

    const members = {
      objectId: this.objectId,
      values:   attributes
    };
    const data = await this.api.addKindMember(this.kindId, members);
    if (data && data.uid) {
      this.setUid(data.uid);
    }
    return data;
  }

  /**
   * Задать значение у атрибута
   * 
   * @param {String} id атрибута
   * @param {Any} value значение атриубута
   * @param {Boolean} isValid признак, что значение атрибута валидно
   */
  @action
  setAttrValue(id, value, isValid) {
    const attr = this.attributes.get(id);
    if (attr) {
      attr.change(value, isValid);
    } else {
      const attribute = this.store.getAttr(id);
      this.attributes.set(
        id,
        new AttrValue({ id, value, type: attribute.type, isValid }, this.store)
      );
    }
  }

  /**
   * Получить значние атрибута
   * 
   * @param {String} id идентификатор атрибута
   * 
   * @returns {Any}
   */
  getAttrValue(id) {
    const attr = this.attributes.get(id);
    if (!attr) {
      return undefined;
    }
    return attr.value;
  }

  /**
   * Получить Вид
   * 
   * @returns {Kind}
   */
  @computed
  get kind() {
    if (this.store.isPending) {
      return null;
    }
    return this.store.getKind(this.kindId);
  }

  /**
   * Получить набор доcтупных типов
   * 
   * @returns {Set<Object>}
   */
  @computed
  get allowedTypes() {
    return this.kind ? new Set(toJS(this.kind.allowedTypes)) : null;
  }

  /**
   * Получить набор доcтупных Видов
   * 
   * @returns {Set<Object>}
   */
  @computed
  get allowedKinds() {
    return this.kind ? new Set(toJS(this.kind.allowedKinds)) : null;
  }

  /**
   * Получить набор доcтупных Видов для Задач
   * 
   * @returns {Set<Object>}
   */
  @computed
  get allowedTasks() {
    return this.kind ? new Set(toJS(this.kind.allowedTasks)) : null;
  }

  /**
   * Признак наличия uid у представителя вида
   * Если uid нет, значит представитель только создается
   * 
   * @returns {Boolean}
   */
  @computed
  get hasUid() {
    return !!this.uid;
  }

  /**
   * Признак необходимости сделать сохранение/создания представителя вида
   * Те признак того, нужно ли делать запрос на сервис
   * 
   * @returns {Boolean}
   */
  @computed
  get needsPersist() {
    return !this.hasUid || this.edited;
  }

  /**
   * Признак сделанных каких-либо изменений в атрибутах
   * 
   * @returns {Boolean}
   */
  @computed
  get edited() {
    let edited = false;
    this.attributes.forEach((attr) => {
      edited = edited || attr.isEdited;
    });
    return edited;
  }

  /**
   * Id Вида
   * 
   * @returns {String}
   */
  @computed
  get kindId() {
    return this.kindUid;
  }

  /**
   * Название Вида
   * 
   * @returns {String}
   */
  @computed
  get kindName() {
    return this.kind && this.kind.name;
  }

  /**
   * Иконка Вида
   * 
   * @returns {String}
   */
  @computed
  get iconString() {
    return (this.kindName && this.store.rootStore.accountStore.getIcon(this.kindUid)) || null;
  }

  /**
   * Коды объекта
   * 
   * @returns {Object}
   */
  @computed
  get codesObject() {
    const object = {};
    this.codes.forEach((value, key) => {
      object[key] = value;
    });
    return object;
  }

  /**
   * Признак, что все атрибуты валидны
   * 
   * @returns {Boolean}
   */
  @computed
  get isValid() {
    let valid = true;
    this.attributes.forEach((attr) => {
      if (attr.isValid === false) {
        valid = false;
      }
    });
    return valid;
  }

  /**
   * Вернуть все значения атрибутов в формате {attributeId: Значение}
   * 
   * @returns {Object}
   */
  @computed
  get allValues() {
    const vals = {};
    this.attributes.forEach((attr) => {
      vals[attr.id] = attr.value;
    });
    return vals;
  }

  /**
   * Вернуть все значения инициализации атрибутов в формате {attributeId: Значение}
   * 
   * @returns {Object}
   */
  @computed
  get initValues() {
    const vals = {};
    this.attributes.forEach((attr) => {
      vals[attr.id] = attr.initialValue;
    });
    return vals;
  }
}

export default KindMember;
