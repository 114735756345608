import { observable, computed, action } from "mobx";

import processComposerAisObject from "~/core/utils/aisHelpers";
import MemberModel from "./MemberModel";

/**
 * Модель найденной сущности АИС через запрос к Composer
 */
export default class EntityModel {
  /**
   * @type {String}
   * Уникальный идентификатор объекта АИС
   */
  @observable
  uid = undefined;

  /**
   * @type {String}
   * Class объекта АИС
   */
  @observable
  class = undefined;

  /**
   * @type {Number}
   * Версия объекта АИС
   */
  @observable
  version = undefined;

  /**
   * @type {Object}
   * Представление объекта АИС
   */
  @observable
  representation = undefined;

  /**
   * @type {Map<MemberModel>}
   * Представление объекта АИС
   */
  @observable
  members = new Map();

  /**
   * @type {AisVersion}
   * Объект АИС
   */
  @observable
  object = undefined;
  
  static create({ uid, id, class: klass, version, members, representation, fullObjectPath }, store) {
    return new  EntityModel({ uid: uid || id, class: klass, version, members, representation, fullObjectPath }, store);
  }

  constructor(data, store) {
    this.store = store;
    
    this.uid = data.uid;
    this.class = data.class;
    this.version = data.version;

    if (Array.isArray(data.members)) {
      data.members.forEach((memberData) => {
        this.addMember(MemberModel.create(memberData, store));
      });
    }

    this.initObject(data);
  }


  async initObject(data) {
    if (!data) {
      return;
    }

    const obj = await processComposerAisObject(data, this.store.rootStore.objectStore);
    this.setObject(obj);
  }

  /**
   * Получить название участника Вида
   * 
   * @returns {String} название участника Вида
   */
  @computed
  get title() {
    return  this.object ?  this.object.title : this.class;
  }

  /**
   * Задать объект АИС участнику Вида
   * 
   * @param {AisVersion} obj 
   */
  @action
  setObject(obj) {
    this.object = obj;
  }

  /**
   * Добавить участника Вида
   * 
   * @param {MemberModel} member участник Вида
   */
  @action
  addMember(member) {
    this.members.set(member.kindUid, member);
  }

  getMember(kindUid) {
    return this.members.get(kindUid);
  }
}
