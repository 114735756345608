// класс для расширения Error
class CustomError extends Error {
  constructor(message, status, traceId, detail) {
    const fullMessage = 
      `${message}${status ? `\nКод ошибки: ${status}` : ""}${traceId ? `\nНомер трассировки: ${traceId}` : ""}`;
    super(fullMessage);
    this.status = status;
    this.traceId = traceId;
    this.detail = detail;
  }
}
export default CustomError;
