import { action, computed } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class List extends BaseBoxClass {
  @action
  async persistCreate() {
    await super.persistCreate();
    await this.createOnlyItem();
  }

  @action
  async createOnlyItem() {
    if (!this.store) {
      return null;
    }
    if (this.idsArray.length < 1) {
      const item = this.store.createItem(
        {
          class: "text.container.EnumerationItem",
          uid:   this.store.getUid(),
          isNew: true
        },
        this
      );
      this.addItemId(item.uid);
    }
  }

  @action
  async checkForEmptinessAndRemove() {
    if (!this.idsArray.length) {
      await this.persistDelete();
      this.selfDestruct(true);
    }
  }

  @computed
  get title() {
    let title = " ";
    this.items.forEach((item) => {
      if (item) {
        title += item.title;
      }
    });
    return title;
  }

  @computed
  get slug() {
    return "enumerations";
  }

  @computed
  get category() {
    return "elements";
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [
      {
        class:               "text.container.EnumerationItem",
        icon:                "data-enum-single-M",
        level:               this.childListLevel,
        isParent:            true,
        ancorId:             this.uid,
        availableKindsArray: [{ kind: "type-only", title: "", icon: null }]
      }
    ];
    return array;
  }

  @computed
  get hierarchyItemsArray() {
    if (!this.isLast) {
      return [];
    }
    
    return [].concat(
      this.parent.hierarchyItemsArray,
      this.parent.innerHierarchyItemsArray
    );
  }

  @computed
  get removedFirstChildListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (
      this.diffCompatitor.items && 
      this.diffCompatitor.items.length > 0 && 
      this.diffCompatitor.items[0].diffClass === "removed"
    ) {
      return [this.diffCompatitor.items[0], ...this.diffCompatitor.items[0].removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get itemsForRender() {
    let data = [...this.removedFirstChildListItemsArray];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data = data.concat([item, ...item.removedDescendantListItemsArray]);
    });
    return data;
  }

  @computed
  get firstItem() {
    if (!this.idsArray.length) {
      return null;
    }
    const id = this.idsArray[0];
    const item = this.getItemById(id);
    return item || null;
  }

  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      elements:    [],
      "@position": this.position
    };
  }

  @computed
  get flatItemsArray() {
    const data = [this];
    const childrenArray = [];

    if (this.removedFirstChildItemsArray && this.removedFirstChildItemsArray.length) {
      this.removedFirstChildItemsArray.forEach((item) => {
        if (item) {
          childrenArray.push(item);
        }
      });
    }
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      item.flatItemsArray && item.flatItemsArray.forEach((item) => {
        if (item) {
          childrenArray.push(item);
        }
      });
    });
    if (!this.store.isPending && !this.store.isDiffPending && this.removedDescendantItemsArray.length > 0) {
      this.removedDescendantItemsArray.forEach((item) => {
        if (item) {
          childrenArray.push(item);
        }
      });
    }
    childrenArray.shift();
    return data.concat(childrenArray);
  }

  @computed
  get childListLevel() {
    return 1 + (this.parent.childListLevel || 0);
  }

  @computed
  get availableItemsArray() {
    if (!this.parent) {
      return [];
    }
    if (this.isLocked && !this.isLockedByMe) {
      return [].concat(
        this.parent.hierarchyItemsArray,
      );
    }
    if (this.isParentLocked && !this.isParentLockedByMe) {
      return [];
    }
    if (this.isLast) {
      return this.hierarchyItemsArray.concat(
        this.innerHierarchyItemsArray,
        this.parentDefaultItemsArray
      );
    }
    return this.innerHierarchyItemsArray.concat(this.parentDefaultItemsArray);
  }
}

export default List;
