import { action, observable, runInAction, computed } from "mobx";

import Api from "../api/usersApi";
import User from "../models/User";
import Group from "../models/Group";

const arrayDiff = function(a, b) {
  return a.filter((i) => {
    return b.indexOf(i) < 0;
  });
};
/**
 * Глобальное хранилище пользователей.
 */
export default class UserStore {
  @observable rootStore = false;
  @observable userFormIsVisible = false;
  @observable groupFormIsVisible = false;
  @observable passFormIsVisible = false;
  @observable chooseItemIsVisible = false;

  @observable isPending = false;
  @observable users = new Map(); // список пользователей
  @observable groups = new Map(); // список пользователских групп
  @observable selectedType = "user";
  @observable selectedId;
  @observable systemUid; // uid системы

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.api = new Api(rootStore);
  }

  @computed
  get list() {
    return Array.from(this.users.values());
  }

  @computed
  get currentId() {
    return this.selectedId || null;
  }

  @computed
  get currentItem() {
    return this.getItem(this.currentId);
  }

  @computed
  get type() {
    return this.selectedType || null;
  }

  @computed
  get groupList() {
    return Array.from(this.groups.values());
  }

  @computed
  get chooserItemsArray() {
    if (!this.currentItem) {
      return [];
    }
    if (this.currentItem.type === "user") {
      return arrayDiff(
        Array.from(this.groups.values()),
        this.currentItem.groupArray
      );
    } else if (this.currentItem.type === "group") {
      return arrayDiff(
        Array.from(this.users.values()),
        this.currentItem.userArray
      );
    }
    return [];
  }

  @action
  showChooser() {
    this.chooseItemIsVisible = true;
  }

  @action
  changePass(uid) {
    this.passFormIsVisible = uid;
  }

  @action
  async changePassword(data) {
    if (this.passFormIsVisible) {
      await this.api.updatePass(this.passFormIsVisible, data);
      this.hideForm();
    }
  }

  @action
  async init() {
    this.isPending = true;
    try {
      await this.loadUsers(true);
      await this.loadGroups();
    } catch (e) {
      this.onError(e);
    } finally {
      runInAction(() => {
        this.isPending = false;
      });
    }
  }

  /**
   * Получить пользователя
   *
   * @param  {String} uid пользователя
   */
  @action
  get(uid) {
    return this.users.get(uid);
  }

  @action
  chooseItem(uid) {
    const withRequest = true;
    if (this.currentItem.type === "user") {
      this.currentItem.insertGroup(uid, withRequest);
    } else if (this.currentItem.type === "group") {
      this.currentItem.insertUser(uid, withRequest);
    }
  }

  @action
  async createUser(data) {
    const userData = await this.api.createUser(data);
    this.addUser(userData);
    this.hideForm();
  }

  @action
  async createGroup(data) {
    const groupData = await this.api.createGroup(data);
    this.addGroup(groupData);
    this.hideForm();
  }

  @action
  delete(uid) {
    if (!this.currentItem) {
      if (this.type === "user") {
        this.deleteUser(uid);
      } else if (this.type === "group") {
        this.deleteGroup(uid);
      }
    } else {
      const withRequest = true;
      if (this.type === "user") {
        this.currentItem.removeGroup(uid, withRequest);
      } else if (this.type === "group") {
        this.currentItem.removeUser(uid, withRequest);
      }
    }
  }

  @action
  async deleteUser(uid) {
    await this.api.deleteUser(uid);
    this.removeUser(uid);
  }

  @action
  async deleteGroup(uid) {
    await this.api.deleteGroup(uid);
    this.removeGroup(uid);
  }

  @action
  showForm() {
    if (this.type === "user") {
      this.userFormIsVisible = true;
    } else if (this.type === "group") {
      this.groupFormIsVisible = true;
    }
  }

  @action
  hideForm() {
    this.userFormIsVisible = false;
    this.groupFormIsVisible = false;
    this.chooseItemIsVisible = false;
    this.passFormIsVisible = false;
  }

  /**
   * Выбрать user/group
   *
   * @param  {String} uid пользователя
   */
  @action
  setItem(uid) {
    this.setId(uid);
    const item = this.getItem(uid);
    if (item) {
      this.setType(item.type);
    }
  }

  /**
   * Выбрать user/group
   *
   * @param  {String} uid пользователя
   */
  @action
  setId(uid) {
    this.selectedId = uid;
  }

  /**
   * Выбрать user/group
   *
   * @param  {String} uid пользователя
   */
  @action
  setType(type) {
    this.selectedType = type;
  }

  /**
   * Получить группу
   *
   * @param  {String} uid группы
   */
  @action
  getGroup(uid) {
    return this.groups.get(uid);
  }

  /**
   * Получить item
   *
   * @param  {String} uid группы
   */
  @action
  getItem(uid) {
    return this.get(uid) || this.getGroup(uid);
  }

  /**
   * Загрузить список пользователей
   *
   * @param{Boolean} withGroups загрузить пользователей со списком групп, которым они принадлежат
   *
   * @return {Promise}
   */
  @action
  async loadUsers(withGroups = false) {
    try {
      const data = await this.api.loadUsers(withGroups);
      (data || []).forEach((item) => {
        this.addUser(item);
      });
      return data;
    } catch (ex) {
      this.onError(ex);
    }
  }

  /**
   * Загрузить список групп
   *
   * @return {Promise}
   */
  @action
  async loadGroups(withMembers = false) {
    try {
      const data = await this.api.loadGroups(withMembers);
      const groups = [];
      (data || []).forEach((item) => {
        groups.push(this.addGroup(item));
      });
      await Promise.all(groups);
      return data;
    } catch (ex) {
      this.onError(ex);
    }
  }

  /**
   * Добавить пользователя
   *
   * @param  {Object} props данные пользователя
   * @return {User}
   */
  @action
  addUser(props) {
    const u = User.create(props || {}, this);

    this.users.set(u.uid, u);

    const { groups } = props;
    if (groups && Array.isArray(groups)) {
      groups.forEach((gr) => {
        u.addGroup(Group.create(gr, this));
      });
    }
    return u;
  }

  /**
   * Добавить группу
   *
   * @param  {Object} props данные группы
   * @return {Group}
   */
  @action
  async addGroup(props) {
    const gr = Group.create(props || {}, this);

    const { members } = props;
    if (members && Array.isArray(members)) {
      members.forEach((u) => {
        gr.addUser(User.create(u, this));
      });
    }
    this.groups.set(gr.uid, gr);
    return gr;
  }

  /**
   * Обновить свойства пользователя
   *
   * @param  {String} uid пользователя
   * @param  {Object} props свойства пользователя
   * @return {User}
   */
  @action
  updateUser(uid, props) {
    const user = this.get(uid);
    if (user) {
      user.update(props);
    }
  }

  /**
   * Удалить пользователя
   *
   * @param  {String} uid пользователя
   */
  @action
  removeUser(uid) {
    this.users.delete(uid);
  }

  /**
   * Удалить группу
   *
   * @param  {String} uid группы
   */
  @action
  removeGroup(uid) {
    this.groups.delete(uid);
  }

  /**
   * Очистить список
   *
   */
  @action
  clear() {
    this.users.clear();
    this.groups.clear();
  }

  @action
  onError(error) {
    this.rootStore.onError(error);
  }

  destroy() {
    this.clear();
  }
}
