import React, { useMemo } from "react";
import { observer } from "mobx-react";
import AisIcon from "./AisIcon";
import useStores from "../utils/useStores";
import { getDomainByClass } from "../utils";
import { DOMAIN_LIBRARY, DOMAIN_REPO } from "../constants/Domains";
import { CLS_REPO_DIR, CLS_REPO_FILE, CLS_REPO_FILE_FUNCTION } from "../constants/Classes";
import { getFileIcon } from "~/modules/repo/utils";

const AisObjectTooltip = observer(({ object, canGoToObject }) => {
  const { fullObjectPath = [] } = object;
  const { accountStore } = useStores();

  if (!fullObjectPath || (fullObjectPath && fullObjectPath.length === 0)) {
    return (
      <div className="ais-object-tooltip">
        <div className="ais-object-tooltip-empty">
          Нет данных об  исходном объекте
        </div>
        {canGoToObject && 
        <div className="ais-object-tooltip-go">
          Кликните по объекту, чтобы перейти к его расположению
        </div>
        }
      </div>
    );
  }
  const objectPath = useMemo(() => {
    const path = fullObjectPath
      .filter((item) => {
        // убираю из пути Root и возвращаю только путь в Библиотеке
        return item.title.toLowerCase() !== "root" && getDomainByClass(item.class) === DOMAIN_LIBRARY;
      });
    // Если объект из репозитория, то необходимо дополнить путь еще расположением в репозитории  
    if (object.domain === DOMAIN_REPO && object.path) {
      const filePath = object.path.split("/");
      filePath.forEach((name, i) => {
        const isFile = object.className === CLS_REPO_FILE_FUNCTION || object.className === CLS_REPO_FILE;
        let title = name;
        if ((object.className === CLS_REPO_DIR || object.className === CLS_REPO_FILE) && i === filePath.length - 1) {
          // не добавляем в путь саму директорию или сам файл
          title = null;
        }

        if (title) {
          path.push({
            title,
            icon: (isFile && i === filePath.length - 1) ? getFileIcon(title) : "collection-closed-M"
          });
        }
      });
    }
    return  path;
  }, [fullObjectPath, object]);

  if (objectPath.length === 0) {
    return (
      <div className="ais-object-tooltip">
        <div className="ais-object-tooltip-empty">
          Путь до исходного объекта не определен
        </div>
        {canGoToObject && 
        <div className="ais-object-tooltip-go">
          Кликните по объекту, чтобы перейти к его расположению
        </div>
        }
      </div>
    );
  }

  const parent = useMemo(() => {
    const parent = objectPath[objectPath.length - 1];
    return parent;
  }, [objectPath && objectPath.length]);

  const icon = useMemo(() => {
    if (parent && parent.icon) {
      return parent.icon;
    }
    if (parent && Array.isArray(parent.members)) {
      const lastMember = parent.members[parent.members.length - 1];
      if (lastMember && lastMember.kindUid) {
        return accountStore.getIcon(lastMember.kindUid);
      }
    }
    return null;
  }, [parent && parent.members && parent.members.length]);

  const path = useMemo(() => {
    return objectPath
      .map((item) => {
        let icon = item.icon;
        if (Array.isArray(item.members)) {
          const lastMember = item.members[item.members.length - 1];
          if (lastMember && lastMember.kindUid) {
            icon = accountStore.getIcon(lastMember.kindUid);
          }
        }
        return (
          <div 
            key={item.uid}
            className="ais-object-tooltip-path-item"
          > 
            <AisIcon item={item} icon={icon} />
            {item.title}
          </div>
        );
      });
  }, [objectPath]);
  
  return (
    <div className="ais-object-tooltip">
      <div className="ais-object-tooltip-parent">
        <label>Исходный объект:</label>
        <AisIcon item={parent} icon={icon} />
        {parent && parent.title}
      </div>
      <div className="ais-object-tooltip-path">
        <label>Путь:</label>
        {path}
      </div>
      {canGoToObject && 
        <div className="ais-object-tooltip-go">
          Кликните по объекту, чтобы перейти к его расположению
        </div>
      }
    </div>
  );
});

export default AisObjectTooltip;
